import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed top-0 left-0 w-full z-50 shadow-md bg-gray-200 bg-opacity-70 backdrop-blur-md backdrop-opacity-90 animate-pulse"
}
const _hoisted_2 = { class: "flex items-center h-16 mx-auto max-w-screen-xl justify-between px-4" }
const _hoisted_3 = {
  key: 0,
  class: "h-6 w-1/3 bg-gray-300 rounded"
}

import {
  ref, onMounted, computed,
} from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import BreadCrumb from '@/components/tailwind/header/BreadCrumb.vue';
import Page from '@/types/page';


export default /*@__PURE__*/_defineComponent({
  __name: 'SimpleHeader',
  props: {
  bgColor: { type: String, default: '' }, // Allows custom background color
  onClose: { type: Function, default: null }, // Optional custom close method
  pages: { type: Array as () => Page[], default: null }, // Array of pages for the breadcrumb
},
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

/*
  Props
*/
const props = __props;

/*
  Computed
*/
const justifyClass = computed(() => (props.pages.length > 0 ? 'justify-between' : 'justify-end'));

/*
  Data
*/
const isLoading = ref(true);

/*
  Methods
*/
const handleClose = () => {
  if (props.onClose) {
    props.onClose(); // Call custom method
  } else {
    emit('close'); // Default behavior
  }
};

/*
  Hooks
*/
onMounted(() => {
  setTimeout(() => {
    isLoading.value = false;
  }, 100);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (props.pages)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3))
              : _createCommentVNode("", true),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "h-6 w-6 bg-gray-300 rounded-full" }, null, -1))
          ])
        ]))
      : (_openBlock(), _createElementBlock("nav", {
          key: 1,
          class: _normalizeClass(['fixed top-0 left-0 w-full z-50 shadow-md', __props.bgColor, 'bg-opacity-70 backdrop-blur-md backdrop-opacity-90'])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(['flex items-center h-16 mx-auto max-w-screen-xl px-4', justifyClass.value])
          }, [
            (props.pages)
              ? (_openBlock(), _createBlock(BreadCrumb, {
                  key: 0,
                  pages: props.pages
                }, null, 8, ["pages"]))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              class: "text-gray-900 hover:text-gray-700 p-2 rounded-md focus:ring-2 focus:ring-teal-600 focus:ring-offset-2 focus:ring-offset-teal-600 cursor-pointer",
              onClick: handleClose
            }, [
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "sr-only" }, "Close", -1)),
              _createVNode(_unref(XMarkIcon), {
                class: "size-6",
                "aria-hidden": "true"
              })
            ])
          ], 2)
        ], 2)),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "h-18" }, null, -1))
  ], 64))
}
}

})