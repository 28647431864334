<template>
  <main class="main">
    <div class="column is-full p-0">
      <RouterView
        v-if="myStream && rtt"
        :stream="myStream"
        :rtt="rtt"
        :user-name="session.name"
        @update:my-stream="load"
        @reload:stream="reloadStream"
      />
    </div>
  </main>
  <LoadingComponent
    v-if="isLoading"
    :full-screen="true"
  />
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onBeforeMount,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import useStreamStore from '@/store/stream';
import useAuthStore from '@/store/auth';
import Stream from '@/models/stream';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';

const router = useRouter();
const {
  session,
} = useAuthStore();
const {
  getMyStream,
  streamsDoGetMy,
  streamStore,
  streamsDoGetRealtimeTokens,
} = useStreamStore();

/*
  Data
*/
const userRole = ref(1); // 1 = host, 2 = audience
const isLoading = ref(true);
const myStream = ref({});
const rtt = ref(null);

/*
  Computed
*/
const streamUuid = computed(() => router.currentRoute.value.params.uuid);

/*
  Methods
*/
const redirectDashboard = ((error: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar sua live. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));
const reloadStream = async () => {
  await streamsDoGetMy({ uuid: streamUuid.value }).then(async (response) => {
    if (response === undefined) {
      setTimeout(() => redirectDashboard('Não foi possível completar sua solicitação. Verifique o link e tente novamente'), 300);
      return;
    }
    myStream.value = await getMyStream();
  }).catch((error) => {
    console.error('error', error);
    redirectDashboard(error.toString());
  }).finally(() => {
    isLoading.value = false;
  });
};
const load = async (stream: Stream) => {
  isLoading.value = true;
  if (stream == null) {
    rtt.value = await streamsDoGetRealtimeTokens(
      streamUuid.value,
      userRole.value,
    );
    reloadStream();
  } else {
    myStream.value = stream;
    isLoading.value = false;
  }
};

/*
  Hooks
*/
onBeforeMount(async () => {
  load();
});
watch(streamStore, () => {
  myStream.value = getMyStream();
}, { deep: true });
</script>

<style lang="scss" scoped>
</style>
