<!-- eslint-disable max-len -->
<template>
  <TransitionRoot
    as="template"
    :show="props.show"
  >
    <Dialog
      class="relative z-60"
      @close="handleClose"
    >
      <!-- Overlay -->
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <!-- Drawer Panel -->
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                <!-- Close Button -->
                <TransitionChild
                  as="template"
                  enter="ease-in-out duration-500"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="ease-in-out duration-500"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div class="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      class="relative rounded-md text-gray-300 hover:text-white focus:ring-2 focus:ring-white focus:outline-hidden"
                      @click="handleClose"
                    >
                      <span class="absolute -inset-2.5" />
                      <span class="sr-only">Close panel</span>
                      <XMarkIcon
                        class="size-6"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </TransitionChild>

                <!-- Drawer Content -->
                <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <!-- Header Slot -->
                  <!-- <div :class="['px-4 py-6 sm:px-6', bgColor]"> -->
                  <div :class="[bgColor]">
                    <slot name="header">
                      <DialogTitle class="text-base font-semibold text-gray-900">
                        Default Title
                      </DialogTitle>
                      <p class="mt-1 text-sm text-gray-900">
                        Default Description
                      </p>
                    </slot>
                  </div>

                  <!-- Main Content Slot -->
                  <!-- <div class="relative flex-1 px-4 py-4 sm:px-6"> -->
                  <div class="relative flex-1">
                    <slot name="content">
                      No content provided.
                    </slot>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  show: { type: Boolean, required: true }, // Controls the visibility of the drawer
  bgColor: { type: String, default: 'bg-teal-100' }, // Background color for header
  onClose: { type: Function, default: null }, // Custom close method
});

const emit = defineEmits(['update:show']);

// Handle close logic
const handleClose = () => {
  if (props.onClose) {
    props.onClose(); // Call custom close function
  } else {
    emit('update:show', false); // Default close action
  }
};
</script>
