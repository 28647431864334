import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/logo2.png'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "fixed inset-0 flex" }
const _hoisted_3 = { class: "absolute top-0 left-full flex w-16 justify-center pt-5" }
const _hoisted_4 = { class: "flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4 bg-white" }
const _hoisted_5 = { class: "flex flex-1 flex-col" }
const _hoisted_6 = {
  role: "list",
  class: "flex flex-1 flex-col gap-y-7"
}
const _hoisted_7 = {
  role: "list",
  class: "-mx-2 space-y-1"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "text-xs/6 font-semibold text-gray-400" }
const _hoisted_10 = {
  role: "list",
  class: "-mx-2 mt-2 space-y-1"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "truncate" }
const _hoisted_13 = {
  key: 1,
  class: "'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'"
}
const _hoisted_14 = { class: "border-gray-200 text-gray-400 flex size-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium" }
const _hoisted_15 = { class: "truncate text-gray-400" }
const _hoisted_16 = { class: "mt-auto" }
const _hoisted_17 = ["href"]
const _hoisted_18 = { class: "hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col" }
const _hoisted_19 = { class: "flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4" }
const _hoisted_20 = { class: "flex flex-1 flex-col" }
const _hoisted_21 = {
  role: "list",
  class: "flex flex-1 flex-col gap-y-7"
}
const _hoisted_22 = {
  role: "list",
  class: "-mx-2 space-y-1"
}
const _hoisted_23 = ["href"]
const _hoisted_24 = {
  role: "list",
  class: "-mx-2 mt-2 space-y-1"
}
const _hoisted_25 = ["href"]
const _hoisted_26 = { class: "truncate" }
const _hoisted_27 = {
  key: 1,
  class: "'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'"
}
const _hoisted_28 = { class: "border-gray-200 text-gray-400 flex size-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium" }
const _hoisted_29 = { class: "truncate text-gray-400" }
const _hoisted_30 = { class: "mt-auto" }
const _hoisted_31 = ["href"]
const _hoisted_32 = { class: "lg:pl-72" }
const _hoisted_33 = { class: "bg-gray-100 sticky top-0 z-40 lg:mx-auto lg:max-w-7xl lg:px-8" }
const _hoisted_34 = { class: "flex h-16 items-center gap-x-4 border-b border-gray-200 px-4 shadow-xs sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none" }
const _hoisted_35 = { class: "flex flex-1 gap-x-4 self-stretch lg:gap-x-6 bg-gray-100" }
const _hoisted_36 = { class: "flex items-center gap-x-4 lg:gap-x-6" }
const _hoisted_37 = ["src"]
const _hoisted_38 = { class: "hidden lg:flex lg:items-center" }
const _hoisted_39 = {
  class: "ml-4 text-sm/6 font-semibold text-gray-900",
  "aria-hidden": "true"
}
const _hoisted_40 = ["href"]
const _hoisted_41 = { class: "py-4" }
const _hoisted_42 = { class: "mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" }
const _hoisted_43 = { class: "border-b border-gray-200 pb-5" }
const _hoisted_44 = { class: "font-semibold text-gray-900 text-xl" }
const _hoisted_45 = {
  key: 0,
  class: "bg-white"
}
const _hoisted_46 = { class: "px-6 py-16 sm:px-6 sm:py-32 lg:px-8" }
const _hoisted_47 = { class: "mx-auto max-w-2xl text-center" }
const _hoisted_48 = { class: "text-4xl font-semibold tracking-tight text-balance text-gray-900 sm:text-5xl" }
const _hoisted_49 = { class: "mx-auto mt-6 max-w-xl text-lg/8 text-pretty text-gray-600" }
const _hoisted_50 = { class: "mt-10 flex items-center justify-center gap-x-6" }
const _hoisted_51 = {
  key: 1,
  class: ""
}
const _hoisted_52 = { class: "mt-8" }
const _hoisted_53 = { class: "mt-8" }
const _hoisted_54 = { class: "text-base font-semibold text-gray-900" }
const _hoisted_55 = { class: "mt-1 text-md text-gray-900" }
const _hoisted_56 = { class: "px-4 py-6 sm:px-6" }
const _hoisted_57 = { class: "space-y-8" }
const _hoisted_58 = { class: "text-sm font-medium text-gray-500 sm:shrink-0" }
const _hoisted_59 = { class: "italic text-gray-500 mt-2" }
const _hoisted_60 = { class: "text-sm text-gray-900 sm:col-span-2 mt-4" }
const _hoisted_61 = { class: "text-sm font-medium text-gray-500 sm:shrink-0" }
const _hoisted_62 = { class: "italic text-gray-500 mt-2" }
const _hoisted_63 = { class: "text-sm text-gray-900 sm:col-span-2 mt-4" }
const _hoisted_64 = { class: "text-sm font-medium text-gray-500 sm:shrink-0" }
const _hoisted_65 = { class: "italic text-gray-500 mt-2" }
const _hoisted_66 = { class: "text-sm text-gray-900 sm:col-span-2 mt-4" }
const _hoisted_67 = { class: "text-sm font-medium text-gray-500 sm:shrink-0" }
const _hoisted_68 = { class: "italic text-gray-500 mt-2" }
const _hoisted_69 = { class: "text-sm text-gray-900 sm:col-span-2 mt-4" }
const _hoisted_70 = { class: "text-sm font-medium text-gray-500 sm:shrink-0" }
const _hoisted_71 = { class: "italic text-gray-500 mt-2" }
const _hoisted_72 = { class: "text-sm text-gray-900 sm:col-span-2 mt-4" }
const _hoisted_73 = { class: "text-sm font-medium text-gray-500 sm:shrink-0" }
const _hoisted_74 = { class: "italic text-gray-500 mt-2" }
const _hoisted_75 = { class: "text-sm text-gray-900 sm:col-span-2 mt-4" }

import {
  ref, computed, onBeforeMount, onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import useAuthStore from '@/store/auth';
import SimpleFooter from '@/components/tailwind/footer/SimpleFooter.vue';
import RightSideDrawer from '@/components/tailwind/shared/RightSideDrawer.vue';
import ListPluginReportsDashboard from '@/components/tailwind/plugin/ListPluginReportsDashboard.vue';
import PluginMetadatasMetrics from '@/components/tailwind/plugin/PluginMetadatasMetrics.vue';
import BlankProfilePicture from '@/assets/images/backgrounds/blank-profile-picture.png';
import {
  Dialog,
  DialogTitle,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  Bars3Icon,
  // BellIcon,
  PresentationChartBarIcon,
  Cog6ToothIcon,
  PuzzlePieceIcon,
  HomeIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { useI18n } from 'vue-i18n';
import usePluginStore from '@/store/plugin/plugin';
import Plugin from '@/models/plugin/plugin';


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardLayout',
  setup(__props) {

const router = useRouter();
const { session, authDoLogout } = useAuthStore();
const { t } = useI18n();
const {
  pluginsDoGetMy,
  pluginStore,
} = usePluginStore();

/*
  Data
*/
const dataLoaded = ref(false);
const sidebarOpen = ref(false);
const showHelpModal = ref(false);
const plugins = ref([] as Plugin[]);
const stats = computed(() => [
  { name: 'Lives realizadas', stat: '300' },
  { name: 'Total de mensagens', stat: '800' },
  { name: 'Seguidores de live', stat: '537' },
]);

/*
  Methods
*/
const navigateWhatsapp = (message: string) => {
  window.open(`https://wa.me/+5511994991786?text=${message}`, '_blank');
};
const openPluginLivesPage = async (pluginId: string, pluginType: string) => {
  await router.push({
    name: 'PluginLivesPage',
    params: {
      pluginId,
      pluginType,
    },
  });
};

const loadPlugins = () => {
  pluginsDoGetMy()
    .then((response) => {
      if (response !== undefined) {
        plugins.value = response.data;
      }
    }).catch((error) => {
      console.error('error', error);
    });
};

const instagramConnectStart = () => {
  // isLoading.value = true;
  // const instagramUrl = process.env.VUE_APP_INSTAGRAM_OAUTH_REDIRECT_URL;
  // FIXME: USE E ENV VAR VUE_APP_INSTAGRAM_OAUTH_REDIRECT_URL
  // const instagramUrl = 'https://www.instagram.com/oauth/authorize?client_id=1035072877787285&redirect_uri=https://www.livelink.com.br/plugins/callback/instagram&response_type=code';
  // const scope = process.env.VUE_APP_INSTAGRAM_OAUTH_BUSINESS_SCOPE;
  // const url = 'https://r4gcpe9kb7.execute-api.sa-east-1.amazonaws.com/plugins/callback/instagram';
  // const url = 'https://www.livelink.com.br/plugins/callback/instagram';
  const queryParams = [
    'client_id=1035072877787285',
    'redirect_uri=https://www.livelink.com.br/plugins/callback/instagram',
    'response_type=code',
    'auth_type=reauthenticate',
    'prompt=consent',
    'display=popup',
  ];
  const instagramUrl = `https://www.instagram.com/oauth/authorize?${queryParams.join('&')}`;
  pluginStore.csrf = Math.random().toString(36).substring(2, 15);
  const scope = 'instagram_business_basic,instagram_business_manage_comments,instagram_business_manage_messages';
  window.location.href = `${instagramUrl}&state=${pluginStore.csrf}&scope=${scope}`;
};
const getProfileImageUrl = () => session?.profileImageUrl || BlankProfilePicture;
const logout = async () => {
  await authDoLogout().then(() => {
    window.open(router.resolve({ name: 'SessionLoginPage' }).href, '_self');
  }).catch((error) => {
    console.error(error);
    alert(t('session.logoutError'));
    // }).finally(() => {
    // window.location.reload();
  });
};

/*
  Computed
*/
const getUserFirstName = computed(() => session?.name.split(' ')[0]);
const otherProducts = computed(() => [
  {
    id: 1, name: t('sidemenu.navigation.bio.title'), href: router.resolve({ name: 'UserBioManagerPage' }).href, initial: t('sidemenu.navigation.bio.initials'), current: false, disabled: false,
  },
  {
    id: 2, name: t('sidemenu.navigation.myBrand.title'), href: router.resolve({ name: 'MyMerchantsPage' }).href, initial: t('sidemenu.navigation.myBrand.initials'), current: false, disabled: false,
  },
  // {
  //   id: 3, name: t('sidemenu.navigation.crm.title'), href: '#',
  // initial: t('sidemenu.navigation.crm.initials'), current: false, disabled: true,
  // },
]);
const userNavigation = computed(() => [
  { name: t('sidemenu.navigation.profile.title'), href: router.resolve({ name: 'UserProfileManagerPage' }).href, method: null },
  // { name: t('sidemenu.navigation.signout.title'), href: '#', method: logout },
]);
const navigation = computed(() => [
  {
    name: t('sidemenu.navigation.dashboard.title'), href: router.resolve({ name: 'DashboardPage' }).href, icon: HomeIcon, current: true,
  },
  {
    name: t('sidemenu.navigation.integration.title'), href: router.resolve({ name: 'PluginsManagerPage' }).href, icon: PuzzlePieceIcon, current: false,
  },
  {
    name: t('sidemenu.navigation.shared-report.title'), href: router.resolve({ name: 'PluginLivesSharedWithMePage' }).href, icon: PresentationChartBarIcon, current: false,
  },
  // {
  //   name: t('sidemenu.navigation.report-shared-with-me.title'),
  // href: '#', icon: ArrowUpOnSquareIcon, current: false,
  // },
  // {
  //   name: 'Calendar', href: '#', icon: CalendarIcon, current: false,
  // },
  // {
  //   name: 'Team', href: '#', icon: UsersIcon, current: false,
  // },
  // {
  //   name: 'Documents', href: '#', icon: DocumentDuplicateIcon, current: false,
  // },
]);

/*
  Hooks
*/
onBeforeMount(() => {
  loadPlugins();
});
onMounted(() => {
  setTimeout(() => {
    dataLoaded.value = true;
  }, 100);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (dataLoaded.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_unref(TransitionRoot), {
            as: "template",
            show: sidebarOpen.value
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(Dialog), {
                class: "relative z-50 lg:hidden",
                onClose: _cache[1] || (_cache[1] = ($event: any) => (sidebarOpen.value = false))
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(TransitionChild), {
                    as: "template",
                    enter: "transition-opacity ease-linear duration-300",
                    "enter-from": "opacity-0",
                    "enter-to": "opacity-100",
                    leave: "transition-opacity ease-linear duration-300",
                    "leave-from": "opacity-100",
                    "leave-to": "opacity-0"
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createElementVNode("div", { class: "fixed inset-0 bg-gray-900/80" }, null, -1)
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_unref(TransitionChild), {
                      as: "template",
                      enter: "transition ease-in-out duration-300 transform",
                      "enter-from": "-translate-x-full",
                      "enter-to": "translate-x-0",
                      leave: "transition ease-in-out duration-300 transform",
                      "leave-from": "translate-x-0",
                      "leave-to": "-translate-x-full"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(DialogPanel), { class: "relative mr-16 flex w-full max-w-xs flex-1" }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(TransitionChild), {
                              as: "template",
                              enter: "ease-in-out duration-300",
                              "enter-from": "opacity-0",
                              "enter-to": "opacity-100",
                              leave: "ease-in-out duration-300",
                              "leave-from": "opacity-100",
                              "leave-to": "opacity-0"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_3, [
                                  _createElementVNode("button", {
                                    type: "button",
                                    class: "-m-2.5 p-2.5",
                                    onClick: _cache[0] || (_cache[0] = ($event: any) => (sidebarOpen.value = false))
                                  }, [
                                    _cache[7] || (_cache[7] = _createElementVNode("span", { class: "sr-only" }, "Close sidebar", -1)),
                                    _createVNode(_unref(XMarkIcon), {
                                      class: "size-6 text-white",
                                      "aria-hidden": "true"
                                    })
                                  ])
                                ])
                              ]),
                              _: 1
                            }),
                            _createElementVNode("div", _hoisted_4, [
                              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "flex h-16 shrink-0 items-center" }, [
                                _createElementVNode("img", {
                                  class: "h-8 w-auto",
                                  src: _imports_0,
                                  alt: "LiveLink LLM"
                                })
                              ], -1)),
                              _createElementVNode("nav", _hoisted_5, [
                                _createElementVNode("ul", _hoisted_6, [
                                  _createElementVNode("li", null, [
                                    _createElementVNode("ul", _hoisted_7, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navigation.value, (item) => {
                                        return (_openBlock(), _createElementBlock("li", {
                                          key: item.name
                                        }, [
                                          _createElementVNode("a", {
                                            href: item.href,
                                            class: _normalizeClass([item.current ? 'bg-gray-50 text-teal-600' : 'text-gray-700 hover:bg-gray-50 hover:text-teal-600', 'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'])
                                          }, [
                                            (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                                              class: _normalizeClass([item.current ? 'text-teal-600' : 'text-gray-400 group-hover:text-teal-600', 'size-6 shrink-0']),
                                              "aria-hidden": "true"
                                            }, null, 8, ["class"])),
                                            _createTextVNode(" " + _toDisplayString(item.name), 1)
                                          ], 10, _hoisted_8)
                                        ]))
                                      }), 128))
                                    ])
                                  ]),
                                  _createElementVNode("li", null, [
                                    _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(t)('sidemenu.products.title')), 1),
                                    _createElementVNode("ul", _hoisted_10, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(otherProducts.value, (product) => {
                                        return (_openBlock(), _createElementBlock("li", {
                                          key: product.name
                                        }, [
                                          (!product.disabled)
                                            ? (_openBlock(), _createElementBlock("a", {
                                                key: 0,
                                                href: product.href,
                                                class: _normalizeClass([product.current ? 'bg-gray-50 text-teal-600' : 'text-gray-700 hover:bg-gray-50 hover:text-teal-600', 'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'])
                                              }, [
                                                _createElementVNode("span", {
                                                  class: _normalizeClass([product.current ? 'border-teal-600 text-teal-600' : 'border-gray-200 text-gray-400 group-hover:border-teal-600 group-hover:text-teal-600', 'flex size-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium'])
                                                }, _toDisplayString(product.initial), 3),
                                                _createElementVNode("span", _hoisted_12, _toDisplayString(product.name), 1)
                                              ], 10, _hoisted_11))
                                            : (_openBlock(), _createElementBlock("p", _hoisted_13, [
                                                _createElementVNode("span", _hoisted_14, _toDisplayString(product.initial), 1),
                                                _createElementVNode("span", _hoisted_15, _toDisplayString(product.name), 1)
                                              ]))
                                        ]))
                                      }), 128))
                                    ])
                                  ]),
                                  _createElementVNode("li", _hoisted_16, [
                                    _createElementVNode("a", {
                                      href: _unref(router).resolve({ name: 'UserProfileManagerPage' }).href,
                                      class: "group -mx-2 flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-gray-700 hover:bg-gray-50 hover:text-teal-600"
                                    }, [
                                      _createVNode(_unref(Cog6ToothIcon), {
                                        class: "size-6 shrink-0 text-gray-400 group-hover:text-teal-600",
                                        "aria-hidden": "true"
                                      }),
                                      _createTextVNode(" " + _toDisplayString(_unref(t)('sidemenu.navigation.settings.title')), 1)
                                    ], 8, _hoisted_17)
                                  ])
                                ]),
                                _createVNode(SimpleFooter, {
                                  horizontal: false,
                                  "no-backgound": true
                                })
                              ])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["show"]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex h-16 shrink-0 items-center" }, [
                _createElementVNode("img", {
                  class: "h-8 w-auto",
                  src: _imports_0,
                  alt: "Your Company"
                })
              ], -1)),
              _createElementVNode("nav", _hoisted_20, [
                _createElementVNode("ul", _hoisted_21, [
                  _createElementVNode("li", null, [
                    _createElementVNode("ul", _hoisted_22, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navigation.value, (item) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: item.name
                        }, [
                          _createElementVNode("a", {
                            href: item.href,
                            class: _normalizeClass([item.current ? 'bg-gray-50 text-teal-600' : 'text-gray-700 hover:bg-gray-50 hover:text-teal-600', 'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'])
                          }, [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                              class: _normalizeClass([item.current ? 'text-teal-600' : 'text-gray-400 group-hover:text-teal-600', 'size-6 shrink-0']),
                              "aria-hidden": "true"
                            }, null, 8, ["class"])),
                            _createTextVNode(" " + _toDisplayString(item.name), 1)
                          ], 10, _hoisted_23)
                        ]))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("li", null, [
                    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-xs/6 font-semibold text-gray-400" }, " Cresça com a gente ", -1)),
                    _createElementVNode("ul", _hoisted_24, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(otherProducts.value, (product) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: product.name
                        }, [
                          (!product.disabled)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: product.href,
                                class: _normalizeClass([product.current ? 'bg-gray-50 text-teal-600' : 'text-gray-700 hover:bg-gray-50 hover:text-teal-600', 'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'])
                              }, [
                                _createElementVNode("span", {
                                  class: _normalizeClass([product.current ? 'border-teal-600 text-teal-600' : 'border-gray-200 text-gray-400 group-hover:border-teal-600 group-hover:text-teal-600', 'flex size-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium'])
                                }, _toDisplayString(product.initial), 3),
                                _createElementVNode("span", _hoisted_26, _toDisplayString(product.name), 1)
                              ], 10, _hoisted_25))
                            : (_openBlock(), _createElementBlock("p", _hoisted_27, [
                                _createElementVNode("span", _hoisted_28, _toDisplayString(product.initial), 1),
                                _createElementVNode("span", _hoisted_29, _toDisplayString(product.name), 1)
                              ]))
                        ]))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("li", _hoisted_30, [
                    _createElementVNode("a", {
                      href: _unref(router).resolve({ name: 'UserProfileManagerPage' }).href,
                      class: "group -mx-2 flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-gray-700 hover:bg-gray-50 hover:text-teal-600"
                    }, [
                      _createVNode(_unref(Cog6ToothIcon), {
                        class: "size-6 shrink-0 text-gray-400 group-hover:text-teal-600",
                        "aria-hidden": "true"
                      }),
                      _createTextVNode(" " + _toDisplayString(_unref(t)('sidemenu.navigation.settings.title')), 1)
                    ], 8, _hoisted_31)
                  ])
                ]),
                _createVNode(SimpleFooter, {
                  horizontal: false,
                  "no-backgound": true,
                  "hide-label": true
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("div", _hoisted_34, [
                _createElementVNode("button", {
                  type: "button",
                  class: "-m-2.5 p-2.5 text-gray-700 lg:hidden",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (sidebarOpen.value = true))
                }, [
                  _cache[11] || (_cache[11] = _createElementVNode("span", { class: "sr-only" }, "Open sidebar", -1)),
                  _createVNode(_unref(Bars3Icon), {
                    class: "size-6",
                    "aria-hidden": "true"
                  })
                ]),
                _cache[15] || (_cache[15] = _createElementVNode("div", {
                  class: "h-6 w-px bg-gray-200 lg:hidden",
                  "aria-hidden": "true"
                }, null, -1)),
                _createElementVNode("div", _hoisted_35, [
                  _cache[14] || (_cache[14] = _createElementVNode("div", { class: "grid flex-1 grid-cols-1" }, null, -1)),
                  _createElementVNode("div", _hoisted_36, [
                    _cache[13] || (_cache[13] = _createElementVNode("div", {
                      class: "hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200",
                      "aria-hidden": "true"
                    }, null, -1)),
                    _createVNode(_unref(Menu), {
                      as: "div",
                      class: "relative"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(MenuButton), { class: "-m-1.5 flex items-center p-1.5" }, {
                          default: _withCtx(() => [
                            _cache[12] || (_cache[12] = _createElementVNode("span", { class: "sr-only" }, "Open user menu", -1)),
                            _createElementVNode("img", {
                              class: "size-8 rounded-full bg-gray-50",
                              src: getProfileImageUrl(),
                              alt: ""
                            }, null, 8, _hoisted_37),
                            _createElementVNode("span", _hoisted_38, [
                              _createElementVNode("span", _hoisted_39, _toDisplayString(getUserFirstName.value), 1),
                              _createVNode(_unref(ChevronDownIcon), {
                                class: "ml-2 size-5 text-gray-400",
                                "aria-hidden": "true"
                              })
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_Transition, {
                          "enter-active-class": "transition ease-out duration-100",
                          "enter-from-class": "transform opacity-0 scale-95",
                          "enter-to-class": "transform opacity-100 scale-100",
                          "leave-active-class": "transition ease-in duration-75",
                          "leave-from-class": "transform opacity-100 scale-100",
                          "leave-to-class": "transform opacity-0 scale-95"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(MenuItems), { class: "bg-white absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md py-2 ring-1 shadow-lg ring-gray-900/5 focus:outline-hidden" }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(userNavigation.value, (item) => {
                                  return (_openBlock(), _createBlock(_unref(MenuItem), {
                                    key: item.name
                                  }, {
                                    default: _withCtx(({ active }) => [
                                      _createElementVNode("a", {
                                        href: item.href,
                                        class: _normalizeClass([active ? 'bg-gray-50 outline-hidden' : '', 'block px-3 py-1 text-sm/6 text-gray-900'])
                                      }, _toDisplayString(item.name), 11, _hoisted_40)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128)),
                                _createVNode(_unref(MenuItem), null, {
                                  default: _withCtx(({ active }) => [
                                    _createElementVNode("a", {
                                      href: "#",
                                      class: _normalizeClass([active ? 'bg-gray-50 outline-hidden' : '', 'block px-3 py-1 text-sm/6 text-gray-900']),
                                      onClick: logout
                                    }, _toDisplayString(_unref(t)('sidemenu.navigation.signout.title')), 3)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ])
              ])
            ]),
            _createElementVNode("main", _hoisted_41, [
              _createElementVNode("div", _hoisted_42, [
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("h3", _hoisted_44, _toDisplayString(_unref(t)('dashboard.title', {name: getUserFirstName.value})), 1)
                ]),
                (plugins.value.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                      _createElementVNode("div", _hoisted_46, [
                        _createElementVNode("div", _hoisted_47, [
                          _createElementVNode("h2", _hoisted_48, _toDisplayString(_unref(t)('dashboard.plugin.calltoaction.title')), 1),
                          _createElementVNode("p", _hoisted_49, _toDisplayString(_unref(t)('dashboard.plugin.calltoaction.subtitle')), 1),
                          _createElementVNode("div", _hoisted_50, [
                            _createElementVNode("a", {
                              href: "#",
                              class: "rounded-md bg-teal-500 px-3.5 py-2.5 text-lg font-semibold text-white shadow-xs hover:bg-teal-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-700",
                              onClick: _withModifiers(instagramConnectStart, ["prevent"])
                            }, _toDisplayString(_unref(t)('dashboard.plugin.calltoaction.button')), 1),
                            _createElementVNode("a", {
                              href: "#",
                              class: "text-sm/6 font-semibold text-gray-900",
                              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (showHelpModal.value = true), ["prevent"]))
                            }, [
                              _createTextVNode(_toDisplayString(_unref(t)('dashboard.plugin.calltoaction.learnmore')) + " ", 1),
                              _cache[16] || (_cache[16] = _createElementVNode("span", { "aria-hidden": "true" }, "→", -1))
                            ])
                          ])
                        ])
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_51, [
                      _createElementVNode("div", _hoisted_52, [
                        _createVNode(PluginMetadatasMetrics)
                      ]),
                      _createElementVNode("div", _hoisted_53, [
                        _createVNode(ListPluginReportsDashboard, { plugins: plugins.value }, null, 8, ["plugins"])
                      ])
                    ]))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(RightSideDrawer, {
      show: showHelpModal.value,
      "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => ((showHelpModal).value = $event)),
      "bg-color": "bg-gray-100"
    }, {
      header: _withCtx(() => [
        _createVNode(_unref(DialogTitle), { class: "px-4 py-6 sm:px-6" }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_54, _toDisplayString(_unref(t)('plugins.manager.modal.help.title')), 1),
            _createElementVNode("p", _hoisted_55, _toDisplayString(_unref(t)('plugins.manager.modal.help.description')), 1)
          ]),
          _: 1
        })
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_56, [
          _createElementVNode("dl", _hoisted_57, [
            _createElementVNode("div", null, [
              _createElementVNode("dt", _hoisted_58, [
                _createElementVNode("strong", null, _toDisplayString(_unref(t)('plugins.manager.modal.help.permissions.title')), 1),
                _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("p", _hoisted_59, _toDisplayString(_unref(t)('plugins.manager.modal.help.permissions.error')), 1)
              ]),
              _createElementVNode("dd", _hoisted_60, [
                _createElementVNode("p", null, _toDisplayString(_unref(t)('plugins.manager.modal.help.permissions.solution')), 1)
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("dt", _hoisted_61, [
                _createElementVNode("strong", null, _toDisplayString(_unref(t)('plugins.manager.modal.help.admin.title')), 1),
                _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("p", _hoisted_62, _toDisplayString(_unref(t)('plugins.manager.modal.help.admin.error')), 1)
              ]),
              _createElementVNode("dd", _hoisted_63, [
                _createElementVNode("p", null, _toDisplayString(_unref(t)('plugins.manager.modal.help.admin.solution')), 1)
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("dt", _hoisted_64, [
                _createElementVNode("strong", null, _toDisplayString(_unref(t)('plugins.manager.modal.help.link.title')), 1),
                _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("p", _hoisted_65, _toDisplayString(_unref(t)('plugins.manager.modal.help.link.error')), 1)
              ]),
              _createElementVNode("dd", _hoisted_66, [
                _createElementVNode("p", null, _toDisplayString(_unref(t)('plugins.manager.modal.help.link.solution')), 1)
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("dt", _hoisted_67, [
                _createElementVNode("strong", null, _toDisplayString(_unref(t)('plugins.manager.modal.help.selection.title')), 1),
                _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("p", _hoisted_68, _toDisplayString(_unref(t)('plugins.manager.modal.help.selection.error')), 1)
              ]),
              _createElementVNode("dd", _hoisted_69, [
                _createElementVNode("p", null, _toDisplayString(_unref(t)('plugins.manager.modal.help.selection.solution')), 1)
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("dt", _hoisted_70, [
                _createElementVNode("strong", null, _toDisplayString(_unref(t)('plugins.manager.modal.help.personal.title')), 1),
                _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("p", _hoisted_71, _toDisplayString(_unref(t)('plugins.manager.modal.help.personal.error')), 1)
              ]),
              _createElementVNode("dd", _hoisted_72, [
                _createElementVNode("p", null, _toDisplayString(_unref(t)('plugins.manager.modal.help.personal.solution')), 1)
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("dt", _hoisted_73, [
                _createElementVNode("strong", null, _toDisplayString(_unref(t)('plugins.manager.modal.help.token.title')), 1),
                _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("p", _hoisted_74, _toDisplayString(_unref(t)('plugins.manager.modal.help.token.error')), 1)
              ]),
              _createElementVNode("dd", _hoisted_75, [
                _createElementVNode("p", null, _toDisplayString(_unref(t)('plugins.manager.modal.help.token.solution')), 1)
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                type: "button",
                class: "inline-flex w-full shrink-0 items-center justify-center rounded-md bg-teal-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-teal-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-500 sm:flex-1",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (navigateWhatsapp(_unref(t)('plugins.manager.modal.help.button1'))))
              }, _toDisplayString(_unref(t)('plugins.manager.modal.help.button1')), 1)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}
}

})