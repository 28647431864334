import Unlock from '@/models/billing/unlock';

class BillingCredit {
  id: string;

  createdAt: Date;

  updatedAt: Date;

  userUuid: string;

  paymentId: string;

  paymentGateway: string;

  paymentMethod: string;

  paymentValue: number;

  paymentCurrency: string;

  paymentInvoiceUrl: string | null = null;

  type: string;

  credits: number;

  maxDistinctAccounts: number;

  validFrom: Date;

  validTo: Date;

  unlocks: Unlock[];

  rawData: any | null = null;

  freeUnlocksMediaId: string | null = null;

  creditUnlocksMediaId: string | null = null;

  messageAboutCredit: string | null = null;

  constructor({
    id,
    createdAt,
    updatedAt,
    userUuid,
    paymentId,
    paymentGateway,
    paymentMethod,
    paymentValue,
    paymentCurrency,
    paymentInvoiceUrl,
    type,
    credits,
    maxDistinctAccounts,
    validFrom,
    validTo,
    unlocks,
    rawData,
    freeUnlocksMediaId,
    creditUnlocksMediaId,
    messageAboutCredit,
  }: {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    userUuid: string;
    paymentId: string;
    paymentGateway: string;
    paymentMethod: string;
    paymentValue: number;
    paymentCurrency: string;
    paymentInvoiceUrl: string | null;
    type: string;
    credits: number;
    maxDistinctAccounts: number;
    validFrom: Date;
    validTo: Date;
    unlocks: Unlock[];
    rawData: any | null;
    freeUnlocksMediaId: string | null;
    creditUnlocksMediaId: string | null;
    messageAboutCredit: string | null;
  }) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.userUuid = userUuid;
    this.paymentId = paymentId;
    this.paymentGateway = paymentGateway;
    this.paymentMethod = paymentMethod;
    this.paymentValue = paymentValue;
    this.paymentCurrency = paymentCurrency;
    this.paymentInvoiceUrl = paymentInvoiceUrl;
    this.type = type;
    this.credits = credits;
    this.maxDistinctAccounts = maxDistinctAccounts;
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.unlocks = unlocks || [];
    this.rawData = rawData;
    this.freeUnlocksMediaId = freeUnlocksMediaId;
    this.creditUnlocksMediaId = creditUnlocksMediaId;
    this.messageAboutCredit = messageAboutCredit;
  }

  // For this should consider the value (int) and the currency (string)
  // must return something like: 10.00 USD
  // or 10,00 BRL
  formattedPaymentValue(): string {
    if (this.paymentValue === undefined || this.paymentValue === null || this.paymentValue === 0) {
      return 'Grátis';
    }
    const value = this.paymentValue / 100;
    return value.toLocaleString(undefined, { style: 'currency', currency: this.paymentCurrency });
  }

  formattedCreatedAt(): string {
    return this.createdAt.toLocaleString();
  }

  // Should return day/month/year
  // Example: 01/01/2021
  formattedValidFrom(): string {
    return this.validFrom.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  formattedValidTo(): string {
    return this.validTo.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  isBillingCreditType(): boolean {
    return this.type.toUpperCase() === 'CREDITS';
  }

  isBillingSubscriptionType(): boolean {
    return this.type.toUpperCase() === 'SUBSCRIPTION';
  }

  getShortId(): string {
    return this.id.substring(0, 16);
  }

  notLiveLinkInitialCredit(): boolean {
    // This was needed to grant the initial credit to the users
    // when deploying Paywall for the first time
    return this.id !== '67e2c494df94130c98a44b13';
  }

  isLiveLinkDomain(): boolean {
    if (!this.paymentInvoiceUrl) return false;
    return this.paymentInvoiceUrl.includes('livelink.com.br') || this.paymentInvoiceUrl.includes('www.livelink.com.br');
  }

  getDescription(): string {
    if (this.isBillingCreditType()) {
      if (this.credits > 1) return 'Crédito avulso de relatórios';
      return 'Crédito avulso de relatório';
    }
    if (this.isBillingSubscriptionType()) {
      return 'Assinatura mensal';
    }
    return '';
  }

  availableCredits(isFree: boolean): string | number {
    const illimitedReports = this.credits === -1;
    const illimitedAccounts = this.maxDistinctAccounts === -1;

    if (illimitedReports && illimitedAccounts) {
      return 'ilimitado (relatórios e contas)';
    }

    if (illimitedReports && !illimitedAccounts) {
      return 'ilimitado (relatórios)';
    }

    // If illimitedAccounts we can ignore the accountName
    // so we just get the unique unlocked dates (year-month-day)
    if (illimitedAccounts) {
      const unlockedDates = Array.from(new Set(this.unlocks.map((unlock) => unlock.unlockedAt.toISOString().split('T')[0])));
      return this.credits - unlockedDates.length;
    }

    // If not illimitedAccounts we need to get the unique `accountName-unlocked date`
    // so we can get the distinct accountName
    const unlockedDates = this.unlocks.map(
      (unlock) => `${unlock.pluginAccountName}-${unlock.unlockedAt.toISOString().split('T')[0]}`,
    );
    return this.credits - unlockedDates.length;
  }

  static fromApiResponse(columns: string[], rows: any[][]): BillingCredit[] {
    if (rows.length === 0) {
      return [];
    }

    return rows.map((row: any[]) => {
      const data: any = {};

      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());

        if (row[index] && (
          camelCaseKey === 'timestamp'
          || camelCaseKey === 'validFrom'
          || camelCaseKey === 'validTo'
          || camelCaseKey === 'createdAt'
          || camelCaseKey === 'updatedAt')) {
          // Convert timestamps to Date objects
          data[camelCaseKey] = new Date(row[index]);
        } else if (row[index] && camelCaseKey === 'Id') {
          data.id = row[index];
        } else if (row[index] && camelCaseKey === 'unlocks') {
          // Handle the array of Unlock objects
          data[camelCaseKey] = row[index].map((unlockData: any) => {
            // Convert snake_case keys in the Unlock data to camelCase
            const formattedUnlock = Object.keys(unlockData).reduce((formatted, key: string) => ({
              ...formatted,
              [key.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase())]: unlockData[key],
            }), {}) as Unlock;

            return new Unlock(formattedUnlock);
          });
        } else if (row[index] && camelCaseKey === 'rawData' && row[index]) {
          // Raw data remains as-is
          try {
            data[camelCaseKey] = row[index];
          } catch (error) {
            console.error('unable to parse rawData', row[index]);
          }
        } else if (row[index]) {
          // Default case for all other fields
          data[camelCaseKey] = row[index];
        }
      });

      return new BillingCredit(data);
    });
  }
}

export default BillingCredit;
