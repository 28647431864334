<!-- eslint-disable max-len -->
<template>
  <div>
    <!-- <h2 class="text-2xl font-semibold text-gray-900 mb-4">
      {{ t('plugin.lives.reports.dashboard.title') }}
    </h2>
    <p class="text-sm text-gray-700">
      {{ t('plugin.lives.reports.dashboard.description') }}
    </p> -->
    <ul>
      <li
        v-for="content in metadataHash"
        :key="content.plugin.name"
      >
        <div class="bg-white shadow-xs rounded-lg p-4 mb-4">
          <PluginHeader
            :plugin="content.plugin"
          />

          <ListPluginReportsTable
            :plugin="content.plugin"
            :metadatas="content.metadatas"
            :page-limit="pageLimit"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { defineProps, onBeforeMount, ref } from 'vue';
import Plugin from '@/models/plugin/plugin';
import InstagramLiveMetadata from '@/models/plugin/instagramLiveMetadata';
import usePluginStore from '@/store/plugin/plugin';
import ListPluginReportsTable from '@/components/tailwind/plugin/ListPluginReportsTable.vue';
import PluginHeader from '@/components/tailwind/plugin/PluginHeader.vue';

const {
  pluginGetLiveMetadatas,
} = usePluginStore();

/*
  Props
*/
const props = defineProps({
  plugins: {
    type: () => [] as Plugin[],
    required: true,
  },
});

/*
  Data
*/
const pageLimit = 5;
const metadataHash = ref({} as Record<string, {
  plugin: Plugin, metadatas: InstagramLiveMetadata[]
}>);

/*
  Methods
*/
const loadMetadatas = (plugin: Plugin) => {
  pluginGetLiveMetadatas({
    pluginId: `${plugin.id}`,
    pluginType: `${plugin.type}`,
    page: 1,
    limit: pageLimit,
    orderBy: 'createdAt',
    order: -1,
  }).then((response) => {
    if (response) {
      metadataHash.value[plugin.name] = {
        plugin,
        metadatas: response.data,
      };
    }
  });
};

/*
  Hooks
*/
onBeforeMount(() => {
  props.plugins.forEach((plugin: Plugin) => {
    loadMetadatas(plugin);
  });
});
</script>
