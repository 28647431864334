import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, unref as _unref, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "main has-navbar-fixed-top"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "section"
}
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "columns is-multiline" }
const _hoisted_6 = { class: "column is-full" }
const _hoisted_7 = { class: "box" }
const _hoisted_8 = { class: "help" }
const _hoisted_9 = { class: "column is-full has-text-right" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = ["disabled"]

import {
  ref,
  onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import PageHeader from '@/components/header/PageHeader.vue';
import useUserStore from '@/store/user';
import User from '@/models/user';
import Profile from '@/models/user/bio/profile';
import Style from '@/models/user/bio/style';
import UserBio from '@/models/user/bio/userBio';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';
import UsernameInput from '@/components/form/UsernameInput.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserBioManagerLayout',
  setup(__props) {

const router = useRouter();
const {
  usersDoLoadBio,
  usersDoGetMyUser,
  usersDoUpdateMyUser,
} = useUserStore();

/*
  Data
*/
const dataLoaded = ref(false);
const isLoading = ref(true);
const myProfile = ref({} as User);
const myBio = ref({});
const username = ref('');
const usernameError = ref('');

/*
  Methods
*/
const redirectDashboard = ((error: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar a página. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));
const load = async () => {
  isLoading.value = true;
  await usersDoGetMyUser()
    .then((response) => {
      if (response === undefined) {
        throw new Error('O perfil que você tento acessar está indisponível no momento. Recarregue a página e tente novamente');
      }
      myProfile.value = response;
    }).catch((error) => redirectDashboard(error.toString()));

  await usersDoLoadBio(myProfile.value.username)
    .then((response) => {
      if (response === undefined) {
        myBio.value = new UserBio({
          username: myProfile.value.username,
          style: new Style(),
          live: null,
          profile: new Profile(),
          links: [],
        });
      } else {
        myBio.value = response;
      }
    }).catch((error) => {
      console.error(error);
      // redirectDashboard(error.toString());
    });

  isLoading.value = false;
  dataLoaded.value = true;
};
const submitForm = async (payload = {}) => {
  isLoading.value = true;
  await usersDoUpdateMyUser(payload).then(async (success) => {
    if (success === false) {
      throw new Error('Não foi possível completar sua solicitação. Tente novamente mais tarde.');
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }).catch((error) => {
    isLoading.value = false;
    let message;
    if (error.response?.data?.error) {
      message = error.response?.data?.error;
    }
    if (message === undefined) {
      message = error;
    }
    if (error === undefined) {
      message = 'Não foi possível completar sua solicitação. Tente novamente mais tarde. (x002)';
    }
    notify({
      duration: 5000,
      type: 'error',
      title: 'Ooops',
      text: message,
    });
  });
};
const setUsername = async () => {
  username.value = username.value.trim().toLowerCase();
  await submitForm({
    username: `@${username.value}`,
  });
};
/*
  Hooks
*/
onBeforeMount(async () => {
  await load();
  dataLoaded.value = true;
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, {
      "is-loading": isLoading.value,
      title: "Link da bio"
    }, null, 8, ["is-loading"]),
    (!isLoading.value)
      ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
          (!!myProfile.value.username && !!myBio.value.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_RouterView, {
                  username: myProfile.value.username,
                  "my-bio": myBio.value,
                  "onUpdate:myBio": _cache[0] || (_cache[0] = (value) => myBio.value = value)
                }, null, 8, ["username", "my-bio"])
              ]))
            : (_openBlock(), _createElementBlock("section", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "column is-full" }, [
                      _createElementVNode("p", { class: "title is-4" }, " Criar link da bio "),
                      _createElementVNode("p", { class: "subtitle is-size-6" }, " Crie seu link personalizado, totalmente customizável, e utilize em suas redes sociais. ")
                    ], -1)),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _cache[4] || (_cache[4] = _createElementVNode("p", { class: "title is-6" }, [
                          _createTextVNode(" Nome do perfil "),
                          _createElementVNode("span", { class: "tag ml-2" }, "obrigatório")
                        ], -1)),
                        _cache[5] || (_cache[5] = _createElementVNode("p", { class: "subtitle is-7" }, " Você terá uma url exclusiva para compartilhar com seu público ", -1)),
                        (_openBlock(), _createBlock(_resolveDynamicComponent(UsernameInput), {
                          modelValue: username.value,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((username).value = $event)),
                          "input-id": "username",
                          "is-loading": isLoading.value,
                          "input-required": false,
                          "input-label": "",
                          "input-placeholder": "por ex: leandroxbr",
                          "input-min-length": 3,
                          "input-min-words": 1,
                          "input-max-length": 30,
                          "input-min-words-error": "No mínimo 3 caracteres",
                          "input-auto-complete": "username",
                          "input-error": usernameError.value,
                          "onUpdate:inputError": _cache[2] || (_cache[2] = (value) => usernameError.value = value)
                        }, null, 40, ["modelValue", "is-loading", "input-error"])),
                        _createElementVNode("p", _hoisted_8, _toDisplayString(username.value.length) + " de 30 caracteres. Você pode usar letras, números, pontos finais e sublinhados. ", 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "button is-inverted mr-2",
                        style: {"background-color":"transparent","border-color":"transparent"},
                        disabled: isLoading.value,
                        onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_unref(router).push({ name: 'DashboardPage' })), ["prevent"]))
                      }, " Cancelar ", 8, _hoisted_10),
                      _createElementVNode("button", {
                        type: "button",
                        class: "button is-primary",
                        disabled: isLoading.value || username.value.length < 3,
                        onClick: _withModifiers(setUsername, ["prevent"])
                      }, " Criar link da bio ", 8, _hoisted_11)
                    ])
                  ])
                ])
              ]))
        ]))
      : _createCommentVNode("", true),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_notifications, { classes: "toast-notification" })
  ], 64))
}
}

})