import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto min-h-full" }

import {
  ref,
  computed,
  onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import useBillingStore from '@/store/billing';
import { useI18n } from 'vue-i18n';
import SimpleHeader from '@/components/tailwind/header/SimpleHeader.vue';
import SimpleFooter from '@/components/tailwind/footer/SimpleFooter.vue';
// import PageHeader from '@/components/header/PageHeader.vue';
// import LoadingComponent from '../shared/LoadingComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PricingLayout',
  setup(__props) {

const { t } = useI18n();
const router = useRouter();

const {
  getBillingPlans,
  getBillingPlansPrices,
} = useBillingStore();

/*
  Data
*/
const billingPlans = ref();
const isLoading = ref(true);

/*
  Computed
*/
const page = computed((): string => {
  const { query } = router.currentRoute.value;
  return `${query.page}`;
});
const pluginId = computed((): string => {
  const { query } = router.currentRoute.value;
  return `${query.pluginId}`;
});
const pluginType = computed((): string => {
  const { query } = router.currentRoute.value;
  return `${query.pluginType}`;
});
const pluginName = computed((): string => {
  const { query } = router.currentRoute.value;
  return `${query.pluginName}`;
});
const accountId = computed((): string => {
  const { query } = router.currentRoute.value;
  return `${query.accountId}`;
});
const mediaId = computed((): string => {
  const { query } = router.currentRoute.value;
  return `${query.mediaId}`;
});
const successReturnTo = computed((): string => {
  const { query } = router.currentRoute.value;
  return `${query.successReturnTo}`;
});

/*
  Methods
*/
const handleClose = async () => {
  router.push({
    name: 'PluginLiveReportPage',
    params: {
      pluginId, pluginType, accountId, mediaId,
    },
    query: { pluginName },
  });
};
const loadBillingPlans = async () => {
  billingPlans.value = await getBillingPlans({
    mode: 'production',
  });
};
const loadBillingPlansPrices = async () => {
  // Extract all price IDs from billingPlans
  const priceIds = Object.values(
    billingPlans.value.products,
  ).flatMap((type: any) => Object.values(type.product).map((plan: any) => plan.default_price.id));

  try {
    // Call the API to fetch price details
    const prices: any = await getBillingPlansPrices({ priceIds });

    // Update billingPlans with enriched price details
    billingPlans.value = {
      ...billingPlans.value,
      products: Object.fromEntries(
        Object.entries(billingPlans.value.products).map(([typeKey, typeValue]: any) => {
          const updatedProducts = Object.fromEntries(
            Object.entries(typeValue.product).map(([orderKey, plan]: any) => {
              const priceDetails = prices[plan.default_price.id];
              return [
                orderKey,
                {
                  ...plan,
                  default_price: {
                    ...plan.default_price,
                    // Attach price details or an empty object if not found
                    details: priceDetails || {},
                  },
                },
              ];
            }),
          );
          return [typeKey, { ...typeValue, product: updatedProducts }];
        }),
      ),
    };
  } catch (error) {
    console.error('Error loading billing plan prices:', error);
    alert('Failed to load price details. Please try again.');
  }
};

/*
  Hooks
*/
onMounted(async () => {
  await loadBillingPlans();
  loadBillingPlansPrices();
  isLoading.value = false;
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(SimpleHeader, {
        "on-close": handleClose,
        pages: [
        {
          name: _unref(t)('sidemenu.navigation.integration.title'), href: _unref(router).resolve({ name: 'PluginsManagerPage' }).href, current: false },
        {
          name: `@${pluginName.value}`, href: _unref(router).resolve({
            name: 'PluginLivesPage',
            params: {
              pluginId: `${pluginId.value}`,
              pluginType: `${pluginType.value}`,
            },
            query: {
              tab: 'reports',
            },
          }).href, current: false },
        {
          name: _unref(t)('sidemenu.navigation.report-details.title'), href: _unref(router).resolve({
            name: 'PluginLiveReportPage',
            params: { pluginId: pluginId.value, pluginType: pluginType.value, accountId: accountId.value, mediaId: mediaId.value },
            query: { pluginName: pluginName.value, },
          }).href, current: false
        },
        {
          name: _unref(t)('report.dashboard.subheader.credits'), href: '#', current: true
        },
      ]
      }, null, 8, ["pages"]),
      (!isLoading.value)
        ? (_openBlock(), _createBlock(_component_RouterView, {
            key: 0,
            "products-list": billingPlans.value,
            "success-return-to": successReturnTo.value
          }, null, 8, ["products-list", "success-return-to"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(SimpleFooter, { horizontal: true })
  ], 64))
}
}

})