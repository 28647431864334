import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {
  ref,
  computed,
  onBeforeMount,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import useStreamStore from '@/store/stream';
import Stream from '@/models/stream';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'StreamManagerLayout',
  setup(__props) {

const router = useRouter();
const {
  getMyStream,
  streamsDoGetMy,
  streamStore,
} = useStreamStore();

/*
  Data
*/
const myStream = ref({});
const isLoading = ref(true);

/*
  Computed
*/
const streamUuid = computed(() => router.currentRoute.value.params.uuid);

/*
  Methods
*/
const resetFields = async (stream: Stream) => {
  isLoading.value = true;
  if (stream == null || stream.uuid === undefined) {
    await streamsDoGetMy({ uuid: streamUuid.value })
      .then((response) => {
        const { data: rStreams, pagination } = response;
        if (pagination && pagination.total === 0) {
          throw new Error('Não foi possível carregar sua live. Tente novamente em instantes.');
        }
        [myStream.value] = rStreams.filter((s: Stream) => s.uuid === streamUuid.value);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        isLoading.value = false;
      });
  } else {
    myStream.value = stream;
  }
};

/*
  Hooks
*/
onBeforeMount(async () => {
  resetFields();
});
watch(streamStore, () => {
  myStream.value = getMyStream();
}, { deep: true });

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RouterView, {
      stream: myStream.value,
      "onUpdate:myStream": resetFields
    }, null, 8, ["stream"]),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, {
          key: 0,
          "full-screen": true
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})