import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/backgrounds/blank-profile-picture.png'


const _hoisted_1 = { class: "max-w-screen-xl mx-auto mt-4 px-4 xl:px-0" }
const _hoisted_2 = {
  key: 0,
  class: "animate-pulse"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "columns is-vcentered" }
const _hoisted_5 = { class: "md:flex md:items-center md:justify-between md:space-x-5" }
const _hoisted_6 = { class: "flex flex-col sm:flex-row items-start space-x-5" }
const _hoisted_7 = { class: "shrink-0" }
const _hoisted_8 = { class: "relative" }
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = ["alt"]
const _hoisted_11 = { class: "pt-1.5" }
const _hoisted_12 = { class: "text-lg lg:text-2xl font-bold text-gray-900" }
const _hoisted_13 = {
  key: 0,
  class: "inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-gray-200 ring-inset bg-white"
}
const _hoisted_14 = { class: "text-sm font-medium text-gray-500" }

import {
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Plugin from '@/models/plugin/plugin';


export default /*@__PURE__*/_defineComponent({
  __name: 'PluginHeader',
  props: {
  plugin: {
    type: Object as () => Plugin,
    required: true,
  },
  instagramLiveStatus: {
    type: Object,
    required: false,
    default: null,
  },
},
  setup(__props) {

const { t } = useI18n();

/*
  Props
*/
const props = __props;

/*
  Data
*/
const isLoading = ref(true);

/*
  Hooks
*/
watch(
  () => props.plugin?.id,
  (newVal) => {
    if (newVal) {
      isLoading.value = false;
    }
  },
  { immediate: true },
);

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createStaticVNode("<div class=\"md:flex md:items-center md:justify-between md:space-x-5\"><div class=\"flex flex-col sm:flex-row items-start space-x-5\"><div class=\"shrink-0\"><div class=\"relative\"><div class=\"size-32 sm:size-16 bg-gray-300 rounded-lg\"></div><span class=\"absolute inset-0 rounded-lg shadow-inner\" aria-hidden=\"true\"></span></div></div><div class=\"pt-1.5 space-y-2\"><div class=\"h-6 w-48 bg-gray-300 rounded\"></div><div class=\"h-4 w-32 bg-gray-300 rounded\"></div></div></div></div>", 1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    (props.plugin.profilePictureBase64)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "size-32 sm:size-16 rounded-lg",
                          src: `data:image/jpeg;base64,${props.plugin.profilePictureBase64}`,
                          alt: `${props.plugin.name} logo`
                        }, null, 8, _hoisted_9))
                      : (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          class: "size-32 sm:size-16 rounded-lg",
                          src: _imports_0,
                          alt: `${props.plugin.name} no logo`
                        }, null, 8, _hoisted_10)),
                    _cache[1] || (_cache[1] = _createElementVNode("span", {
                      class: "absolute inset-0 rounded-lg shadow-inner",
                      "aria-hidden": "true"
                    }, null, -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("h1", _hoisted_12, [
                    _createTextVNode(_toDisplayString(props.plugin.name) + " ", 1),
                    (props.instagramLiveStatus?.id)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['fas', 'circle'],
                            class: "ml-2 text-green-500 animate-pulse"
                          }),
                          _createTextVNode(" " + _toDisplayString(_unref(t)('plugins.dashboard.header.live')), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("p", _hoisted_14, [
                    _createTextVNode(_toDisplayString(_unref(t)('plugins.dashboard.header.createdAt')) + " ", 1),
                    _createElementVNode("time", null, _toDisplayString(__props.plugin.formattedCreatedAt()), 1)
                  ])
                ])
              ])
            ])
          ])
        ]))
  ]))
}
}

})