<!-- eslint-disable max-len -->
<!-- eslint-disable vuejs-accessibility/no-redundant-roles -->
<template>
  <div v-if="dataLoaded">
    <!-- SIDEBAR -->
    <TransitionRoot
      as="template"
      :show="sidebarOpen"
    >
      <Dialog
        class="relative z-50 lg:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute top-0 left-full flex w-16 justify-center pt-5">
                  <button
                    type="button"
                    class="-m-2.5 p-2.5"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon
                      class="size-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4 bg-white">
                <div class="flex h-16 shrink-0 items-center">
                  <img
                    class="h-8 w-auto"
                    src="@/assets/logo2.png"
                    alt="LiveLink LLM"
                  >
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul
                    role="list"
                    class="flex flex-1 flex-col gap-y-7"
                  >
                    <li>
                      <ul
                        role="list"
                        class="-mx-2 space-y-1"
                      >
                        <li
                          v-for="item in navigation"
                          :key="item.name"
                        >
                          <a
                            :href="item.href"
                            :class="[item.current ? 'bg-gray-50 text-teal-600' : 'text-gray-700 hover:bg-gray-50 hover:text-teal-600', 'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold']"
                          >
                            <component
                              :is="item.icon"
                              :class="[item.current ? 'text-teal-600' : 'text-gray-400 group-hover:text-teal-600', 'size-6 shrink-0']"
                              aria-hidden="true"
                            />
                            {{ item.name }}
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div class="text-xs/6 font-semibold text-gray-400">
                        {{ t('sidemenu.products.title') }}
                      </div>
                      <ul
                        role="list"
                        class="-mx-2 mt-2 space-y-1"
                      >
                        <li
                          v-for="product in otherProducts"
                          :key="product.name"
                        >
                          <a
                            v-if="!product.disabled"
                            :href="product.href"
                            :class="[product.current ? 'bg-gray-50 text-teal-600' : 'text-gray-700 hover:bg-gray-50 hover:text-teal-600', 'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold']"
                          >
                            <span :class="[product.current ? 'border-teal-600 text-teal-600' : 'border-gray-200 text-gray-400 group-hover:border-teal-600 group-hover:text-teal-600', 'flex size-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium']">{{ product.initial }}</span>
                            <span class="truncate">{{ product.name }}</span>
                          </a>
                          <p
                            v-else
                            class="'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'"
                          >
                            <span class="border-gray-200 text-gray-400  flex size-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium">{{ product.initial }}</span>
                            <span class="truncate text-gray-400">{{ product.name }}</span>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li class="mt-auto">
                      <a
                        :href="router.resolve({ name: 'UserProfileManagerPage' }).href"
                        class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-gray-700 hover:bg-gray-50 hover:text-teal-600"
                      >
                        <Cog6ToothIcon
                          class="size-6 shrink-0 text-gray-400 group-hover:text-teal-600"
                          aria-hidden="true"
                        />
                        {{ t('sidemenu.navigation.settings.title') }}
                      </a>
                    </li>
                  </ul>
                  <SimpleFooter
                    :horizontal="false"
                    :no-backgound="true"
                  />
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
        <div class="flex h-16 shrink-0 items-center">
          <img
            class="h-8 w-auto"
            src="@/assets/logo2.png"
            alt="Your Company"
          >
        </div>
        <nav class="flex flex-1 flex-col">
          <ul
            role="list"
            class="flex flex-1 flex-col gap-y-7"
          >
            <li>
              <ul
                role="list"
                class="-mx-2 space-y-1"
              >
                <li
                  v-for="item in navigation"
                  :key="item.name"
                >
                  <a
                    :href="item.href"
                    :class="[item.current ? 'bg-gray-50 text-teal-600' : 'text-gray-700 hover:bg-gray-50 hover:text-teal-600', 'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold']"
                  >
                    <component
                      :is="item.icon"
                      :class="[item.current ? 'text-teal-600' : 'text-gray-400 group-hover:text-teal-600', 'size-6 shrink-0']"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <div class="text-xs/6 font-semibold text-gray-400">
                Cresça com a gente
              </div>
              <ul
                role="list"
                class="-mx-2 mt-2 space-y-1"
              >
                <li
                  v-for="product in otherProducts"
                  :key="product.name"
                >
                  <a
                    v-if="!product.disabled"
                    :href="product.href"
                    :class="[product.current ? 'bg-gray-50 text-teal-600' : 'text-gray-700 hover:bg-gray-50 hover:text-teal-600', 'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold']"
                  >
                    <span :class="[product.current ? 'border-teal-600 text-teal-600' : 'border-gray-200 text-gray-400 group-hover:border-teal-600 group-hover:text-teal-600', 'flex size-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium']">{{ product.initial }}</span>
                    <span class="truncate">{{ product.name }}</span>
                  </a>
                  <p
                    v-else
                    class="'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'"
                  >
                    <span class="border-gray-200 text-gray-400  flex size-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium">{{ product.initial }}</span>
                    <span class="truncate text-gray-400">{{ product.name }}</span>
                  </p>
                </li>
              </ul>
            </li>
            <li class="mt-auto">
              <a
                :href="router.resolve({ name: 'UserProfileManagerPage' }).href"
                class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-gray-700 hover:bg-gray-50 hover:text-teal-600"
              >
                <Cog6ToothIcon
                  class="size-6 shrink-0 text-gray-400 group-hover:text-teal-600"
                  aria-hidden="true"
                />
                {{ t('sidemenu.navigation.settings.title') }}
              </a>
            </li>
          </ul>
          <SimpleFooter
            :horizontal="false"
            :no-backgound="true"
            :hide-label="true"
          />
        </nav>
      </div>
    </div>

    <!-- Main -->
    <div class="lg:pl-72">
      <!-- Sub Header -->
      <div class="bg-gray-100 sticky top-0 z-40 lg:mx-auto lg:max-w-7xl lg:px-8">
        <div class="flex h-16 items-center gap-x-4 border-b border-gray-200 px-4 shadow-xs sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none">
          <button
            type="button"
            class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            @click="sidebarOpen = true"
          >
            <span class="sr-only">Open sidebar</span>
            <Bars3Icon
              class="size-6"
              aria-hidden="true"
            />
          </button>

          <!-- Separator -->
          <div
            class="h-6 w-px bg-gray-200 lg:hidden"
            aria-hidden="true"
          />

          <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 bg-gray-100">
            <!-- <form
              class="grid flex-1 grid-cols-1"
              action="#"
              method="GET"
            >
              <input
                type="search"
                name="search"
                aria-label="Search"
                class="col-start-1 row-start-1 block size-full bg-white pl-8 text-base text-gray-900 outline-hidden placeholder:text-gray-400 sm:text-sm/6"
                placeholder="Search"
              >
              <MagnifyingGlassIcon
                class="pointer-events-none col-start-1 row-start-1 size-5 self-center text-gray-400"
                aria-hidden="true"
              />
            </form> -->
            <div class="grid flex-1 grid-cols-1" />
            <div class="flex items-center gap-x-4 lg:gap-x-6">
              <!-- <button
                type="button"
                class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
              >
                <span class="sr-only">View notifications</span>
                <BellIcon
                  class="size-6"
                  aria-hidden="true"
                />
              </button> -->

              <!-- Separator -->
              <div
                class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                aria-hidden="true"
              />

              <!-- Profile dropdown -->
              <Menu
                as="div"
                class="relative"
              >
                <MenuButton class="-m-1.5 flex items-center p-1.5">
                  <span class="sr-only">Open user menu</span>
                  <img
                    class="size-8 rounded-full bg-gray-50"
                    :src="getProfileImageUrl()"
                    alt=""
                  >
                  <span class="hidden lg:flex lg:items-center">
                    <span
                      class="ml-4 text-sm/6 font-semibold text-gray-900"
                      aria-hidden="true"
                    >
                      {{ getUserFirstName }}
                    </span>
                    <ChevronDownIcon
                      class="ml-2 size-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </MenuButton>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems class="bg-white absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md py-2 ring-1 shadow-lg ring-gray-900/5 focus:outline-hidden">
                    <MenuItem
                      v-for="item in userNavigation"
                      :key="item.name"
                      v-slot="{ active }"
                    >
                      <a
                        :href="item.href"
                        :class="[active ? 'bg-gray-50 outline-hidden' : '', 'block px-3 py-1 text-sm/6 text-gray-900']"
                      >{{ item.name }}</a>
                    </MenuItem>
                    <MenuItem
                      v-slot="{ active }"
                    >
                      <a
                        href="#"
                        :class="[active ? 'bg-gray-50 outline-hidden' : '', 'block px-3 py-1 text-sm/6 text-gray-900']"
                        @click="logout"
                      >{{ t('sidemenu.navigation.signout.title') }}</a>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      <main class="py-4">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="border-b border-gray-200 pb-5">
            <h3 class="font-semibold text-gray-900 text-xl">
              {{ t('dashboard.title', {name: getUserFirstName}) }}
            </h3>
          </div>

          <!-- IF USER DOES NOT HAVE A PLUGIN -->
          <div
            v-if="plugins.length === 0"
            class="bg-white"
          >
            <div class="px-6 py-16 sm:px-6 sm:py-32 lg:px-8">
              <div class="mx-auto max-w-2xl text-center">
                <h2 class="text-4xl font-semibold tracking-tight text-balance text-gray-900 sm:text-5xl">
                  {{ t('dashboard.plugin.calltoaction.title') }}
                </h2>
                <p class="mx-auto mt-6 max-w-xl text-lg/8 text-pretty text-gray-600">
                  {{ t('dashboard.plugin.calltoaction.subtitle') }}
                </p>
                <div class="mt-10 flex items-center justify-center gap-x-6">
                  <a
                    href="#"
                    class="rounded-md bg-teal-500 px-3.5 py-2.5 text-lg font-semibold text-white shadow-xs hover:bg-teal-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-700"
                    @click.prevent="instagramConnectStart"
                  >
                    {{ t('dashboard.plugin.calltoaction.button') }}
                  </a>
                  <a
                    href="#"
                    class="text-sm/6 font-semibold text-gray-900"
                    @click.prevent="showHelpModal = true"
                  >
                    {{ t('dashboard.plugin.calltoaction.learnmore') }} <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <!-- IF USER HAS A PLUGIN -->
          <div
            v-else
            class=""
          >
            <!-- STATS -->
            <div class="mt-8">
              <PluginMetadatasMetrics />
            </div>
            <div class="mt-8">
              <ListPluginReportsDashboard
                :plugins="plugins"
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>

  <RightSideDrawer
    v-model:show="showHelpModal"
    bg-color="bg-gray-100"
  >
    <!-- Custom Header -->
    <template #header>
      <DialogTitle class="px-4 py-6 sm:px-6 ">
        <p class="text-base font-semibold text-gray-900">
          {{ t('plugins.manager.modal.help.title') }}
        </p>
        <p class="mt-1 text-md text-gray-900">
          {{ t('plugins.manager.modal.help.description') }}
        </p>
      </DialogTitle>
    </template>

    <!-- Custom Content -->
    <template #content>
      <div class="px-4 py-6 sm:px-6">
        <dl class="space-y-8">
          <div>
            <dt class="text-sm font-medium text-gray-500 sm:shrink-0">
              <strong>{{ t('plugins.manager.modal.help.permissions.title') }}</strong>
              <br>
              <p class="italic text-gray-500 mt-2">
                {{ t('plugins.manager.modal.help.permissions.error') }}
              </p>
            </dt>
            <dd class="text-sm text-gray-900 sm:col-span-2 mt-4">
              <p>{{ t('plugins.manager.modal.help.permissions.solution') }}</p>
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500 sm:shrink-0">
              <strong>{{ t('plugins.manager.modal.help.admin.title') }}</strong>
              <br>
              <p class="italic text-gray-500 mt-2">
                {{ t('plugins.manager.modal.help.admin.error') }}
              </p>
            </dt>
            <dd class="text-sm text-gray-900 sm:col-span-2 mt-4">
              <p>{{ t('plugins.manager.modal.help.admin.solution') }}</p>
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500 sm:shrink-0">
              <strong>{{ t('plugins.manager.modal.help.link.title') }}</strong>
              <br>
              <p class="italic text-gray-500 mt-2">
                {{ t('plugins.manager.modal.help.link.error') }}
              </p>
            </dt>
            <dd class="text-sm text-gray-900 sm:col-span-2 mt-4">
              <p>{{ t('plugins.manager.modal.help.link.solution') }}</p>
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500 sm:shrink-0">
              <strong>{{ t('plugins.manager.modal.help.selection.title') }}</strong>
              <br>
              <p class="italic text-gray-500 mt-2">
                {{ t('plugins.manager.modal.help.selection.error') }}
              </p>
            </dt>
            <dd class="text-sm text-gray-900 sm:col-span-2 mt-4">
              <p>{{ t('plugins.manager.modal.help.selection.solution') }}</p>
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500 sm:shrink-0">
              <strong>{{ t('plugins.manager.modal.help.personal.title') }}</strong>
              <br>
              <p class="italic text-gray-500 mt-2">
                {{ t('plugins.manager.modal.help.personal.error') }}
              </p>
            </dt>
            <dd class="text-sm text-gray-900 sm:col-span-2 mt-4">
              <p>{{ t('plugins.manager.modal.help.personal.solution') }}</p>
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500 sm:shrink-0">
              <strong>{{ t('plugins.manager.modal.help.token.title') }}</strong>
              <br>
              <p class="italic text-gray-500 mt-2">
                {{ t('plugins.manager.modal.help.token.error') }}
              </p>
            </dt>
            <dd class="text-sm text-gray-900 sm:col-span-2 mt-4">
              <p>{{ t('plugins.manager.modal.help.token.solution') }}</p>
            </dd>
          </div>
          <div>
            <button
              type="button"
              class="inline-flex w-full shrink-0 items-center justify-center rounded-md bg-teal-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-teal-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-500 sm:flex-1"
              @click="navigateWhatsapp(t('plugins.manager.modal.help.button1'))"
            >
              {{ t('plugins.manager.modal.help.button1') }}
            </button>
          </div>
        </dl>
      </div>
    </template>
  </RightSideDrawer>
</template>

<script setup lang="ts">
import {
  ref, computed, onBeforeMount, onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import useAuthStore from '@/store/auth';
import SimpleFooter from '@/components/tailwind/footer/SimpleFooter.vue';
import RightSideDrawer from '@/components/tailwind/shared/RightSideDrawer.vue';
import ListPluginReportsDashboard from '@/components/tailwind/plugin/ListPluginReportsDashboard.vue';
import PluginMetadatasMetrics from '@/components/tailwind/plugin/PluginMetadatasMetrics.vue';
import BlankProfilePicture from '@/assets/images/backgrounds/blank-profile-picture.png';
import {
  Dialog,
  DialogTitle,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  Bars3Icon,
  // BellIcon,
  PresentationChartBarIcon,
  Cog6ToothIcon,
  PuzzlePieceIcon,
  HomeIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { useI18n } from 'vue-i18n';
import usePluginStore from '@/store/plugin/plugin';
import Plugin from '@/models/plugin/plugin';

const router = useRouter();
const { session, authDoLogout } = useAuthStore();
const { t } = useI18n();
const {
  pluginsDoGetMy,
  pluginStore,
} = usePluginStore();

/*
  Data
*/
const dataLoaded = ref(false);
const sidebarOpen = ref(false);
const showHelpModal = ref(false);
const plugins = ref([] as Plugin[]);
const stats = computed(() => [
  { name: 'Lives realizadas', stat: '300' },
  { name: 'Total de mensagens', stat: '800' },
  { name: 'Seguidores de live', stat: '537' },
]);

/*
  Methods
*/
const navigateWhatsapp = (message: string) => {
  window.open(`https://wa.me/+5511994991786?text=${message}`, '_blank');
};
const openPluginLivesPage = async (pluginId: string, pluginType: string) => {
  await router.push({
    name: 'PluginLivesPage',
    params: {
      pluginId,
      pluginType,
    },
  });
};

const loadPlugins = () => {
  pluginsDoGetMy()
    .then((response) => {
      if (response !== undefined) {
        plugins.value = response.data;
      }
    }).catch((error) => {
      console.error('error', error);
    });
};

const instagramConnectStart = () => {
  // isLoading.value = true;
  // const instagramUrl = process.env.VUE_APP_INSTAGRAM_OAUTH_REDIRECT_URL;
  // FIXME: USE E ENV VAR VUE_APP_INSTAGRAM_OAUTH_REDIRECT_URL
  // const instagramUrl = 'https://www.instagram.com/oauth/authorize?client_id=1035072877787285&redirect_uri=https://www.livelink.com.br/plugins/callback/instagram&response_type=code';
  // const scope = process.env.VUE_APP_INSTAGRAM_OAUTH_BUSINESS_SCOPE;
  // const url = 'https://r4gcpe9kb7.execute-api.sa-east-1.amazonaws.com/plugins/callback/instagram';
  // const url = 'https://www.livelink.com.br/plugins/callback/instagram';
  const queryParams = [
    'client_id=1035072877787285',
    'redirect_uri=https://www.livelink.com.br/plugins/callback/instagram',
    'response_type=code',
    'auth_type=reauthenticate',
    'prompt=consent',
    'display=popup',
  ];
  const instagramUrl = `https://www.instagram.com/oauth/authorize?${queryParams.join('&')}`;
  pluginStore.csrf = Math.random().toString(36).substring(2, 15);
  const scope = 'instagram_business_basic,instagram_business_manage_comments,instagram_business_manage_messages';
  window.location.href = `${instagramUrl}&state=${pluginStore.csrf}&scope=${scope}`;
};
const getProfileImageUrl = () => session?.profileImageUrl || BlankProfilePicture;
const logout = async () => {
  await authDoLogout().then(() => {
    window.open(router.resolve({ name: 'SessionLoginPage' }).href, '_self');
  }).catch((error) => {
    console.error(error);
    alert(t('session.logoutError'));
    // }).finally(() => {
    // window.location.reload();
  });
};

/*
  Computed
*/
const getUserFirstName = computed(() => session?.name.split(' ')[0]);
const otherProducts = computed(() => [
  {
    id: 1, name: t('sidemenu.navigation.bio.title'), href: router.resolve({ name: 'UserBioManagerPage' }).href, initial: t('sidemenu.navigation.bio.initials'), current: false, disabled: false,
  },
  {
    id: 2, name: t('sidemenu.navigation.myBrand.title'), href: router.resolve({ name: 'MyMerchantsPage' }).href, initial: t('sidemenu.navigation.myBrand.initials'), current: false, disabled: false,
  },
  // {
  //   id: 3, name: t('sidemenu.navigation.crm.title'), href: '#',
  // initial: t('sidemenu.navigation.crm.initials'), current: false, disabled: true,
  // },
]);
const userNavigation = computed(() => [
  { name: t('sidemenu.navigation.profile.title'), href: router.resolve({ name: 'UserProfileManagerPage' }).href, method: null },
  // { name: t('sidemenu.navigation.signout.title'), href: '#', method: logout },
]);
const navigation = computed(() => [
  {
    name: t('sidemenu.navigation.dashboard.title'), href: router.resolve({ name: 'DashboardPage' }).href, icon: HomeIcon, current: true,
  },
  {
    name: t('sidemenu.navigation.integration.title'), href: router.resolve({ name: 'PluginsManagerPage' }).href, icon: PuzzlePieceIcon, current: false,
  },
  {
    name: t('sidemenu.navigation.shared-report.title'), href: router.resolve({ name: 'PluginLivesSharedWithMePage' }).href, icon: PresentationChartBarIcon, current: false,
  },
  // {
  //   name: t('sidemenu.navigation.report-shared-with-me.title'),
  // href: '#', icon: ArrowUpOnSquareIcon, current: false,
  // },
  // {
  //   name: 'Calendar', href: '#', icon: CalendarIcon, current: false,
  // },
  // {
  //   name: 'Team', href: '#', icon: UsersIcon, current: false,
  // },
  // {
  //   name: 'Documents', href: '#', icon: DocumentDuplicateIcon, current: false,
  // },
]);

/*
  Hooks
*/
onBeforeMount(() => {
  loadPlugins();
});
onMounted(() => {
  setTimeout(() => {
    dataLoaded.value = true;
  }, 100);
});
</script>
