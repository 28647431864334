<template>
  <RouterView
    :stream="myStream"
    @update:my-stream="resetFields"
  />
  <LoadingComponent
    v-if="isLoading"
    :full-screen="true"
  />
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onBeforeMount,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import useStreamStore from '@/store/stream';
import Stream from '@/models/stream';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';

const router = useRouter();
const {
  getMyStream,
  streamsDoGetMy,
  streamStore,
} = useStreamStore();

/*
  Data
*/
const myStream = ref({});
const isLoading = ref(true);

/*
  Computed
*/
const streamUuid = computed(() => router.currentRoute.value.params.uuid);

/*
  Methods
*/
const resetFields = async (stream: Stream) => {
  isLoading.value = true;
  if (stream == null || stream.uuid === undefined) {
    await streamsDoGetMy({ uuid: streamUuid.value })
      .then((response) => {
        const { data: rStreams, pagination } = response;
        if (pagination && pagination.total === 0) {
          throw new Error('Não foi possível carregar sua live. Tente novamente em instantes.');
        }
        [myStream.value] = rStreams.filter((s: Stream) => s.uuid === streamUuid.value);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        isLoading.value = false;
      });
  } else {
    myStream.value = stream;
  }
};

/*
  Hooks
*/
onBeforeMount(async () => {
  resetFields();
});
watch(streamStore, () => {
  myStream.value = getMyStream();
}, { deep: true });
</script>

<style lang="scss" scoped>
</style>
