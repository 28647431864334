import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import useAxios from '@/libs/axios';
import eventBus from '@/eventBus/eventBus';
import BillingCredit from '@/models/billing/billingCredit';
import StripeCheckoutSession from '@/models/billing/stripeCheckoutSession';

export default defineStore('billingStore', () => {
  const axios = useAxios();

  /*
    State
  */
  const billingStore = ref({
    plans: {},
    customer: {},
    portalURL: '',
  });

  /*
    Actions
  */
  // Reset the whole store and remove it from localStorage
  // after user logout
  const resetStore = () => {
    localStorage.removeItem('billingStore');
  };

  const openBillingPortal = async (params: {
    stripeCustomerId: string,
    returnURL: string,
  }) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      stripe_customer_id: params.stripeCustomerId,
      return_url: params.returnURL,
    };

    return axios.post('/billing/portal', payload, config)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Erro ao abrir billing portal: ${response.statusText}`);
        }
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const openPurchaseSession = async (params: {
    productId: string,
    productPriceId: string,
    productType: string,
    productCurrency: string,
    successUrl: string,
    cancellUrl: string,
}) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      product_id: params.productId,
      product_price_id: params.productPriceId,
      product_type: params.productType,
      product_currency: params.productCurrency,
      success_url: params.successUrl,
      cancel_url: params.cancellUrl,
    };

    return axios.post('/billing/portal/purchase/session', payload, config)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Erro ao abrir purchase page: ${response.statusText}`);
        }

        const { columns, row } = response.data;
        const stripeCheckoutSession = StripeCheckoutSession.fromApiResponse(columns, [row]);

        if (stripeCheckoutSession.length === 0) {
          throw new Error('Erro ao abrir purchase page: stripeCheckoutSession is empty');
        }

        return stripeCheckoutSession[0].sessionUrl;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const getBillingPlans = async (params:{
    mode: string,
  }) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      mode: params.mode,
    };

    return axios.post('/billing/plans', payload, config)
      .then((response) => {
        if (response.status === 204) { // No content
          billingStore.value.plans = {};
          return billingStore.value.plans;
        }

        if (response.status !== 200) {
          throw new Error(`Erro ao buscar billing plans: ${response.statusText}`);
        }

        if (!response.data) {
          return billingStore.value.plans;
        }

        billingStore.value.plans = response.data;
        return billingStore.value.plans;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const consumeBillingCredit = async (params:{
    mediaId: string,
    billingCreditId: string,
  }) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      media_id: params.mediaId,
      billing_credit_id: params.billingCreditId,
    };

    return axios.post('/billing/credit/consume', payload, config)
      .then((response) => {
        if (response.status === 204) { // No content
          billingStore.value.plans = {};
          return billingStore.value.plans;
        }

        if (response.status !== 200) {
          throw new Error(`Erro ao buscar billing plans prices: ${response.statusText}`);
        }

        if (!response.data) {
          return billingStore.value.plans;
        }

        billingStore.value.plans = response.data;
        return billingStore.value.plans;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const getBillingPlansPrices = async (params:{
    priceIds: string[],
  }) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      price_ids: params.priceIds,
    };

    return axios.post('/billing/plans/prices', payload, config)
      .then((response) => {
        if (response.status === 204) { // No content
          billingStore.value.plans = {};
          return billingStore.value.plans;
        }

        if (response.status !== 200) {
          throw new Error(`Erro ao buscar billing plans prices: ${response.statusText}`);
        }

        if (!response.data) {
          return billingStore.value.plans;
        }

        billingStore.value.plans = response.data;
        return billingStore.value.plans;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const getReportBillingCredit = async (params:{
    mediaId: string,
  }) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      media_id: params.mediaId,
    };

    return axios.post('/billing/credit/report', payload, config)
      .then((response) => {
        if (response.status === 204) { // No content
          return null;
        }

        if (response.status !== 200) {
          throw new Error(`Erro ao buscar billing customer: ${response.statusText}`);
        }

        if (!response.data) {
          return null;
        }

        const { columns, row } = response.data;
        const billingCredits = BillingCredit.fromApiResponse(columns, [row]);

        if (billingCredits.length === 0) {
          return null;
        }

        return billingCredits[0];
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const getMyBillingCredits = async (params:{
    mediaId?: string,
    page?: number,
    limit?: number,
    orderBy?: string,
    order?: number,
    dateChosen?: Date,
    hasAvailableCredits?: boolean,
  }) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    let payload = {
      page: params.page,
      limit: params.limit,
      order_by: params.orderBy,
      order: params.order,
      media_id: params.mediaId,
      date_chosen: params.dateChosen,
      has_available_credits: params.hasAvailableCredits,
    };

    if (!params.mediaId) {
      delete payload.media_id;
    }

    if (!params.dateChosen) {
      delete payload.date_chosen;
    }

    if (!params.hasAvailableCredits) {
      delete payload.has_available_credits;
    }

    if (!params.page) {
      payload = {
        ...payload,
        page: 1,
      };
    }
    if (!params.limit) {
      payload = {
        ...payload,
        limit: 50,
      };
    }
    if (!params.orderBy) {
      payload = {
        ...payload,
        order_by: 'createdAt',
      };
    }
    if (!params.order) {
      payload = {
        ...payload,
        order: -1,
      };
    }

    return axios.post('/billing/credit/my', payload, config)
      .then((response) => {
        if (response.status === 204) { // No content
          return null;
        }

        if (response.status !== 200) {
          throw new Error(`Erro ao buscar billing customer: ${response.statusText}`);
        }

        if (!response.data) {
          return null;
        }

        const { columns, rows } = response.data;
        const billingCredits = BillingCredit.fromApiResponse(columns, rows);

        return billingCredits;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const getBillingCustomer = async (params:{
    userUuid: string,
  }) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      user_uuid: params.userUuid,
    };

    return axios.post('/billing/customer', payload, config)
      .then((response) => {
        if (response.status === 204) { // No content
          billingStore.value.customer = {};
          return billingStore.value.customer;
        }

        if (response.status !== 200) {
          throw new Error(`Erro ao buscar billing customer: ${response.statusText}`);
        }

        if (!response.data) {
          return billingStore.value;
        }

        billingStore.value.customer = response.data;
        return billingStore.value.customer;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  /*
    Hooks
  */
  // Listen to logout event
  // This event is used to clear the store
  eventBus.on('event:logout', resetStore);

  // Load store from localStorage
  // This is necessary to keep the store
  // when user refresh the page
  if (localStorage.getItem('billingStore')) {
    billingStore.value = JSON.parse(localStorage.getItem('billingStore') || '{}');
  }

  watch(billingStore, (billingsVal) => {
    localStorage.setItem('billingStore', JSON.stringify(billingsVal));
  }, {
    deep: true,
  });

  return {
    billingStore,
    resetStore,
    getMyBillingCredits,
    getReportBillingCredit,
    getBillingCustomer,
    getBillingPlans,
    getBillingPlansPrices,
    openBillingPortal,
    openPurchaseSession,
    consumeBillingCredit,
  };
});
