<template>
  <PageHeader
    :is-loading="false"
    title="Lojas e Marcas"
    to="HomePage"
  >
    <template
      v-if="stream.showProductsOnLanding"
      #content
    >
      <div class="mr-4">
        <YampiShoppingCartComponent
          v-if="stream.productGateway === 'livelink-yampi'"
          :stream-uuid="stream.uuid"
          :merchant-uuid="stream.merchantUuid"
          button-color="is-black"
        />
      </div>
    </template>
  </PageHeader>
  <main class="main has-navbar-fixed-top">
    <RouterView
      v-if="isDataLoaded"
      :stream-uri="streamUri"
      :stream="stream"
    />
  </main>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onBeforeMount,
  onUnmounted,
} from 'vue';
import { useRouter } from 'vue-router';
import useStreamStore from '@/store/stream';
import PageHeader from '@/components/header/PageHeader.vue';
import YampiShoppingCartComponent from '@/components/stream/YampiShoppingCartComponent.vue';

const router = useRouter();
const { streamDoGet, resetStore } = useStreamStore();

/*
  Computed
*/
const streamUri = computed(() => router.currentRoute.value.params.uri);

/*
  Data
*/
const isDataLoaded = ref(false);
const stream = ref({});

/*
  Methods
*/
const load = async () => {
  await streamDoGet(streamUri.value).then((result) => {
    if (result === undefined) {
      router.push({ name: 'NotFoundPage' });
    } else {
      stream.value = result;
    }
    if (!stream.value.landingPageActive) {
      router.push({ name: 'HomePage' });
    }
    isDataLoaded.value = true;
  }).catch((error) => {
    console.error(error);
  });
};

/*
  Hooks
*/
onBeforeMount(() => {
  load();
});
onUnmounted(() => {
  resetStore();
});
</script>

<style lang="scss" scoped>
</style>
