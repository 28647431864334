import Product from '@/models/yampi/product/product';
import Sku from '@/models/yampi/product/sku';
import ProductQuantity from '@/models/yampi/shoppingCart/productQuantity';

class ShoppingCart {
  streamUuid: string;

  productQuantity: ProductQuantity[];

  total: number;

  updatedAt = new Date();

  constructor({
    streamUuid,
    productQuantity,
    total,
  }: {
    streamUuid: string;
    productQuantity: ProductQuantity[];
    total: number;
  }) {
    this.streamUuid = streamUuid;
    this.productQuantity = productQuantity;
    this.total = total;
  }

  getProductNames() {
    return this.productQuantity.map((productQuantity) => productQuantity?.product?.name);
  }

  getTotalPrice() {
    return this.productQuantity.reduce(
      (total, productQuantity) => total + productQuantity.getTotalPrice(),
      0,
    );
  }

  getFormattedTotalPrice() {
    const minOptions = {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    return this.getTotalPrice().toLocaleString('pt-BR', minOptions);
  }

  addToCart(
    product: Product,
    productSku: Sku,
    quantity: number,
  ) {
    this.productQuantity.push(new ProductQuantity({
      quantity,
      productSku,
      product,
    }));
  }

  getCheckoutUrl(streamUuid: string, merchantUuid: string): URL {
    const params = this.productQuantity.map((pq: ProductQuantity) => pq.getCheckoutUrlParams());
    const queryParams = `?utm_campaign=${streamUuid}&utm_source=${merchantUuid}&utm_medium=live&utm_content=shoppingCart`;
    return new URL(`https://conhecendo-yampi.pay.yampi.com.br/r/${params.join(',')}${queryParams}`);
  }

  increaseQuantity(productSKu: string) {
    const index = this.productQuantity.findIndex(
      (productQuantity) => productQuantity.productSku?.token === productSKu,
    );
    if (index !== -1 && this.productQuantity[index].quantity < 10) {
      this.productQuantity[index].increaseQuantity();
    }
  }

  decreaseQuantity(productSKu: string) {
    const index = this.productQuantity.findIndex(
      (productQuantity) => productQuantity.productSku?.token === productSKu,
    );
    if (index !== -1) {
      if (this.productQuantity[index].quantity === 1) {
        // eslint-disable-next-line no-alert
        const confirmed = window.confirm('Deseja remover este produto do seu carrinho?');
        if (!confirmed) {
          return;
        }
      }
      this.productQuantity[index].decreaseQuantity();
      if (this.productQuantity[index].quantity === 0) {
        this.productQuantity.splice(index, 1);
      }
    }
  }

  removeFromCart(productSKu: string) {
    const index = this.productQuantity.findIndex(
      (productQuantity) => productQuantity.productSku?.token === productSKu,
    );
    if (index !== -1) {
      this.productQuantity.splice(index, 1);
    }
  }
}

export default ShoppingCart;
