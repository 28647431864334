import mixpanel from 'mixpanel-browser';
import UtmParamsStore from '@/store/utm/utm';

import {
  RouteRecordName,
} from 'vue-router';

const config = {
  MinMessageLength: 1,
  EventActions: {},
  EventActionsDetail: {},
  EventKeys: {
    TriggeredFrom: 'Triggered From',
    Action: 'Action',
    ActionDetail: 'Action Detail',
    MerchantUUID: 'Merchant UUID',
    StreamUUID: 'Stream UUID',

    UtmSource: 'UTM Source',
    UtmMedium: 'UTM Medium',
    UtmCampaign: 'UTM Campaign',
    UtmTerm: 'UTM Term',
    UtmContent: 'UTM Content',
  },
};

mixpanel.init((
  process.env.VUE_APP_MIXPANEL_TOKEN), { debug: process.env.NODE_ENV !== 'production' });

const peopleIncrement = (eventName: string, total: number) => {
  try {
    mixpanel.people.increment(eventName, total);
  } catch (error) {
    console.error('Error incrementing event', error);
  }
};

const userIdentify = (userId: string) => {
  try {
    mixpanel.identify(userId);
  } catch (error) {
    console.error('Error identifying user', error);
  }
};

const mixpanelTrack = (params: {
  eventName: string,
  eventAction: string,
  eventActionDetail: string,

  properties: Record<string, string | boolean | RouteRecordName | undefined | null>,
  merchantUuid: string | null | undefined,
  streamUuid: string | null | undefined,
}) => {
  // Getting UTM parameters on storage
  const { getUTMParameters } = UtmParamsStore();
  const utmParams = getUTMParameters();

  const props = {
    [config.EventKeys.TriggeredFrom]: 'Web',
    [config.EventKeys.Action]: params.eventAction,
    [config.EventKeys.ActionDetail]: params.eventActionDetail,
    [config.EventKeys.MerchantUUID]: params.merchantUuid,
    [config.EventKeys.StreamUUID]: params.streamUuid,
    ...params.properties,
  };

  if (props[config.EventKeys.MerchantUUID] === null
    || props[config.EventKeys.MerchantUUID] === undefined) {
    delete (props[config.EventKeys.MerchantUUID]);
  }

  if (props[config.EventKeys.StreamUUID] === null
    || props[config.EventKeys.StreamUUID] === undefined) {
    delete (props[config.EventKeys.StreamUUID]);
  }

  // Add UTM parameters to all events
  if (utmParams) {
    props[config.EventKeys.UtmSource] = utmParams.utmSource;
    props[config.EventKeys.UtmMedium] = utmParams.utmMedium;
    props[config.EventKeys.UtmCampaign] = utmParams.utmCampaign;
    props[config.EventKeys.UtmTerm] = utmParams.utmTerm;
    props[config.EventKeys.UtmContent] = utmParams.utmContent;
  }

  try {
    mixpanel.track(params.eventName, props);
  } catch (error) {
    console.error('Error tracking event', error);
  }
};

export default {
  mixpanelTrack,
  peopleIncrement,
  userIdentify,
};
