import { RouteRecordRaw } from 'vue-router';
import PricingLayout from '@/components/tailwind/layout/billing/PricingLayout.vue';
import NoHeaderLayout from '@/components/layout/NoHeaderLayout.vue';

const billingRoutes: Array<RouteRecordRaw> = [
  {
    path: '/pricing',
    name: 'PricingPage',
    meta: {
      label: 'PricingPage',
      layout: PricingLayout,
      accessWithSession: true,
      useTailwind: true,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/tailwind/billing/PricingView.vue'),
  },
  {
    path: '/billing/payment/process',
    name: 'PaymentProcessPage',
    meta: {
      label: 'PaymentProcessPage',
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/billing/PaymentProcessView.vue'),
  },
];

export default billingRoutes;
