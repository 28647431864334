<!-- eslint-disable max-len -->
<template>
  <div class="mx-auto min-h-full">
    <SimpleHeader
      :on-close="handleClose"
      :pages="[
        { name: t('sidemenu.navigation.shared-report.title'), href: router.resolve({ name: 'PluginLivesSharedWithMePage' }).href, current: true },
      ]"
    />
    <RouterView />
  </div>
  <SimpleFooter
    :horizontal="true"
  />
</template>

<script setup lang="ts">
import {
  ref,
  onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import SimpleHeader from '@/components/tailwind/header/SimpleHeader.vue';
import { useI18n } from 'vue-i18n';
import SimpleFooter from '@/components/tailwind/footer/SimpleFooter.vue';

const router = useRouter();
const { t } = useI18n();

const handleClose = () => {
  router.push({
    name: 'DashboardPage',
  });
};

/*
  Data
*/
const dataLoaded = ref(false);

/*
  Methods
*/
// const deletePlugin = async (plugin: Plugin) => {
//   await pluginsDoDelete(plugin.id)
//     .then(() => {
//       notify({
//         type: 'success',
//         title: 'Sucesso',
//         text: 'Plugin removido com sucesso',
//       });
//       plugins.value = plugins.value.filter((p) => p.id !== plugin.id);
//     }).catch((error) => {
//       console.error('error', error);
//       notify({
//         type: 'error',
//         title: 'Erro',
//         text: 'Não foi possível remover o plugin',
//       });
//     });
// };

/*
  Hooks
*/
onMounted(() => {
  setTimeout(() => {
    dataLoaded.value = true;
  }, 500);
});
</script>
