/* eslint-disable @typescript-eslint/no-explicit-any */
import Image from '@/models/yampi/product/image';
import Sku from '@/models/yampi/product/sku';
import Variations from '@/models/yampi/product/variations';
import Brand from '@/models/yampi/product/brand';
import Extras from '@/models/yampi/product/extras';
import SEO from '@/models/yampi/product/seo';
import SearchAttributes from '@/models/yampi/product/searchAttributes';

export default class Product {
  relevance = null as number | null;

  id = null as number | null;

  uuid = null as string | null;

  merchantId = null as number | null;

  sellerId = null;

  affiliationId = null;

  brand = {} as Brand | null;

  active = null as boolean | null;

  giftValue = null;

  searchable = null as boolean | null;

  simple = null as boolean | null;

  erpId = null;

  ncm = null;

  hasVariations = false;

  isDigital = false;

  warranty = null;

  customShipping = false;

  shippingPrice = '0.00';

  name = null as string | null;

  slug = null as string | null;

  sku = null as string | null;

  rating = null as number | null;

  priority = null as number | null;

  url = null as string | null;

  redirectUrlCard = null;

  redirectUrlBillet = null;

  previewUrl = null as string | null;

  images = [] as Image[];

  skus = [] as Sku[];

  purchaseUrl = null as string | null;

  dates = {
    createdAt: {
      date: null as string | null,
      timezoneType: null as number | null,
      timezone: null as string | null,
    },
    createdAtFormated: null as string | null,
    updatedAt: {
      date: null as string | null,
      timezoneType: null as number | null,
      timezone: null as string | null,
    },
  };

  categories = [] as string[];

  texts = {
    description: null as string | null,
    measures: null as string | null,
    specifications: null as string | null, // USE TO STORE IMAGES URL ONLY
  }

  variations = {} as Variations;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    Object.assign(this, data);
  }

  hasAvailableQuantity(): boolean {
    let hasAvailableQuantity = false;
    this.skus.forEach((sku) => {
      if (!sku.blockedSale && !hasAvailableQuantity) {
        if (!sku.quantityManaged) {
          hasAvailableQuantity = true;
          return;
        }
        if (sku.totalInStock && sku.totalInStock > 0) {
          hasAvailableQuantity = true;
        }
      }
    });
    return hasAvailableQuantity;
  }

  availableQuantity(): number | null {
    let maxQty = null as number | null;
    let handleStoks = false;
    this.skus.forEach((sku) => {
      if (sku.quantityManaged) {
        handleStoks = true;
        maxQty = 0;
        if (sku.totalInStock && (maxQty === null || maxQty < sku.totalInStock)) {
          maxQty = sku.totalInStock;
        }
      }
    });
    if (!handleStoks) {
      return -1;
    }
    return maxQty;
  }

  formattedAvailableQuantity(): string {
    if (this.availableQuantity() === -1) {
      return 'estoque: ilimitado';
    }
    if (this.availableQuantity() === 0) {
      return '<strong class="has-text-danger">estoque esgotado</strong>';
    }
    return `estoque: ${this.availableQuantity()}`;
  }

  getFormattedPrice(): string {
    if (this.skus.length === 0) {
      console.error('Product has no skus');
      return '';
    }

    if (this.skus.length === 1) {
      return this.skus[0].getFormattedPrice();
    }

    let freeOptions = false;
    let freeOptionsMessage = '';
    let variantsMessage = '';
    let minPrice = null || 0;
    let minPriceDiscount = null || 0;

    this.skus.forEach((sku) => {
      if (!minPrice || !minPriceDiscount) {
        minPrice = sku.priceSale;
        minPriceDiscount = sku.priceDiscount;
      }

      if (sku.priceSale && sku.priceSale < minPrice) {
        minPrice = sku.priceSale;
      }

      if (sku.priceDiscount && sku.priceDiscount < minPriceDiscount) {
        minPriceDiscount = sku.priceDiscount;
      }

      if (
        (!sku.priceSale || sku.priceSale === 0.0)
        && (!sku.priceDiscount || sku.priceDiscount === 0.0)
        && !sku.blockedSale
      ) {
        freeOptions = true;
      }
    });

    if (freeOptions) {
      freeOptionsMessage = '<br>e com opções grátis';
    }

    if (this.skus.length > 1) {
      variantsMessage = 'A partir de ';
    }

    if (minPriceDiscount > 0) {
      return `${variantsMessage}${minPriceDiscount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}${freeOptionsMessage}`;
    }

    if (minPrice === 0.0) {
      return '<strong>Grátis</strong>';
    }

    return `A partir de ${minPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}${freeOptionsMessage}`;
  }

  getAllImagesUrl(modifiers: string): (string | null)[] {
    let mod = modifiers;
    if (mod === undefined) {
      mod = '';
    }
    if (this.texts.measures) {
      return Product.getImagesUrl(this.texts.measures, modifiers);
    }
    // For legacy products
    if (this.images.length === 0) {
      return [];
    }
    return this.images.map((image) => {
      switch (modifiers) {
        case 'small':
          return image.small.url;
        case 'thumb':
          return image.thumb.url;
        case 'medium':
          return image.medium.url;
        case 'large':
          return image.large.url;
        default:
          return image.medium.url;
      }
    });
  }

  getImageUrl(modifiers?: string): string | null {
    let mod = modifiers;
    if (mod === undefined) {
      mod = '';
    }
    if (this.texts?.measures) {
      const cdnUrl = Product.getImagesUrlFromHtml(this.texts?.measures);
      return Product.getImagesUrl(cdnUrl, mod)[0];
    }
    // For legacy products
    if (this.images.length === 0) {
      return null;
    }
    switch (mod) {
      case 'small':
        return this.images[0].small.url;
      case 'thumb':
        return this.images[0].thumb.url;
      case 'medium':
        return this.images[0].medium.url;
      case 'large':
        return this.images[0].large.url;
      default:
        return this.images[0].medium.url;
    }
  }

  getName() {
    return this.name?.replace(/ ref\. \d+ ?/g, ' ');
  }

  static setName(name: string, brandId: number) {
    return `${name.trim()} ref. ${brandId}`;
  }

  static setHtmlText(text: string) {
    if (!text) {
      return '';
    }
    if (text.slice(0, 4) !== '<p>' && text.slice(-4) !== '</p>') {
      return `<p>${text.substring(0, 500)}</p>`;
    }
    return `${text}`;
  }

  static getImagesUrlFromHtml(text: string) {
    return text.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g, ' ');
  }

  static getImagesTotalFromHtml(text: string) {
    const [, count] = text.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g, ' ').split('~');
    if (!count) {
      return 0;
    }
    return parseInt(count.replace(/\D/g, ''), 10);
  }

  static getImagesUrl(cdnUrl: string, modifiers?: string, forceJpg = false) {
    let mod = modifiers;
    if (mod === undefined) {
      mod = '';
    }
    const count = Product.getImagesTotalFromHtml(cdnUrl);
    const cdn = Product.getImagesUrlFromHtml(cdnUrl);
    if (count <= 0) {
      return [];
    }
    const urls = [];
    for (let index = 0; index < count; index += 1) {
      if (forceJpg) {
        urls.push(`${cdn}nth/${index}/${mod}-/preview/-/format/jpg/?.jpg`);
      } else {
        urls.push(`${cdn}nth/${index}/${mod}-/preview/`);
      }
    }
    return urls;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): Product[] {
    if (rows.length === 0) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        if (camelCaseKey === 'id') {
          v.id = row[index];
          v.uuid = `${row[index]}`;
        } else if (camelCaseKey === 'variations' && row[index]?.data) {
          v[camelCaseKey] = Variations.fromModelApiResponse(row[index].data);
        } else if (camelCaseKey === 'images' && row[index]?.data) {
          v[camelCaseKey] = Image.fromApiResponse(row[index].data);
        } else if (camelCaseKey === 'firstImage' && row[index]?.data) {
          v[camelCaseKey] = new Image(row[index].data);
        } else if (camelCaseKey === 'brand' && row[index]?.data) {
          v[camelCaseKey] = Brand.fromModelApiResponse(row[index].data);
        } else if (camelCaseKey === 'categories' && row[index]?.data) {
          v[camelCaseKey] = row[index].data.map((category: any) => category.name);
        } else if (camelCaseKey === 'dates' && row[index]?.data) {
          v[camelCaseKey] = row[index].data;
        } else if (camelCaseKey === 'extras' && row[index]?.data) {
          v[camelCaseKey] = Extras.fromModelApiResponse(row[index].data);
        } else if (camelCaseKey === 'seo' && row[index]?.data) {
          v[camelCaseKey] = SEO.fromModelApiResponse(row[index].data);
        } else if (camelCaseKey === 'texts' && row[index]?.data) {
          v[camelCaseKey] = row[index].data;
        } else if (camelCaseKey === 'skus' && row[index]?.data) {
          v[camelCaseKey] = Sku.fromModelApiResponse(row[index].data);
        } else if (camelCaseKey === 'searchAttributes' && row[index]?.data) {
          v[camelCaseKey] = SearchAttributes.fromModelApiResponse(row[index].data);
        } else {
          v[camelCaseKey] = row[index];
        }
      });
      return new Product(v);
    });
  }
}
