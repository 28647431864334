class Unlock {
  pluginId: string;

  pluginAccountName: string;

  mediaId: string;

  mediaCreatedAt: Date;

  unlockedBy: string;

  unlockedByName: string;

  unlockedAt: Date;

  constructor({
    pluginId,
    pluginAccountName,
    mediaId,
    mediaCreatedAt,
    unlockedBy,
    unlockedByName,
    unlockedAt,
  }: {
    pluginId: string;
    pluginAccountName: string;
    mediaId: string;
    mediaCreatedAt: Date;
    unlockedBy: string;
    unlockedByName: string;
    unlockedAt: Date;
  }) {
    this.pluginId = pluginId;
    this.pluginAccountName = pluginAccountName;
    this.mediaId = mediaId;
    this.mediaCreatedAt = new Date(mediaCreatedAt);
    this.unlockedBy = unlockedBy;
    this.unlockedByName = unlockedByName;
    this.unlockedAt = new Date(unlockedAt);
  }

  formattedUnlockedAt = (): string => this.unlockedAt.toLocaleString();
}

export default Unlock;
