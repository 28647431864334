class InstagramLiveMetadata {
  id: string;

  mediaId: string;

  billingCreditId: string;

  accountId: string;

  accountUsername: string;

  commentsCount: number;

  realCommentsCount: number;

  likeCount: number;

  mediaType: string;

  permalink: string;

  shortcode: string;

  createdAt: Date;

  updatedAt: Date;

  pluginId: string;

  pluginType: string;

  profilePictureBase64: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor({
    id,
    mediaId,
    billingCreditId,
    accountId,
    accountUsername,
    commentsCount,
    realCommentsCount,
    likeCount,
    mediaType,
    permalink,
    shortcode,
    createdAt,
    updatedAt,
    pluginId,
    pluginType,
    profilePictureBase64,
  }: {
    id: string;
    mediaId: string;
    billingCreditId: string;
    accountId: string;
    accountUsername: string;
    commentsCount: number;
    realCommentsCount: number;
    likeCount: number;
    mediaType: string;
    permalink: string;
    shortcode: string;
    createdAt: Date;
    updatedAt: Date;
    pluginId: string;
    pluginType: string;
    profilePictureBase64: string;
  }) {
    this.id = id;
    this.mediaId = mediaId;
    this.billingCreditId = billingCreditId;
    this.accountId = accountId;
    this.accountUsername = accountUsername;
    this.commentsCount = commentsCount;
    this.realCommentsCount = realCommentsCount;
    this.likeCount = likeCount;
    this.mediaType = mediaType;
    this.permalink = permalink;
    this.shortcode = shortcode;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.pluginId = pluginId;
    this.pluginType = pluginType;
    this.profilePictureBase64 = profilePictureBase64;
  }

  missingPayment(): boolean {
    return !this.billingCreditId;
  }

  getShortId(): string {
    return this.id.substring(0, 16);
  }

  getLiveFirstMessageDate(): string {
    return this.createdAt.toLocaleDateString('pt-BR', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }

  getLiveFirstMessageTime(): string {
    return this.createdAt.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  }

  formattedCommentsCount(): string {
    const userLang = navigator.language; // Detects browser language
    const formattedNumber = new Intl.NumberFormat(userLang).format(this.realCommentsCount);
    return formattedNumber;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): InstagramLiveMetadata[] {
    if (rows.length === 0) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        if (row[index] && (camelCaseKey === 'Id')) {
          v.id = row[index].toString();
        } else if (row[index] && (camelCaseKey === 'updatedAt' || camelCaseKey === 'createdAt')) {
          v[camelCaseKey] = new Date(row[index]);
        } else {
          v[camelCaseKey] = row[index];
        }
      });
      return new InstagramLiveMetadata(v);
    });
  }
}

export default InstagramLiveMetadata;
