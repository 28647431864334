<!-- eslint-disable max-len -->
<template>
  <div class="mx-auto min-h-full">
    <SimpleHeader
      :on-close="handleClose"
      :pages="[
        { name: t('sidemenu.navigation.integration.title'), href: router.resolve({ name: 'PluginsManagerPage' }).href, current: false },
        { name: `@${pluginName}`, href: router.resolve({
          name: 'PluginLivesPage',
          params: {
            pluginId: `${pluginId}`,
            pluginType: `${pluginType}`,
          },
          query: {
            tab: 'reports',
          },
        }).href, current: false },
        { name: t('sidemenu.navigation.report-details.title'), href: '#', current: true },
      ]"
    />
    <RouterView
      :plugin="plugin"
      :metadata="metadata"
      :instagram-live-status="instagramLiveStatus"
      :initial-page-messages="initialPageMessages"
      :initial-limit-messages="initialLimitMessages"
      :initial-page-questions="initialPageQuestions"
      :initial-limit-questions="initialLimitQuestions"
      :initial-page-users="initialPageUsers"
      :initial-limit-users="initialLimitUsers"
    />
  </div>
  <SimpleFooter
    :horizontal="true"
  />
</template>

<script setup lang="ts">
import {
  ref, onBeforeMount, computed,
} from 'vue';
import { useRouter } from 'vue-router';
import usePluginStore from '@/store/plugin/plugin';
import InstagramLiveStatus from '@/models/plugin/instagramLiveStatus';
import InstagramLiveMetadata from '@/models/plugin/instagramLiveMetadata';
import Pagination from '@/models/pagination';
import SimpleHeader from '@/components/tailwind/header/SimpleHeader.vue';
import SimpleFooter from '@/components/tailwind/footer/SimpleFooter.vue';
import Plugin from '@/models/plugin/plugin';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();

const {
  pluginsDoGetMy,
  pluginAccountLiveStatus,
  pluginGetLiveMetadatas,
} = usePluginStore();

interface InstagramLiveMetadataResponse {
  data: InstagramLiveMetadata[];
  pagination: Pagination;
}

/*
  Data
*/
const isLoading = ref(true);
const initialLimitMessages = ref(500);
const initialPageMessages = ref(1);
const initialLimitQuestions = ref(500);
const initialPageQuestions = ref(1);
const initialLimitUsers = ref(500);
const initialPageUsers = ref(1);
const instagramLiveStatus = ref({} as InstagramLiveStatus);
const metadata = ref({} as InstagramLiveMetadata);
const metadataPagination = ref({} as Pagination);
const moderator = ref(router.currentRoute.value.query.ga);
const plugin = ref({} as Plugin);

/*
  Computed
*/
const pluginId = computed(() => router.currentRoute.value.params.pluginId);
const pluginType = computed(() => router.currentRoute.value.params.pluginType);
const pluginName = computed(() => plugin.value.name);
const accountId = computed(() => router.currentRoute.value.params.accountId);
const mediaId = computed(() => router.currentRoute.value.params.mediaId);

/*
  Methods
*/
const handleClose = () => {
  router.push({
    name: 'PluginLivesPage',
    params: {
      pluginId: `${pluginId.value}`,
      pluginType: `${pluginType.value}`,
    },
    query: {
      tab: 'reports',
    },
  });
};
const redirectDashboard = ((error: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar a página. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));
const loadPlugin = () => {
  pluginsDoGetMy(`${pluginId.value}`, `${mediaId.value}`)
    .then((response) => {
      if (response !== undefined) {
        const res = response.data;
        plugin.value = new Plugin(res[0]);
      }
    }).catch((error) => {
      console.error('error', error);
      redirectDashboard(error.toString());
    });
};

const loadAccountLiveStatus = async () => {
  pluginAccountLiveStatus({
    pluginId: `${pluginId.value}`,
    pluginType: `${pluginType.value}`,
  }).then((res) => {
    if (!res || !Array.isArray(res.data) || res.data.length === 0) {
      instagramLiveStatus.value = {} as InstagramLiveStatus;
      return;
    }
    const { data } = res;
    [instagramLiveStatus.value] = data;
  }).catch((error) => {
    console.error('Error', error);
    instagramLiveStatus.value = {} as InstagramLiveStatus;
  });
};

const loadLiveMetadata = async () => {
  await pluginGetLiveMetadatas({
    pluginId: `${pluginId.value}`,
    pluginType: `${pluginType.value}`,
    page: 1,
    limit: 1,
    orderBy: 'createdAt',
    order: -1,
    mediaId: `${mediaId.value}`,
    moderator: `${moderator.value}` === '1',
  }).then((response: InstagramLiveMetadataResponse) => {
    isLoading.value = false;
    if (response && response.data.length === 0) {
      alert('Dados do relatório não encontrados. Recarregue a página e tente novamente.');
      router.push({
        name: 'PluginsManagerPage',
      });
    }
    if (!response) {
      metadata.value = {} as InstagramLiveMetadata;
      metadataPagination.value = {} as Pagination;
      return;
    }
    [metadata.value] = response.data;
    metadataPagination.value = response.pagination;
  }).catch((error) => {
    console.error('Error', error);
    isLoading.value = false;
  });
};

/*
  Hooks
*/
onBeforeMount(() => {
  loadPlugin();
  loadLiveMetadata();
  loadAccountLiveStatus();
});
</script>

<style scoped lang="scss">
</style>
