<!-- eslint-disable max-len -->
<template>
  <div v-if="!isLoading">
    <nav
      v-if="$props.pages.length > 0"
      class="flex border-gray-700"
      aria-label="Breadcrumb"
    >
      <ol class="mx-auto flex w-full max-w-screen-xl space-x-4 px-4">
        <!-- Home Link -->
        <li class="flex">
          <div class="flex items-center">
            <router-link
              :to="{ name: 'DashboardPage' }"
              class="text-gray-700 hover:text-gray-900 cursor-pointer"
            >
              <HomeIcon
                class="size-5 shrink-0"
                aria-hidden="true"
              />
              <span class="sr-only">{{ t('sidemenu.navigation.dashboard.title') }}</span>
            </router-link>
          </div>
        </li>

        <!-- Dynamic Breadcrumb for Desktop -->
        <template
          v-for="(page, index) in $props.pages"
          :key="page.name"
        >
          <li
            :class="['flex', {
              'hidden sm:flex': (index !== $props.pages.length - 1) }]"
          >
            <div class="flex items-center">
              <svg
                class="h-full w-6 shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <router-link
                v-if="!page.current"
                :to="page.href"
                class="flex ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
                :class="{ 'font-bold': page.current }"
                :aria-current="page.current ? 'page' : undefined"
              >
                {{ page.name }}
              </router-link>
              <span
                v-else
                class="flex ml-4 text-sm font-medium text-gray-900"
                aria-current="page"
              >
                {{ page.name }}
              </span>
            </div>
          </li>
        </template>
      </ol>
    </nav>
  </div>
</template>

<script lang="ts">
import {
  ref,
  computed,
  onMounted,
  defineComponent,
} from 'vue';
import { HomeIcon } from '@heroicons/vue/24/outline';
import { useI18n } from 'vue-i18n';
import Page from '@/types/page';

export default defineComponent({
  name: 'BreadCrumb',
  components: {
    HomeIcon,
  },
  props: {
    pages: { type: Array as () => Page[], required: true },
    justifyClass: { type: String, default: '' },
  },
  setup(props) {
    const { t } = useI18n();
    const isLoading = ref(true);

    onMounted(() => {
      setTimeout(() => {
        isLoading.value = false;
      }, 100);
    });

    return {
      isLoading,
      t,
    };
  },
});
</script>
