import { RouteRecordRaw } from 'vue-router';
import PluginsLayout from '@/components/tailwind/layout/plugins/ManagerLayout.vue';
import PluginLivesSharedWithMeLayout from '@/components/tailwind/layout/plugins/PluginLivesSharedWithMeLayout.vue';
import PluginReportLayout from '@/components/layout/plugins/PluginReportLayout.vue';
import PluginLivesLayout from '@/components/tailwind/layout/plugins/PluginLivesLayout.vue';
import PluginLiveReportLayout from '@/components/tailwind/layout/plugins/PluginLiveReportLayout.vue';
import PluginLiveReportSharedWithMeLayout from '@/components/tailwind/layout/plugins/PluginLiveReportSharedWithMeLayout.vue';
import NoHeaderLayout from '@/components/layout/NoHeaderLayout.vue';

const pluginsRoutes: Array<RouteRecordRaw> = [
  {
    path: '/plugins',
    name: 'PluginsManagerPage',
    meta: {
      label: 'PluginsPage',
      layout: PluginsLayout,
      accessWithSession: true,
      useTailwind: true,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/tailwind/plugins/ManagerView.vue'),
  },
  {
    path: '/plugins/callback/shopify',
    name: 'PluginsCallbackShopifyPage',
    meta: {
      label: 'PluginsPage',
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/plugins/ShopifyCallback.vue'),
  },
  {
    path: '/plugins/callback/instagram',
    name: 'PluginsCallbackInstagramPage',
    meta: {
      label: 'PluginsPage',
      layout: NoHeaderLayout,
      accessWithSession: undefined,
      useTailwind: true,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/tailwind/plugins/InstagramCallback.vue'),
  },
  {
    path: '/plugins/:account/reports',
    name: 'PluginsReportPage',
    meta: {
      label: 'ReportsPage',
      layout: PluginReportLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/plugins/PluginReportPage.vue'),
  },
  {
    path: '/plugins/v2/lives/share-with-me',
    name: 'PluginLivesSharedWithMePage',
    meta: {
      label: 'ReportsPage',
      layout: PluginLivesSharedWithMeLayout,
      accessWithSession: true,
      useTailwind: true,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/tailwind/plugins/PluginLivesSharedWithmeView.vue'),
  },
  {
    path: '/plugins/v2/:pluginId/:pluginType/lives',
    name: 'PluginLivesPage',
    meta: {
      label: 'ReportsPage',
      layout: PluginLivesLayout,
      accessWithSession: true,
      useTailwind: true,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/tailwind/plugins/PluginLivesView.vue'),
  },
  {
    path: '/plugins/v2/:pluginId/:pluginType/:accountId/medias/:mediaId/report',
    name: 'PluginLiveReportPage',
    meta: {
      label: 'ReportsPage',
      layout: PluginLiveReportLayout,
      accessWithSession: true,
      useTailwind: true,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/tailwind/plugins/PluginLiveReportView.vue'),
  },
  {
    path: '/plugins/v2/:pluginId/:pluginType/:accountId/medias/:mediaId/report/share-with-me',
    name: 'PluginLiveReportSharedWithMePage',
    meta: {
      label: 'ReportsPage',
      layout: PluginLiveReportSharedWithMeLayout,
      accessWithSession: true,
      useTailwind: true,
    },
    component: () => import(/* webpackChunkName: "plugins" */ '@/views/tailwind/plugins/PluginLiveReportSharedWithMeView.vue'),
  },
];

export default pluginsRoutes;
