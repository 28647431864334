<template>
  <label
    for="locale"
    class="block text-sm/6 text-gray-600"
    style="padding: 0;"
  >
    <span
      v-if="!props.hideLabel"
      class="hidden sm:inline mr-4"
    >{{ t('home.hero.language') }}</span>
    <select
      v-model="locale"
      @change="changeLanguage(locale)"
    >
      <option
        value="en-US"
        class="text-lg"
      >
        🇺🇸 EN
      </option>
      <option
        value="pt-BR"
        class="text-lg"
      >
        🇧🇷 PT-BR
      </option>
    </select>
  </label>
</template>

<script setup lang="ts">
import {
  ref,
  defineProps,
  watchEffect,
} from 'vue';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

/*
  Props
*/
const props = defineProps({
  hideLabel: {
    type: Boolean,
    default: false,
  },
});

/*
  Data
*/
const selectedLanguage = ref(locale.value);

/*
  Methods
*/
const changeLanguage = ($language: string) => {
  locale.value = $language;
  localStorage.setItem('user-locale', $language); // Save user preference
};

/*
  Hooks
*/
watchEffect(() => {
  const savedLocale = localStorage.getItem('user-locale') || 'pt-BR';
  locale.value = savedLocale;
  selectedLanguage.value = savedLocale;
});
</script>
