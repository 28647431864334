import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-4" }
const _hoisted_2 = { class: "main has-navbar-fixed-top" }

import {
  ref,
  computed,
  onBeforeMount,
  onUnmounted,
} from 'vue';
import { useRouter } from 'vue-router';
import useStreamStore from '@/store/stream';
import PageHeader from '@/components/header/PageHeader.vue';
import YampiShoppingCartComponent from '@/components/stream/YampiShoppingCartComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'StreamLandingPageLayout',
  setup(__props) {

const router = useRouter();
const { streamDoGet, resetStore } = useStreamStore();

/*
  Computed
*/
const streamUri = computed(() => router.currentRoute.value.params.uri);

/*
  Data
*/
const isDataLoaded = ref(false);
const stream = ref({});

/*
  Methods
*/
const load = async () => {
  await streamDoGet(streamUri.value).then((result) => {
    if (result === undefined) {
      router.push({ name: 'NotFoundPage' });
    } else {
      stream.value = result;
    }
    if (!stream.value.landingPageActive) {
      router.push({ name: 'HomePage' });
    }
    isDataLoaded.value = true;
  }).catch((error) => {
    console.error(error);
  });
};

/*
  Hooks
*/
onBeforeMount(() => {
  load();
});
onUnmounted(() => {
  resetStore();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, {
      "is-loading": false,
      title: "Lojas e Marcas",
      to: "HomePage"
    }, _createSlots({ _: 2 }, [
      (stream.value.showProductsOnLanding)
        ? {
            name: "content",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (stream.value.productGateway === 'livelink-yampi')
                  ? (_openBlock(), _createBlock(YampiShoppingCartComponent, {
                      key: 0,
                      "stream-uuid": stream.value.uuid,
                      "merchant-uuid": stream.value.merchantUuid,
                      "button-color": "is-black"
                    }, null, 8, ["stream-uuid", "merchant-uuid"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1024),
    _createElementVNode("main", _hoisted_2, [
      (isDataLoaded.value)
        ? (_openBlock(), _createBlock(_component_RouterView, {
            key: 0,
            "stream-uri": streamUri.value,
            stream: stream.value
          }, null, 8, ["stream-uri", "stream"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})