<template>
  <main class="main">
    <div class="column is-full p-0">
      <RouterView
        v-if="myStream && rtt"
        :stream="myStream"
        :rtt="rtt"
        :user-name="getUserName()"
        @update:my-stream="load"
        @reload:stream="reloadStream"
      />
    </div>
  </main>
  <LoadingComponent
    v-if="isLoading"
    :full-screen="true"
  />
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import { useCookies } from 'vue3-cookies';
import useStreamStore from '@/store/stream';
import useAuthStore from '@/store/auth';
import Stream from '@/models/stream';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';

const router = useRouter();
const {
  streamDoGet,
  streamsDoGetRealtimeTokens,
} = useStreamStore();
const {
  session,
} = useAuthStore();

/*
  Data
*/
const userRole = ref(2); // 1 = host, 2 = audience
const isLoading = ref(true);
const myStream = ref({} as Stream);
const rtt = ref(null);

/*
  Computed
*/
const streamUri = computed(() => router.currentRoute.value.params.uri);

/*
  Methods
*/
const getUserName = (() => {
  if (session.name) {
    return session.name;
  }
  const { cookies } = useCookies();
  return cookies.get('_ll_uname');
});
const redirectDashboard = ((error?: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar sua live. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));
const reloadStream = async () => {
  const res = await streamDoGet(streamUri.value);

  if (res === undefined) {
    redirectDashboard('A live que você tentou acessar não está disponível ou não existe.');
    return;
  }

  myStream.value = res;
  console.error('myStream.value', myStream.value);

  if (res.status === 'finished') {
    await router.push({
      name: 'StreamLandingFinishedPage',
      params: {
        uri: streamUri.value,
      },
    });
    return;
  }

  if (res.status === 'pending') {
    await router.push({
      name: 'StreamLandingCountdownPage',
      params: {
        uri: streamUri.value,
      },
    });
    return;
  }

  if (res.status === 'live') {
    isLoading.value = false;
  }
};
const load = async () => {
  isLoading.value = true;
  await reloadStream();
  rtt.value = await streamsDoGetRealtimeTokens(
    myStream.value.uuid,
    userRole.value,
  );
  isLoading.value = false;
};

/*
  Hooks
*/
onBeforeMount(() => {
  load();
});
</script>

<style lang="scss" scoped>
</style>
