import { createI18n } from 'vue-i18n';
import ptBr from '@/locales/pt-BR.json';
import en from '@/locales/en.json';

const getBrowserLocale = () => {
  const navigatorLocale = navigator.language.split('-')[0];
  if (navigatorLocale === 'pt' || navigatorLocale === 'pt-BR') {
    return 'pt-BR';
  } if (navigatorLocale === 'en' || navigatorLocale === 'en-US') {
    return 'en-US';
  }
  return 'en-US';
};

// Get the user's locale from localStorage or fallback to browser language
const savedLocale = localStorage.getItem('user-locale');
const browserLocale = getBrowserLocale();
const defaultLocale = savedLocale || browserLocale;

// Save locale if not in localStorage
if (!savedLocale) {
  localStorage.setItem('user-locale', defaultLocale);
}

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: 'en-US',
  messages: {
    en,
    'pt-BR': ptBr, // Fix this key to match the locale format
  },
});

export default i18n;
