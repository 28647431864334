<!-- eslint-disable max-len -->
<template>
  <table class="min-w-full divide-y divide-gray-300 pb-8">
    <thead>
      <tr>
        <th class="hidden md:table-cell py-4 pr-3 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">
          {{ t('plugin.lives.reports.table.credit-status') }}
        </th>
        <th class="px-3 py-4 text-left text-sm font-semibold text-gray-900 table-cell">
          <span class="whitespace-nowrap">
            {{ t('plugin.lives.reports.table.date') }}
          </span>
          <p class="sm:hidden whitespace-nowrap">
            / {{ t('plugin.lives.reports.table.first-message-time') }}
          </p>
        </th>
        <th class="hidden px-3 py-4 text-left text-sm font-semibold text-gray-900 sm:table-cell whitespace-nowrap">
          {{ t('plugin.lives.reports.table.first-message-time') }}
        </th>
        <th class="hidden px-3 py-4 text-left text-sm font-semibold text-gray-900 sm:table-cell">
          {{ t('plugin.lives.reports.table.comments') }}
        </th>
        <th class="relative py-4 pr-4 pl-3 sm:pr-0 whitespace-nowrap">
          <span class="sr-only">Actions</span>
        </th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white">
      <tr
        v-for="(metadata, index) in props.metadatas"
        :key="index"
      >
        <td class="hidden md:table-cell px-3 py-4 text-sm text-gray-500">
          <img
            v-if="!metadata.missingPayment()"
            :src="coin"
            alt="paid"
            class="w-8 h-8"
          >
          <img
            v-else
            :src="lockClosed"
            alt="unpaid"
            class="w-8 h-8"
          >
        </td>
        <td class="px-3 py-4 text-sm text-gray-500 table-cell">
          {{ metadata?.getLiveFirstMessageDate() }}
          <p class="sm:hidden text-gray-500">
            / {{ metadata?.getLiveFirstMessageTime() }}
          </p>
        </td>
        <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
          {{ metadata?.getLiveFirstMessageTime() }}
        </td>
        <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
          {{ metadata?.formattedCommentsCount() }}
        </td>
        <td class="w-full py-4 pr-4 pl-3 text-right text-sm font-medium whitespace-nowrap">
          <a
            href="#"
            class="text-teal-600 hover:text-teal-500"
            @click.prevent="openPluginLiveReportPage(metadata.accountId, metadata.mediaId)"
          >
            {{ t('plugin.lives.reports.table.view-report') }}
          </a>
        </td>
      </tr>
    </tbody>
    <tfoot v-if="props.metadatas.length >=5">
      <tr>
        <td
          colspan="5"
          class="px-3 py-4 text-sm text-gray-500 gap-x-2"
        >
          <p>
            {{ t('plugin.lives.reports.table.results', {count: props.pageLimit}) }}
          </p>
          <a
            href="#"
            class="text-teal-600 hover:text-teal-500 m-auto"
            @click.prevent="openPluginLivesPage"
          >
            {{ t('plugin.lives.reports.table.footer') }} <span aria-hidden="true">→</span>
          </a>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Plugin from '@/models/plugin/plugin';
import InstagramLiveMetadata from '@/models/plugin/instagramLiveMetadata';
import lockClosed from '@/assets/images/lock-closed.png';
import coin from '@/assets/images/coin.png';

const { t } = useI18n();
const router = useRouter();

/*
  Props
*/
const props = defineProps({
  plugin: {
    type: () => ({} as Plugin),
    required: true,
  },
  metadatas: {
    type: () => [] as InstagramLiveMetadata[],
    required: true,
  },
  pageLimit: {
    type: Number,
    required: true,
  },
});

/*
  Methods
*/
const openPluginLiveReportPage = async (
  accountId: string,
  mediaId: string,
) => {
  await router.push({
    name: 'PluginLiveReportPage',
    params: {
      pluginId: props.plugin.id,
      pluginType: props.plugin.type,
      accountId,
      mediaId,
    },
    query: {
      pluginName: props.plugin.name,
    },
  });
};
const openPluginLivesPage = async () => {
  await router.push({
    name: 'PluginLivesPage',
    params: {
      pluginId: props.plugin.id,
      pluginType: props.plugin.type,
    },
  });
};

</script>
