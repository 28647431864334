import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "bg-white shadow-xs rounded-lg p-4 mb-4" }

import { onBeforeMount, ref } from 'vue';
import Plugin from '@/models/plugin/plugin';
import InstagramLiveMetadata from '@/models/plugin/instagramLiveMetadata';
import usePluginStore from '@/store/plugin/plugin';
import ListPluginReportsTable from '@/components/tailwind/plugin/ListPluginReportsTable.vue';
import PluginHeader from '@/components/tailwind/plugin/PluginHeader.vue';

const pageLimit = 5;

export default /*@__PURE__*/_defineComponent({
  __name: 'ListPluginReportsDashboard',
  props: {
  plugins: {
    type: () => [] as Plugin[],
    required: true,
  },
},
  setup(__props) {

const {
  pluginGetLiveMetadatas,
} = usePluginStore();

/*
  Props
*/
const props = __props;

/*
  Data
*/
const metadataHash = ref({} as Record<string, {
  plugin: Plugin, metadatas: InstagramLiveMetadata[]
}>);

/*
  Methods
*/
const loadMetadatas = (plugin: Plugin) => {
  pluginGetLiveMetadatas({
    pluginId: `${plugin.id}`,
    pluginType: `${plugin.type}`,
    page: 1,
    limit: pageLimit,
    orderBy: 'createdAt',
    order: -1,
  }).then((response) => {
    if (response) {
      metadataHash.value[plugin.name] = {
        plugin,
        metadatas: response.data,
      };
    }
  });
};

/*
  Hooks
*/
onBeforeMount(() => {
  props.plugins.forEach((plugin: Plugin) => {
    loadMetadatas(plugin);
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(metadataHash.value, (content) => {
        return (_openBlock(), _createElementBlock("li", {
          key: content.plugin.name
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(PluginHeader, {
              plugin: content.plugin
            }, null, 8, ["plugin"]),
            _createVNode(ListPluginReportsTable, {
              plugin: content.plugin,
              metadatas: content.metadatas,
              "page-limit": pageLimit
            }, null, 8, ["plugin", "metadatas"])
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

})