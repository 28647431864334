import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createVNode as _createVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/logo2.png'


const _hoisted_1 = {
  key: 0,
  class: "relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1"
}
const _hoisted_2 = { class: "flex flex-wrap items-center gap-x-4 gap-y-2" }
const _hoisted_3 = { class: "text-sm/6 text-gray-900" }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { class: "flex flex-1 justify-end" }
const _hoisted_6 = { class: "mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" }
const _hoisted_7 = { class: "flex h-16 justify-between" }
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = { class: "mr-2 -ml-2 flex items-center md:hidden" }
const _hoisted_10 = { class: "flex items-center gap-x-4" }
const _hoisted_11 = { class: "border-t border-gray-200 pt-4 pb-3" }
const _hoisted_12 = { class: "mt-3 space-y-1" }

import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import {
  Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';
import {
  Bars3Icon, XMarkIcon, ArrowRightEndOnRectangleIcon
  ,
} from '@heroicons/vue/24/outline';
import { PlusIcon } from '@heroicons/vue/20/solid';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeHeader',
  setup(__props) {

const { t } = useI18n();
const router = useRouter();

/*
  Data
*/
const showTopBanner = ref(true);

/*
  Computed
*/
const linkTermsAndConditionsPageShoppers = computed(() => router.resolve({ name: 'TermsAndConditionsPageShoppers' }).href);
const linkTermsAndConditionsPage = computed(() => router.resolve({ name: 'TermsAndConditionsPage' }).href);
const linkPrivacyPage = computed(() => router.resolve({ name: 'PrivacyPage' }).href);
const signupLink = computed(() => router.resolve({ name: 'SessionSignupPage' }).href);

/*
  Methods
*/
const tempDisableTopBanner = () => {
  showTopBanner.value = false;
};
const navigateWhatsapp = (message: string) => {
  window.open(`https://wa.me/+5511994991786?text=${message}`, '_blank');
};
const navigateSignup = () => {
  router.push({ name: 'SessionSignupPage' });
};
const navigateLogin = () => {
  router.push({ name: 'SessionLoginPage' });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Disclosure), {
    as: "nav",
    class: "left-0 w-full z-50 shadow-xs"
  }, {
    default: _withCtx(({ open }) => [
      _createVNode(_Transition, {
        "enter-active-class": "transition-opacity duration-500",
        "enter-from-class": "opacity-0",
        "enter-to-class": "opacity-100",
        "leave-active-class": "transition-opacity duration-500",
        "leave-from-class": "opacity-100",
        "leave-to-class": "opacity-0"
      }, {
        default: _withCtx(() => [
          (showTopBanner.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _cache[4] || (_cache[4] = _createElementVNode("div", {
                  class: "absolute top-1/2 left-[max(-7rem,calc(50%-52rem))] -z-10 -translate-y-1/2 transform-gpu blur-2xl",
                  "aria-hidden": "true"
                }, [
                  _createElementVNode("div", {
                    class: "aspect-577/310 w-[36.0625rem] bg-linear-to-r from-[#ff80b5] to-teal-500 opacity-30",
                    style: {"clip-path":"polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"}
                  })
                ], -1)),
                _cache[5] || (_cache[5] = _createElementVNode("div", {
                  class: "absolute top-1/2 left-[max(45rem,calc(50%+8rem))] -z-10 -translate-y-1/2 transform-gpu blur-2xl",
                  "aria-hidden": "true"
                }, [
                  _createElementVNode("div", {
                    class: "aspect-577/310 w-[36.0625rem] bg-linear-to-r from-[#ff80b5] to-teal-500 opacity-30",
                    style: {"clip-path":"polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"}
                  })
                ], -1)),
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("p", _hoisted_3, [
                    _createElementVNode("strong", _hoisted_4, _toDisplayString(_unref(t)('header.banner.free_trial.title')), 1),
                    _cache[1] || (_cache[1] = _createElementVNode("svg", {
                      viewBox: "0 0 2 2",
                      class: "mx-2 inline size-0.5 fill-current",
                      "aria-hidden": "true"
                    }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_unref(t)('header.banner.free_trial.description')), 1)
                  ]),
                  _createElementVNode("a", {
                    href: "#",
                    class: "flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-xs hover:bg-gray-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (navigateWhatsapp('Olá, gostaria de testar a plataforma.')), ["prevent"]))
                  }, [
                    _createTextVNode(_toDisplayString(_unref(t)('header.banner.free_trial.cta')) + " ", 1),
                    _cache[2] || (_cache[2] = _createElementVNode("span", { "aria-hidden": "true" }, "→", -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "-m-3 p-3 focus-visible:outline-offset-[-4px]",
                    onClick: _withModifiers(tempDisableTopBanner, ["prevent"])
                  }, [
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "sr-only" }, "Dismiss", -1)),
                    _createVNode(_unref(XMarkIcon), {
                      class: "size-5 text-gray-900",
                      "aria-hidden": "true"
                    })
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_unref(DisclosureButton), { class: "relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500 focus:outline-hidden focus:ring-inset" }, {
                default: _withCtx(() => [
                  _cache[6] || (_cache[6] = _createElementVNode("span", { class: "absolute -inset-0.5" }, null, -1)),
                  _cache[7] || (_cache[7] = _createElementVNode("span", { class: "sr-only" }, "Open main menu", -1)),
                  (!open)
                    ? (_openBlock(), _createBlock(_unref(Bars3Icon), {
                        key: 0,
                        class: "block size-6",
                        "aria-hidden": "true"
                      }))
                    : (_openBlock(), _createBlock(_unref(XMarkIcon), {
                        key: 1,
                        class: "block size-6",
                        "aria-hidden": "true"
                      }))
                ]),
                _: 2
              }, 1024)
            ]),
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "flex shrink-0 items-center" }, [
              _createElementVNode("img", {
                class: "h-8 w-auto inline",
                src: _imports_0,
                alt: "Live Link"
              })
            ], -1)),
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "hidden md:ml-6 md:flex md:space-x-8" }, null, -1))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("button", {
              type: "button",
              class: "relative hidden md:inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold border-2 border-transparent hover:border-teal-500 hover:text-teal-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 transition-all duration-200",
              onClick: _withModifiers(navigateSignup, ["prevent"])
            }, [
              _createVNode(_unref(PlusIcon), {
                class: "-ml-0.5 size-5",
                "aria-hidden": "true"
              }),
              _createTextVNode(" " + _toDisplayString(_unref(t)('header.cta.signup')), 1)
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "relative inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold border-2 border-transparent hover:border-teal-500 hover:text-teal-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 transition-all duration-200",
              onClick: _withModifiers(navigateLogin, ["prevent"])
            }, [
              _createTextVNode(_toDisplayString(_unref(t)('header.cta.login')) + " ", 1),
              _createVNode(_unref(ArrowRightEndOnRectangleIcon), {
                class: "-ml-0.5 size-5",
                "aria-hidden": "true"
              })
            ]),
            _createVNode(_unref(Menu), {
              as: "div",
              class: "relative hidden"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_unref(MenuButton), { class: "relative flex rounded-full bg-white text-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createElementVNode("span", { class: "absolute -inset-1.5" }, null, -1),
                      _createElementVNode("span", { class: "sr-only" }, "Open user menu", -1),
                      _createElementVNode("img", {
                        class: "size-8 rounded-full",
                        src: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                        alt: ""
                      }, null, -1)
                    ])),
                    _: 1
                  })
                ]),
                _createVNode(_Transition, {
                  "enter-active-class": "transition ease-out duration-200",
                  "enter-from-class": "transform opacity-0 scale-95",
                  "enter-to-class": "transform opacity-100 scale-100",
                  "leave-active-class": "transition ease-in duration-75",
                  "leave-from-class": "transform opacity-100 scale-100",
                  "leave-to-class": "transform opacity-0 scale-95"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(MenuItems), { class: "absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-hidden" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(MenuItem), null, {
                          default: _withCtx(({ active }) => [
                            _createElementVNode("a", {
                              href: "#",
                              class: _normalizeClass([active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700'])
                            }, "Your Profile", 2)
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(MenuItem), null, {
                          default: _withCtx(({ active }) => [
                            _createElementVNode("a", {
                              href: "#",
                              class: _normalizeClass([active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700'])
                            }, "Settings", 2)
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(MenuItem), null, {
                          default: _withCtx(({ active }) => [
                            _createElementVNode("a", {
                              href: "#",
                              class: _normalizeClass([active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700'])
                            }, "Sign out", 2)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _createVNode(_unref(DisclosurePanel), { class: "md:hidden" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_11, [
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex items-center px-4 sm:px-6" }, null, -1)),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_unref(DisclosureButton), {
                as: "a",
                href: signupLink.value,
                class: "block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('home.cta.cta')), 1)
                ]),
                _: 1
              }, 8, ["href"]),
              _cache[11] || (_cache[11] = _createElementVNode("div", {
                class: "inset-0 flex items-center",
                "aria-hidden": "true"
              }, [
                _createElementVNode("div", { class: "w-full border-t border-gray-300" })
              ], -1)),
              _createVNode(_unref(DisclosureButton), {
                as: "a",
                href: linkTermsAndConditionsPageShoppers.value,
                class: "block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('footer.links.terms.shoppers.name')), 1)
                ]),
                _: 1
              }, 8, ["href"]),
              _createVNode(_unref(DisclosureButton), {
                as: "a",
                href: linkTermsAndConditionsPage.value,
                class: "block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('footer.links.terms.partners.name')), 1)
                ]),
                _: 1
              }, 8, ["href"]),
              _createVNode(_unref(DisclosureButton), {
                as: "a",
                href: linkPrivacyPage.value,
                class: "block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('footer.links.terms.privacy.name')), 1)
                ]),
                _: 1
              }, 8, ["href"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})