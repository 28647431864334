<!-- eslint-disable max-len -->
<template>
  <div>
    <!-- Skeleton Loader (Shown While isLoading is TRUE) -->
    <footer
      v-if="isLoading"
      class="py-4 flex justify-center items-center h-16 border-t border-gray-200 bg-gray-200 animate-pulse"
    >
      <div class="mx-auto overflow-hidden px-6 flex justify-between w-full">
        <!-- Skeleton Language Selector -->
        <div class="h-6 w-24 bg-gray-300 rounded" />

        <!-- Skeleton Text -->
        <div class="h-4 w-32 bg-gray-300 rounded" />
      </div>
    </footer>

    <!-- Actual Footer (Shown When isLoading is FALSE) -->
    <footer
      v-else
      :class="['py-4 flex justify-center items-center h-16 border-t border-gray-200 mt-6', {
        'bg-white': !props.noBackgound,
      }]"
    >
      <div
        :class="['mx-auto overflow-hidden px-6 flex justify-between w-full', {
          'flex-col': !props.horizontal,
          'flex-row': props.horizontal,
        }]"
      >
        <div class="flex justify-center">
          <ChooseLanguage :hide-label="props.hideLabel" />
        </div>
        <p class="text-sm/6 text-gray-600 text-center">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="t('home.footer.love')" />
        </p>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
import ChooseLanguage from '@/components/tailwind/shared/ChooseLanguage.vue';

/*
  Props
*/
const props = defineProps({
  horizontal: {
    type: Boolean,
    default: false,
  },
  noBackgound: {
    type: Boolean,
    default: false,
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
});

/*
  Data
*/
const isLoading = ref(true);

/*
  Hooks
*/
onMounted(() => {
  setTimeout(() => {
    isLoading.value = false;
  }, 100);
});

const { t } = useI18n();
</script>
