import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "py-4 flex justify-center items-center h-16 border-t border-gray-200 bg-gray-200 animate-pulse"
}
const _hoisted_2 = { class: "flex justify-center" }
const _hoisted_3 = { class: "text-sm/6 text-gray-600 text-center" }
const _hoisted_4 = ["innerHTML"]

import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import ChooseLanguage from '@/components/tailwind/shared/ChooseLanguage.vue';

/*
  Props
*/

export default /*@__PURE__*/_defineComponent({
  __name: 'SimpleFooter',
  props: {
  horizontal: {
    type: Boolean,
    default: false,
  },
  noBackgound: {
    type: Boolean,
    default: false,
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

/*
  Data
*/
const isLoading = ref(true);

/*
  Hooks
*/
onMounted(() => {
  setTimeout(() => {
    isLoading.value = false;
  }, 100);
});

const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("footer", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "mx-auto overflow-hidden px-6 flex justify-between w-full" }, [
            _createElementVNode("div", { class: "h-6 w-24 bg-gray-300 rounded" }),
            _createElementVNode("div", { class: "h-4 w-32 bg-gray-300 rounded" })
          ], -1)
        ])))
      : (_openBlock(), _createElementBlock("footer", {
          key: 1,
          class: _normalizeClass(['py-4 flex justify-center items-center h-16 border-t border-gray-200 mt-6', {
        'bg-white': !props.noBackgound,
      }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(['mx-auto overflow-hidden px-6 flex justify-between w-full', {
          'flex-col': !props.horizontal,
          'flex-row': props.horizontal,
        }])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(ChooseLanguage, {
                "hide-label": props.hideLabel
              }, null, 8, ["hide-label"])
            ]),
            _createElementVNode("p", _hoisted_3, [
              _createElementVNode("span", {
                innerHTML: _unref(t)('home.footer.love')
              }, null, 8, _hoisted_4)
            ])
          ], 2)
        ], 2))
  ]))
}
}

})