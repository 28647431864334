import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import useAxios from '@/libs/axios';
import ExternalLinkProduct from '@/models/externalLink/product';
import Pagination from '@/models/yampi/product/pagination';
import eventBus from '@/eventBus/eventBus';
import { Json } from 'aws-sdk/clients/robomaker';

export default defineStore('externalLinkProductStore', () => {
  const axios = useAxios();

  /*
    State
  */
  const externalLinkProductStore = ref({
    selectedProduct: {} as ExternalLinkProduct,
    products: {
      data: [] as ExternalLinkProduct[],
      pagination: {} as Pagination,
    },
  });

  /*
    Actions
  */
  // Reset the whole store and remove it from localStorage
  // after user logout
  const resetStore = () => {
    localStorage.removeItem('externalLinkProductStore');
    externalLinkProductStore.value.products = {
      data: [] as ExternalLinkProduct[],
      pagination: {} as Pagination,
    };
  };

  const productsDoSearch = async (params: {
    merchantUuid: string,
    streamUuid: string,
  }, skipCache = false) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      merchant_uuid: params.merchantUuid,
      stream_uuid: params.streamUuid,
      product_gateway: 'external-link',
      skip_cache: skipCache,
    };

    return axios.post('/products/search', payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) {
          return undefined;
        }

        const { columns, rows, pagination } = response.data;
        const products = ExternalLinkProduct.fromApiResponse(columns, rows);
        externalLinkProductStore.value.products.data = products;
        externalLinkProductStore.value.products.pagination = new Pagination(pagination);
        return externalLinkProductStore.value.products;
      }).catch((error) => {
        console.error(error);
        return undefined;
      });
  };

  const productsDoCreate = async (merchantUuid: string, params: {
    active: boolean,
    name: string,
    link: string,
    description: string,
    images: Json,
    priceSale: number,
    priceDiscount: number,
  }) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      merchant_uuid: merchantUuid,
      active: params.active,
      name: params.name,
      link: params.link,
      description: params.description,
      images: Object.keys(params.images).length > 0 ? params.images : null,
      price_sale: (params.priceSale),
      price_discount: (params.priceDiscount),
      external_link: true,
    };

    return axios.post('/products/create', payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) {
          return undefined;
        }
        return true;
      });
  };

  const productsDoUpdate = async (merchantUuid: string, productUuid: string, params: {
    active: boolean,
    name: string,
    link: string,
    description: string,
    images: [],
    priceSale: number,
    priceDiscount: number,
  }) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      merchant_uuid: merchantUuid,
      product_uuid: productUuid,
      active: params.active,
      name: params.name,
      link: params.link,
      description: params.description,
      images: params.images,
      price_sale: `${params.priceSale}`,
      price_discount: `${params.priceDiscount}`,
      external_link: true,
    };

    return axios.post('/products/update', payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) {
          return undefined;
        }
        return true;
      }).catch((error) => {
        console.error(error);
        return undefined;
      });
  };

  const productsDoDelete = async (productUuid: string, merchantUuid: string) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      product_uuid: productUuid,
      merchant_uuid: merchantUuid,
      must_delete: true,
      external_link: true,
    };

    return axios.post('/products/update', payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        return true;
      }).catch((error) => {
        console.error(error);
        return undefined;
      });
  };

  /*
    Config
  */

  // Listen to logout event
  // This event is used to clear the store
  eventBus.on('event:logout', resetStore);

  // Load store from localStorage
  // This is necessary to keep the store
  // when user refresh the page
  if (localStorage.getItem('externalLinkProductStore')) {
    const v = JSON.parse(localStorage.getItem('externalLinkProductStore') || '{}');
    externalLinkProductStore.value.products.data = v.products.data.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (row: any): ExternalLinkProduct => new ExternalLinkProduct(row),
    );
    externalLinkProductStore.value.products.pagination = new Pagination(v.products.pagination);
  }

  watch(externalLinkProductStore, (productsVal) => {
    localStorage.setItem('externalLinkProductStore', JSON.stringify(productsVal));
  }, {
    deep: true,
  });

  return {
    productModel: ExternalLinkProduct,
    resetStore,
    externalLinkProductStore,
    productsDoSearch,
    productsDoCreate,
    productsDoUpdate,
    productsDoDelete,
  };
});
