import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "min-w-full divide-y divide-gray-300 pb-8" }
const _hoisted_2 = { class: "hidden md:table-cell py-4 pr-3 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-0" }
const _hoisted_3 = { class: "px-3 py-4 text-left text-sm font-semibold text-gray-900 table-cell" }
const _hoisted_4 = { class: "whitespace-nowrap" }
const _hoisted_5 = { class: "sm:hidden whitespace-nowrap" }
const _hoisted_6 = { class: "hidden px-3 py-4 text-left text-sm font-semibold text-gray-900 sm:table-cell whitespace-nowrap" }
const _hoisted_7 = { class: "hidden px-3 py-4 text-left text-sm font-semibold text-gray-900 sm:table-cell" }
const _hoisted_8 = { class: "divide-y divide-gray-200 bg-white" }
const _hoisted_9 = { class: "hidden md:table-cell px-3 py-4 text-sm text-gray-500" }
const _hoisted_10 = ["src"]
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "px-3 py-4 text-sm text-gray-500 table-cell" }
const _hoisted_13 = { class: "sm:hidden text-gray-500" }
const _hoisted_14 = { class: "hidden px-3 py-4 text-sm text-gray-500 sm:table-cell" }
const _hoisted_15 = { class: "hidden px-3 py-4 text-sm text-gray-500 sm:table-cell" }
const _hoisted_16 = { class: "w-full py-4 pr-4 pl-3 text-right text-sm font-medium whitespace-nowrap" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { key: 0 }
const _hoisted_19 = {
  colspan: "5",
  class: "px-3 py-4 text-sm text-gray-500 gap-x-2"
}


import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Plugin from '@/models/plugin/plugin';
import InstagramLiveMetadata from '@/models/plugin/instagramLiveMetadata';
import lockClosed from '@/assets/images/lock-closed.png';
import coin from '@/assets/images/coin.png';


export default /*@__PURE__*/_defineComponent({
  __name: 'ListPluginReportsTable',
  props: {
  plugin: {
    type: () => ({} as Plugin),
    required: true,
  },
  metadatas: {
    type: () => [] as InstagramLiveMetadata[],
    required: true,
  },
  pageLimit: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const { t } = useI18n();
const router = useRouter();

/*
  Props
*/
const props = __props;

/*
  Methods
*/
const openPluginLiveReportPage = async (
  accountId: string,
  mediaId: string,
) => {
  await router.push({
    name: 'PluginLiveReportPage',
    params: {
      pluginId: props.plugin.id,
      pluginType: props.plugin.type,
      accountId,
      mediaId,
    },
    query: {
      pluginName: props.plugin.name,
    },
  });
};
const openPluginLivesPage = async () => {
  await router.push({
    name: 'PluginLivesPage',
    params: {
      pluginId: props.plugin.id,
      pluginType: props.plugin.type,
    },
  });
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        _createElementVNode("th", _hoisted_2, _toDisplayString(_unref(t)('plugin.lives.reports.table.credit-status')), 1),
        _createElementVNode("th", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(t)('plugin.lives.reports.table.date')), 1),
          _createElementVNode("p", _hoisted_5, " / " + _toDisplayString(_unref(t)('plugin.lives.reports.table.first-message-time')), 1)
        ]),
        _createElementVNode("th", _hoisted_6, _toDisplayString(_unref(t)('plugin.lives.reports.table.first-message-time')), 1),
        _createElementVNode("th", _hoisted_7, _toDisplayString(_unref(t)('plugin.lives.reports.table.comments')), 1),
        _cache[0] || (_cache[0] = _createElementVNode("th", { class: "relative py-4 pr-4 pl-3 sm:pr-0 whitespace-nowrap" }, [
          _createElementVNode("span", { class: "sr-only" }, "Actions")
        ], -1))
      ])
    ]),
    _createElementVNode("tbody", _hoisted_8, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.metadatas, (metadata, index) => {
        return (_openBlock(), _createElementBlock("tr", { key: index }, [
          _createElementVNode("td", _hoisted_9, [
            (!metadata.missingPayment())
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _unref(coin),
                  alt: "paid",
                  class: "w-8 h-8"
                }, null, 8, _hoisted_10))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _unref(lockClosed),
                  alt: "unpaid",
                  class: "w-8 h-8"
                }, null, 8, _hoisted_11))
          ]),
          _createElementVNode("td", _hoisted_12, [
            _createTextVNode(_toDisplayString(metadata?.getLiveFirstMessageDate()) + " ", 1),
            _createElementVNode("p", _hoisted_13, " / " + _toDisplayString(metadata?.getLiveFirstMessageTime()), 1)
          ]),
          _createElementVNode("td", _hoisted_14, _toDisplayString(metadata?.getLiveFirstMessageTime()), 1),
          _createElementVNode("td", _hoisted_15, _toDisplayString(metadata?.formattedCommentsCount()), 1),
          _createElementVNode("td", _hoisted_16, [
            _createElementVNode("a", {
              href: "#",
              class: "text-teal-600 hover:text-teal-500",
              onClick: _withModifiers(($event: any) => (openPluginLiveReportPage(metadata.accountId, metadata.mediaId)), ["prevent"])
            }, _toDisplayString(_unref(t)('plugin.lives.reports.table.view-report')), 9, _hoisted_17)
          ])
        ]))
      }), 128))
    ]),
    (props.metadatas.length >=5)
      ? (_openBlock(), _createElementBlock("tfoot", _hoisted_18, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_19, [
              _createElementVNode("p", null, _toDisplayString(_unref(t)('plugin.lives.reports.table.results', {count: props.pageLimit})), 1),
              _createElementVNode("a", {
                href: "#",
                class: "text-teal-600 hover:text-teal-500 m-auto",
                onClick: _withModifiers(openPluginLivesPage, ["prevent"])
              }, [
                _createTextVNode(_toDisplayString(_unref(t)('plugin.lives.reports.table.footer')) + " ", 1),
                _cache[1] || (_cache[1] = _createElementVNode("span", { "aria-hidden": "true" }, "→", -1))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})