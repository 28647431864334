import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-6 grid grid-cols-2 gap-2 sm:grid-cols-4" }
const _hoisted_2 = { class: "border border-gray-300 rounded-lg shadow-sm overflow-hidden bg-white px-4 py-5 sm:p-6" }
const _hoisted_3 = { class: "truncate text-sm font-medium text-gray-500" }
const _hoisted_4 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_5 = { class: "border border-gray-300 rounded-lg shadow-sm overflow-hidden bg-white px-4 py-5 sm:p-6" }
const _hoisted_6 = { class: "truncate text-sm font-medium text-gray-500" }
const _hoisted_7 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_8 = { class: "border border-gray-300 rounded-lg shadow-sm overflow-hidden bg-white px-4 py-5 sm:p-6" }
const _hoisted_9 = { class: "truncate text-sm font-medium text-gray-500" }
const _hoisted_10 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_11 = { class: "border border-gray-300 rounded-lg shadow-sm overflow-hidden bg-white px-4 py-5 sm:p-6" }
const _hoisted_12 = { class: "truncate text-sm font-medium text-gray-500" }
const _hoisted_13 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }

import usePluginStore from '@/store/plugin/plugin';
import { ref, onMounted } from 'vue';
import InstagramLiveMetadataMetrics from '@/models/plugin/instagramLiveMetadataMetrics';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'PluginMetadatasMetrics',
  setup(__props) {

const { t } = useI18n();
const {
  pluginGetLiveMetadatasMetrics,
} = usePluginStore();

/*
  Metrics
*/
const metrics = ref({} as InstagramLiveMetadataMetrics);

/*
  Hooks
*/
onMounted(() => {
  pluginGetLiveMetadatasMetrics({}).then((res) => {
    console.log('pluginGetLiveMetadatasMetrics', res);
    [metrics.value] = res.data;
    console.log('pluginGetLiveMetadatasMetrics');
  }).catch((error) => {
    console.error('pluginGetLiveMetadatasMetrics', error);
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("dl", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("dt", _hoisted_3, _toDisplayString(_unref(t)('plugins.manager.metrics.total-plugins')), 1),
      _createElementVNode("dd", _hoisted_4, _toDisplayString(metrics.value.formattedTotalPlugins()), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("dt", _hoisted_6, _toDisplayString(_unref(t)('plugins.manager.metrics.total-medias')), 1),
      _createElementVNode("dd", _hoisted_7, _toDisplayString(metrics.value.formattedTotalMedias()), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("dt", _hoisted_9, _toDisplayString(_unref(t)('plugins.manager.metrics.total-messages')), 1),
      _createElementVNode("dd", _hoisted_10, _toDisplayString(metrics.value.formattedTotalComments()), 1)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("dt", _hoisted_12, _toDisplayString(_unref(t)('plugins.manager.metrics.total-users')), 1),
      _createElementVNode("dd", _hoisted_13, _toDisplayString(metrics.value.formattedTotalUsers()), 1)
    ])
  ]))
}
}

})