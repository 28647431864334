class StripeCheckoutSession {
  productId: string;

  productPriceId: string;

  productType: string;

  productCurrency: string;

  successUrl: string;

  sessionUrl: string;

  cancelUrl: string;

  constructor({
    productId,
    productPriceId,
    productType,
    productCurrency,
    successUrl,
    sessionUrl,
    cancelUrl,
  }: {
    productId: string,
    productPriceId: string,
    productType: string,
    productCurrency: string,
    successUrl: string,
    sessionUrl: string,
    cancelUrl: string,
  }) {
    this.productId = productId;
    this.productPriceId = productPriceId;
    this.productType = productType;
    this.productCurrency = productCurrency;
    this.successUrl = successUrl;
    this.sessionUrl = sessionUrl;
    this.cancelUrl = cancelUrl;
  }

  static fromApiResponse(columns: string[], rows: any[][]): StripeCheckoutSession[] {
    if (rows.length === 0) {
      return [];
    }

    return rows.map((row: any[]) => {
      const data: any = {};

      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        data[camelCaseKey] = row[index];
      });

      return new StripeCheckoutSession(data);
    });
  }
}

export default StripeCheckoutSession;
