import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {
  ref,
  computed,
  onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import useProductStore from '@/store/yampi/product';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MerchantManagerProductsLayout',
  setup(__props) {

const router = useRouter();

const {
  productsDoSearch,
  productsDoDelete,
} = useProductStore();

/*
  Data
*/
const limit = ref(
  router.currentRoute.value.query.limit
    ? parseInt(router.currentRoute.value.query.limit, 10)
    : 10,
);
const page = ref(
  router.currentRoute.value.query.page
    ? parseInt(router.currentRoute.value.query.page, 10)
    : 1,
);
const products = ref([]);
const pagination = ref({});
const isLoading = ref(true);
const dataLoaded = ref(false);

/*
  Computed
*/
const routeKey = computed(() => router.fullPath);
const productGateway = computed(() => router.currentRoute.value.params.brandId);
const merchantUuid = computed(() => router.currentRoute.value.params.uuid);

/*
  Methods
*/
const deleteProduct = async (value) => {
  const { name, id } = value;
  // eslint-disable-next-line no-alert
  const confirmed = window.confirm(`Deseja remover o Produto:\n${name}?`);
  if (confirmed) {
    isLoading.value = true;
    await productsDoDelete(id).then((success) => {
      if (success === false) {
        throw new Error('Não foi possível completar sua solicitação. Tente novamente mais tarde.');
      }
      notify({
        duration: 5000,
        type: 'success',
        title: 'Uhuuu',
        text: 'Removido com sucesso',
      });
      products.value = products.value.filter((product) => product.id !== id);
    }).catch((error) => {
      let { message } = error;
      if (message === undefined) {
        message = error;
      }
      if (error === undefined) {
        message = 'Não foi possível completar sua solicitação. Tente novamente mais tarde. (x002)';
      }
      notify({
        duration: 5000,
        type: 'error',
        title: 'Ooops',
        text: message,
      });
    }).finally(() => {
      isLoading.value = false;
    });
  }
};
const load = async (page_: number, limit_:number) => {
  await productsDoSearch({
    merchantUuid: merchantUuid.value,
    productGateway: productGateway.value,
    limit: limit_,
    page: page_,
  }, true).then((response) => {
    if (response !== undefined) {
      products.value = response.data;
      pagination.value = response.pagination;
    }
    isLoading.value = false;
  }).catch((error) => {
    console.error('error', error);
    notify({
      duration: 5000,
      type: 'error',
      title: 'Ooops',
      text: error.toString(),
    });
  });
};
const reloadProducts = async (value) => {
  const { page: page_, limit: limit_ } = value;
  page.value = page_;
  limit.value = limit_;
  isLoading.value = true;
  await load(page_, limit_);
};

/*
  Hooks
*/
onBeforeMount(async () => {
  await load(page.value, limit.value);
  dataLoaded.value = true;
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (dataLoaded.value)
      ? (_openBlock(), _createBlock(_component_RouterView, {
          key: routeKey.value,
          "merchant-uuid": merchantUuid.value,
          "brand-id": productGateway.value,
          products: products.value,
          pagination: pagination.value,
          "is-loading": isLoading.value,
          "onLoad:products": _cache[0] || (_cache[0] = (value) => reloadProducts(value)),
          "onDelete:product": _cache[1] || (_cache[1] = (value) => deleteProduct(value))
        }, null, 8, ["merchant-uuid", "brand-id", "products", "pagination", "is-loading"]))
      : _createCommentVNode("", true),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, {
          key: 1,
          "full-screen": false
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_notifications, { classes: "toast-notification" })
  ], 64))
}
}

})