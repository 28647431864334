import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {
  ref,
  computed,
  onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import useProductStore from '@/store/externalLink/product';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MerchantManagerExternalLinkProductsLayout',
  setup(__props) {

const router = useRouter();

const {
  productsDoSearch,
} = useProductStore();

/*
  Data
*/
const products = ref([]);
const isLoading = ref(true);

/*
  Computed
*/
const routeKey = computed(() => router.fullPath);
const merchantUuid = computed(() => router.currentRoute.value.params.uuid);

/*
  Methods
*/
const resetFields = async () => {
  isLoading.value = true;
  await productsDoSearch({
    merchantUuid: merchantUuid.value,
  }, true).then((response) => {
    if (response !== undefined && response.data.length > 0) {
      products.value = response.data.sort((a, b) => {
        if (a.title > b.title) {
          return 1;
        }
        if (a.title < b.title) {
          return -1;
        }

        return 0;
      });
    }
  }).catch((error) => {
    console.error(error);
  }).finally(() => {
    isLoading.value = false;
  });
};

/*
  Hooks
*/
onBeforeMount(async () => {
  resetFields();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!isLoading.value)
      ? (_openBlock(), _createBlock(_component_RouterView, {
          key: routeKey.value,
          products: products.value,
          "merchant-uuid": merchantUuid.value,
          "onUpdate:products": resetFields
        }, null, 8, ["products", "merchant-uuid"]))
      : _createCommentVNode("", true),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingComponent, {
          key: 1,
          "full-screen": true
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})