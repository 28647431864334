<!-- eslint-disable max-len -->
<template>
  <div
    v-if="dataLoaded"
    class="mx-auto min-h-full"
  >
    <SimpleHeader
      :on-close="handleClose"
      :pages="[
        { name: t('sidemenu.navigation.integration.title'), href: router.resolve({ name: 'PluginsManagerPage' }).href, current: false },
        { name: `@${plugin.name}`, href: '#', current: true },
      ]"
    />
    <RouterView
      :plugin="plugin"
    />
  </div>
  <SimpleFooter
    :horizontal="true"
  />
</template>

<script setup lang="ts">
import {
  ref,
  watch,
  computed,
  onBeforeMount,
  onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import usePluginStore from '@/store/plugin/plugin';
import InstagramLiveMetadata from '@/models/plugin/instagramLiveMetadata';
import Plugin from '@/models/plugin/plugin';
import { useI18n } from 'vue-i18n';
import SimpleFooter from '@/components/tailwind/footer/SimpleFooter.vue';
import SimpleHeader from '@/components/tailwind/header/SimpleHeader.vue';

const router = useRouter();
const { t } = useI18n();
const {
  pluginsDoGetMy,
  pluginGetSharedWithMeLiveMetadatas,
} = usePluginStore();

/*
  Data
*/
const dataLoaded = ref(false);
const isLoading = ref(true);
const plugin = ref({} as Plugin);
const newPlugins = ref([] as Plugin[]);
const metadatasSharedWithMe = ref([] as InstagramLiveMetadata[]);

/*
  Computed
*/
const pluginId = computed(() => router.currentRoute.value.params.pluginId);

/*
  Methods
*/
const handleClose = () => {
  router.push({
    name: 'PluginsManagerPage',
  });
};

// const deletePlugin = async (plugin: Plugin) => {
//   await pluginsDoDelete(plugin.id)
//     .then(() => {
//       notify({
//         type: 'success',
//         title: 'Sucesso',
//         text: 'Plugin removido com sucesso',
//       });
//       plugins.value = plugins.value.filter((p) => p.id !== plugin.id);
//     }).catch((error) => {
//       console.error('error', error);
//       notify({
//         type: 'error',
//         title: 'Erro',
//         text: 'Não foi possível remover o plugin',
//       });
//     });
// };
const redirectDashboard = ((error: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar a página. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));
const loadPlugins = () => {
  pluginsDoGetMy(`${pluginId.value}`)
    .then((response) => {
      if (response !== undefined) {
        const res = response.data;
        plugin.value = new Plugin(res[0]);
      }
    }).catch((error) => {
      console.error('error', error);
      redirectDashboard(error.toString());
    });
};
const loadMetadatasSharedWithMe = () => {
  pluginGetSharedWithMeLiveMetadatas({
    page: 1,
    limit: 100,
    orderBy: 'createdAt',
    order: -1,
  })
    .then((response) => {
      if (response !== undefined) {
        metadatasSharedWithMe.value = response.data;
      }
    }).catch((error) => {
      console.error('error', error);
      redirectDashboard(error.toString());
    });
};
const load = async () => {
  isLoading.value = true;
  await loadPlugins();
  await loadMetadatasSharedWithMe();

  isLoading.value = false;
  dataLoaded.value = true;
};

/*
  Hooks
*/
onBeforeMount(async () => {
  await load();
});
onMounted(() => {
  setTimeout(() => {
    dataLoaded.value = true;
  }, 500);
});
watch(newPlugins, () => {
  load();
});
</script>
