import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main has-navbar-fixed-top" }
const _hoisted_2 = { class: "section" }
const _hoisted_3 = { class: "section-content content" }

import {
  ref,
  computed,
  onBeforeMount,
  onUnmounted,
} from 'vue';
import { useRouter } from 'vue-router';
import useStreamStore from '@/store/stream';
import PageHeader from '@/components/header/PageHeader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'StreamRecordingsPageLayout',
  setup(__props) {

const router = useRouter();
const { streamDoGet, resetStore } = useStreamStore();

/*
  Computed
*/
const streamUri = computed(() => router.currentRoute.value.params.uri);

/*
  Data
*/
const isDataLoaded = ref(false);
const expanded = ref(false);
const stream = ref({});

/*
  Methods
*/
const load = async () => {
  await streamDoGet(streamUri.value).then((result) => {
    if (result === undefined) {
      router.push({ name: 'NotFoundPage' });
    } else {
      stream.value = result;
    }
    if (!stream.value.landingPageActive) {
      router.push({ name: 'HomePage' });
    }
    isDataLoaded.value = true;
  }).catch((error) => {
    console.error(error);
  });
};

/*
  Hooks
*/
onBeforeMount(() => {
  load();
});
onUnmounted(() => {
  resetStore();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, {
      "is-loading": false,
      title: "Gravações de transmissões",
      to: "StreamManagerPage",
      params: { uuid: stream.value.uuid }
    }, null, 8, ["params"]),
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass([{
            'is-ellipsis clamp-2-lines': !expanded.value,
          }])
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("span", { class: "subtitle is-size-6" }, " Na página, você encontrará o vídeo de todas as suas transmissões gravadas. Mesmo se pausar e retomar a live, cada momento estará disponível. Para poder utilizar em outras plataformas, basta solicitar o download abaixo do vídeo desejado. Um aviso surgirá explicando como receber o link por e-mail (verifique também o spam). Lembre-se, arquivos maiores demandam mais tempo para ficarem prontos. ", -1)
          ]), 2),
          _createElementVNode("a", {
            href: "#",
            class: "has-text-link no-underline",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (expanded.value = !expanded.value), ["prevent"]))
          }, _toDisplayString(expanded.value ? 'ver menos' : 'continuar lendo'), 1)
        ])
      ]),
      (isDataLoaded.value)
        ? (_openBlock(), _createBlock(_component_RouterView, {
            key: 0,
            stream: stream.value
          }, null, 8, ["stream"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})