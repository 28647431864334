<template>
  <dl class="mt-6 grid grid-cols-2 gap-2 sm:grid-cols-4">
    <div
      class="border border-gray-300 rounded-lg shadow-sm overflow-hidden bg-white px-4 py-5 sm:p-6"
    >
      <dt class="truncate text-sm font-medium text-gray-500">
        {{ t('plugins.manager.metrics.total-plugins') }}
      </dt>
      <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
        {{ metrics.formattedTotalPlugins() }}
      </dd>
    </div>
    <div
      class="border border-gray-300 rounded-lg shadow-sm overflow-hidden bg-white px-4 py-5 sm:p-6"
    >
      <dt class="truncate text-sm font-medium text-gray-500">
        {{ t('plugins.manager.metrics.total-medias') }}
      </dt>
      <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
        {{ metrics.formattedTotalMedias() }}
      </dd>
    </div>
    <div
      class="border border-gray-300 rounded-lg shadow-sm overflow-hidden bg-white px-4 py-5 sm:p-6"
    >
      <dt class="truncate text-sm font-medium text-gray-500">
        {{ t('plugins.manager.metrics.total-messages') }}
      </dt>
      <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
        {{ metrics.formattedTotalComments() }}
      </dd>
    </div>
    <div
      class="border border-gray-300 rounded-lg shadow-sm overflow-hidden bg-white px-4 py-5 sm:p-6"
    >
      <dt class="truncate text-sm font-medium text-gray-500">
        {{ t('plugins.manager.metrics.total-users') }}
      </dt>
      <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
        {{ metrics.formattedTotalUsers() }}
      </dd>
    </div>
  </dl>
</template>

<script setup lang="ts">
import usePluginStore from '@/store/plugin/plugin';
import { ref, onMounted } from 'vue';
import InstagramLiveMetadataMetrics from '@/models/plugin/instagramLiveMetadataMetrics';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const {
  pluginGetLiveMetadatasMetrics,
} = usePluginStore();

/*
  Metrics
*/
const metrics = ref({} as InstagramLiveMetadataMetrics);

/*
  Hooks
*/
onMounted(() => {
  pluginGetLiveMetadatasMetrics({}).then((res) => {
    console.log('pluginGetLiveMetadatasMetrics', res);
    [metrics.value] = res.data;
    console.log('pluginGetLiveMetadatasMetrics');
  }).catch((error) => {
    console.error('pluginGetLiveMetadatasMetrics', error);
  });
});
</script>
