import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto min-h-full" }

import {
  ref,
  watch,
  onBeforeMount,
  onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import usePluginStore from '@/store/plugin/plugin';
import InstagramLiveMetadata from '@/models/plugin/instagramLiveMetadata';
import Plugin from '@/models/plugin/plugin';
import SimpleHeader from '@/components/tailwind/header/SimpleHeader.vue';
import { useI18n } from 'vue-i18n';
import SimpleFooter from '@/components/tailwind/footer/SimpleFooter.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ManagerLayout',
  setup(__props) {

const router = useRouter();
const { t } = useI18n();

const handleClose = () => {
  router.push({
    name: 'DashboardPage',
  });
};

const {
  pluginsDoGetMy,
  pluginGetSharedWithMeLiveMetadatas,
} = usePluginStore();

/*
  Data
*/
const dataLoaded = ref(false);
const isLoading = ref(true);
const plugins = ref([] as Plugin[]);
const newPlugins = ref([] as Plugin[]);
const metadatasSharedWithMe = ref([] as InstagramLiveMetadata[]);

/*
  Methods
*/
// const deletePlugin = async (plugin: Plugin) => {
//   await pluginsDoDelete(plugin.id)
//     .then(() => {
//       notify({
//         type: 'success',
//         title: 'Sucesso',
//         text: 'Plugin removido com sucesso',
//       });
//       plugins.value = plugins.value.filter((p) => p.id !== plugin.id);
//     }).catch((error) => {
//       console.error('error', error);
//       notify({
//         type: 'error',
//         title: 'Erro',
//         text: 'Não foi possível remover o plugin',
//       });
//     });
// };
const redirectDashboard = ((error: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar a página. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));
const loadPlugins = () => {
  pluginsDoGetMy()
    .then((response) => {
      if (response !== undefined) {
        plugins.value = response.data;
      }
    }).catch((error) => {
      console.error('error', error);
      redirectDashboard(error.toString());
    });
};
const loadMetadatasSharedWithMe = () => {
  pluginGetSharedWithMeLiveMetadatas({
    page: 1,
    limit: 100,
    orderBy: 'createdAt',
    order: -1,
  })
    .then((response) => {
      if (response !== undefined) {
        metadatasSharedWithMe.value = response.data;
      }
    }).catch((error) => {
      console.error('error', error);
      redirectDashboard(error.toString());
    });
};
const load = async () => {
  isLoading.value = true;
  await loadPlugins();
  await loadMetadatasSharedWithMe();

  isLoading.value = false;
  dataLoaded.value = true;
};

/*
  Hooks
*/
onBeforeMount(async () => {
  await load();
});
onMounted(() => {
  setTimeout(() => {
    dataLoaded.value = true;
  }, 500);
});
watch(newPlugins, () => {
  load();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(SimpleHeader, {
        "on-close": handleClose,
        pages: [
        { name: _unref(t)('sidemenu.navigation.integration.title'), href: _unref(router).resolve({ name: 'PluginsManagerPage' }).href, current: true },
      ]
      }, null, 8, ["pages"]),
      _createVNode(_component_RouterView, {
        plugins: plugins.value,
        "data-loaded": dataLoaded.value
      }, null, 8, ["plugins", "data-loaded"])
    ]),
    _createVNode(SimpleFooter, { horizontal: true })
  ], 64))
}
}

})