class InstagramLiveMetadataMetrics {
  totalPlugins : number

  totalMedias : number

  totalUsers : number

  totalComments : number

  profilePictureBase64: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor({
    totalPlugins,
    totalMedias,
    totalUsers,
    totalComments,
    profilePictureBase64,
  }: {
    totalPlugins: number;
    totalMedias: number;
    totalUsers: number;
    totalComments: number;
    profilePictureBase64: string;
  }) {
    this.totalPlugins = totalPlugins;
    this.totalMedias = totalMedias;
    this.totalUsers = totalUsers;
    this.totalComments = totalComments;
    this.profilePictureBase64 = profilePictureBase64;
  }

  formattedTotalPlugins(): string {
    const userLang = navigator.language; // Detects browser language
    return new Intl.NumberFormat(userLang).format(this.totalPlugins);
  }

  formattedTotalMedias(): string {
    const userLang = navigator.language; // Detects browser language
    return new Intl.NumberFormat(userLang).format(this.totalMedias);
  }

  formattedTotalUsers(): string {
    const userLang = navigator.language; // Detects browser language
    return new Intl.NumberFormat(userLang).format(this.totalUsers);
  }

  formattedTotalComments(): string {
    const userLang = navigator.language; // Detects browser language
    return new Intl.NumberFormat(userLang).format(this.totalComments);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): InstagramLiveMetadataMetrics[] {
    if (rows.length === 0) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        v[camelCaseKey] = row[index];
      });
      return new InstagramLiveMetadataMetrics(v);
    });
  }
}

export default InstagramLiveMetadataMetrics;
