import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto min-h-full" }

import {
  ref, onBeforeMount, computed,
} from 'vue';
import { useRouter } from 'vue-router';
import usePluginStore from '@/store/plugin/plugin';
import InstagramLiveStatus from '@/models/plugin/instagramLiveStatus';
import InstagramLiveMetadata from '@/models/plugin/instagramLiveMetadata';
import Pagination from '@/models/pagination';
import SimpleHeader from '@/components/tailwind/header/SimpleHeader.vue';
import SimpleFooter from '@/components/tailwind/footer/SimpleFooter.vue';
import Plugin from '@/models/plugin/plugin';
import { useI18n } from 'vue-i18n';

interface InstagramLiveMetadataResponse {
  data: InstagramLiveMetadata[];
  pagination: Pagination;
}

/*
  Data
*/

export default /*@__PURE__*/_defineComponent({
  __name: 'PluginLiveReportLayout',
  setup(__props) {

const router = useRouter();
const { t } = useI18n();

const {
  pluginsDoGetMy,
  pluginAccountLiveStatus,
  pluginGetLiveMetadatas,
} = usePluginStore();

const isLoading = ref(true);
const initialLimitMessages = ref(500);
const initialPageMessages = ref(1);
const initialLimitQuestions = ref(500);
const initialPageQuestions = ref(1);
const initialLimitUsers = ref(500);
const initialPageUsers = ref(1);
const instagramLiveStatus = ref({} as InstagramLiveStatus);
const metadata = ref({} as InstagramLiveMetadata);
const metadataPagination = ref({} as Pagination);
const moderator = ref(router.currentRoute.value.query.ga);
const plugin = ref({} as Plugin);

/*
  Computed
*/
const pluginId = computed(() => router.currentRoute.value.params.pluginId);
const pluginType = computed(() => router.currentRoute.value.params.pluginType);
const pluginName = computed(() => plugin.value.name);
const accountId = computed(() => router.currentRoute.value.params.accountId);
const mediaId = computed(() => router.currentRoute.value.params.mediaId);

/*
  Methods
*/
const handleClose = () => {
  router.push({
    name: 'PluginLivesPage',
    params: {
      pluginId: `${pluginId.value}`,
      pluginType: `${pluginType.value}`,
    },
    query: {
      tab: 'reports',
    },
  });
};
const redirectDashboard = ((error: string) => router.push({
  name: 'DashboardPage',
  query: {
    message: 'Não foi possível carregar a página. Tente novamente em instantes.',
    detail: error,
    type: 'error',
  },
}));
const loadPlugin = () => {
  pluginsDoGetMy(`${pluginId.value}`, `${mediaId.value}`)
    .then((response) => {
      if (response !== undefined) {
        const res = response.data;
        plugin.value = new Plugin(res[0]);
      }
    }).catch((error) => {
      console.error('error', error);
      redirectDashboard(error.toString());
    });
};

const loadAccountLiveStatus = async () => {
  pluginAccountLiveStatus({
    pluginId: `${pluginId.value}`,
    pluginType: `${pluginType.value}`,
  }).then((res) => {
    if (!res || !Array.isArray(res.data) || res.data.length === 0) {
      instagramLiveStatus.value = {} as InstagramLiveStatus;
      return;
    }
    const { data } = res;
    [instagramLiveStatus.value] = data;
  }).catch((error) => {
    console.error('Error', error);
    instagramLiveStatus.value = {} as InstagramLiveStatus;
  });
};

const loadLiveMetadata = async () => {
  await pluginGetLiveMetadatas({
    pluginId: `${pluginId.value}`,
    pluginType: `${pluginType.value}`,
    page: 1,
    limit: 1,
    orderBy: 'createdAt',
    order: -1,
    mediaId: `${mediaId.value}`,
    moderator: `${moderator.value}` === '1',
  }).then((response: InstagramLiveMetadataResponse) => {
    isLoading.value = false;
    if (response && response.data.length === 0) {
      alert('Dados do relatório não encontrados. Recarregue a página e tente novamente.');
      router.push({
        name: 'PluginsManagerPage',
      });
    }
    if (!response) {
      metadata.value = {} as InstagramLiveMetadata;
      metadataPagination.value = {} as Pagination;
      return;
    }
    [metadata.value] = response.data;
    metadataPagination.value = response.pagination;
  }).catch((error) => {
    console.error('Error', error);
    isLoading.value = false;
  });
};

/*
  Hooks
*/
onBeforeMount(() => {
  loadPlugin();
  loadLiveMetadata();
  loadAccountLiveStatus();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(SimpleHeader, {
        "on-close": handleClose,
        pages: [
        { name: _unref(t)('sidemenu.navigation.integration.title'), href: _unref(router).resolve({ name: 'PluginsManagerPage' }).href, current: false },
        { name: `@${pluginName.value}`, href: _unref(router).resolve({
          name: 'PluginLivesPage',
          params: {
            pluginId: `${pluginId.value}`,
            pluginType: `${pluginType.value}`,
          },
          query: {
            tab: 'reports',
          },
        }).href, current: false },
        { name: _unref(t)('sidemenu.navigation.report-details.title'), href: '#', current: true },
      ]
      }, null, 8, ["pages"]),
      _createVNode(_component_RouterView, {
        plugin: plugin.value,
        metadata: metadata.value,
        "instagram-live-status": instagramLiveStatus.value,
        "initial-page-messages": initialPageMessages.value,
        "initial-limit-messages": initialLimitMessages.value,
        "initial-page-questions": initialPageQuestions.value,
        "initial-limit-questions": initialLimitQuestions.value,
        "initial-page-users": initialPageUsers.value,
        "initial-limit-users": initialLimitUsers.value
      }, null, 8, ["plugin", "metadata", "instagram-live-status", "initial-page-messages", "initial-limit-messages", "initial-page-questions", "initial-limit-questions", "initial-page-users", "initial-limit-users"])
    ]),
    _createVNode(SimpleFooter, { horizontal: true })
  ], 64))
}
}

})