<!-- eslint-disable max-len -->
<template>
  <div class="max-w-screen-xl mx-auto mt-4 px-4 xl:px-0">
    <!-- Skeleton Loader (Shown While isLoading is TRUE) -->
    <div
      v-if="isLoading"
      class="animate-pulse"
    >
      <div class="md:flex md:items-center md:justify-between md:space-x-5">
        <div class="flex flex-col sm:flex-row items-start space-x-5">
          <div class="shrink-0">
            <div class="relative">
              <!-- Skeleton Image -->
              <div class="size-32 sm:size-16 bg-gray-300 rounded-lg" />
              <span
                class="absolute inset-0 rounded-lg shadow-inner"
                aria-hidden="true"
              />
            </div>
          </div>
          <!-- Skeleton Text -->
          <div class="pt-1.5 space-y-2">
            <div class="h-6 w-48 bg-gray-300 rounded" />
            <div class="h-4 w-32 bg-gray-300 rounded" />
          </div>
        </div>
      </div>
    </div>

    <!-- Actual Plugin Header (Shown When isLoading is FALSE) -->
    <div v-else>
      <div class="columns is-vcentered">
        <div class="md:flex md:items-center md:justify-between md:space-x-5">
          <div class="flex flex-col sm:flex-row items-start space-x-5">
            <div class="shrink-0">
              <div class="relative">
                <img
                  v-if="props.plugin.profilePictureBase64"
                  class="size-32 sm:size-16 rounded-lg"
                  :src="`data:image/jpeg;base64,${props.plugin.profilePictureBase64}`"
                  :alt="`${props.plugin.name} logo`"
                >
                <img
                  v-else
                  class="size-32 sm:size-16 rounded-lg"
                  src="@/assets/images/backgrounds/blank-profile-picture.png"
                  :alt="`${props.plugin.name} no logo`"
                >
                <span
                  class="absolute inset-0 rounded-lg shadow-inner"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div class="pt-1.5">
              <h1 class="text-lg lg:text-2xl font-bold text-gray-900">
                {{ props.plugin.name }}
                <span
                  v-if="props.instagramLiveStatus?.id"
                  class="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-gray-200 ring-inset bg-white"
                >
                  <font-awesome-icon
                    :icon="['fas', 'circle']"
                    class="ml-2 text-green-500 animate-pulse"
                  /> {{ t('plugins.dashboard.header.live') }}
                </span>
              </h1>
              <p class="text-sm font-medium text-gray-500">
                {{ t('plugins.dashboard.header.createdAt') }}
                <time>{{ plugin.formattedCreatedAt() }}</time>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  defineProps,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Plugin from '@/models/plugin/plugin';

const { t } = useI18n();

/*
  Props
*/
const props = defineProps({
  plugin: {
    type: Object as () => Plugin,
    required: true,
  },
  instagramLiveStatus: {
    type: Object,
    required: false,
    default: null,
  },
});

/*
  Data
*/
const isLoading = ref(true);

/*
  Hooks
*/
watch(
  () => props.plugin?.id,
  (newVal) => {
    if (newVal) {
      isLoading.value = false;
    }
  },
  { immediate: true },
);
</script>
