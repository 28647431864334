/* eslint-disable camelcase */
class InstagramPluginModerator {
  mediaId: string;

  email: string;

  name: string;

  userUuid: string;

  createdAt: Date | null;

  createdByUuid: string;

  constructor({
    media_id,
    email,
    name,
    user_uuid,
    created_at,
    created_by_uuid,
  }: {
    media_id: string;
    email: string;
    name: string;
    user_uuid: string;
    created_at: Date | null;
    created_by_uuid: string;
  }) {
    this.mediaId = media_id;
    this.email = email;
    this.name = name;
    this.userUuid = user_uuid;
    this.createdAt = created_at;
    this.createdByUuid = created_by_uuid;
  }
}

export default InstagramPluginModerator;
