import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = {
  key: 0,
  ref: "topMenu",
  class: "navbar is-fixed-top is-white has-background-white",
  role: "navigation",
  "aria-label": "main navigation"
}
const _hoisted_2 = { class: "navbar-brand is-flex is-justify-content-space-between" }
const _hoisted_3 = { class: "navbar-item p-0" }
const _hoisted_4 = { class: "is-size-5" }
const _hoisted_5 = { class: "navbar-item is-hidden-desktop p-0" }
const _hoisted_6 = { class: "navbar-menu" }
const _hoisted_7 = { class: "navbar-end" }
const _hoisted_8 = { class: "navbar-item p-0" }


import NavigateBackMenu from '@/components/menu/NavigateBackMenu.vue';

/*
  Props
*/

export default /*@__PURE__*/_defineComponent({
  __name: 'PageHeader',
  props: {
  title: {
    type: String,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  to: {
    type: String,
    required: false,
    default: 'DashboardPage',
  },
  toUrl: {
    type: String,
    required: false,
    default: 'DashboardPage',
  },
  params: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  query: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  text: {
    type: String,
    required: false,
    default: '',
  },
  icon: {
    type: String,
    required: false,
    default: 'close',
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (!props.isLoading)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_RouterLink, {
              to: { name: 'DashboardPage' },
              class: "navbar-item",
              "aria-label": "navbar main logo"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("figure", { class: "image is-flex is-align-items-center" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "logo",
                    style: {"width":"29px"}
                  })
                ], -1)
              ])),
              _: 1
            }),
            _createElementVNode("p", _hoisted_4, _toDisplayString(props.title), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "content"),
            (!_ctx.$slots.content)
              ? (_openBlock(), _createBlock(NavigateBackMenu, {
                  key: 0,
                  class: "navbar-item has-text-right is-transparent",
                  icon: props.icon,
                  to: props.to,
                  params: props.params,
                  query: props.query,
                  text: props.text
                }, null, 8, ["icon", "to", "params", "query", "text"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _renderSlot(_ctx.$slots, "content"),
              (!_ctx.$slots.content)
                ? (_openBlock(), _createBlock(NavigateBackMenu, {
                    key: 0,
                    class: "navbar-item has-text-right is-transparent",
                    icon: props.icon,
                    to: props.to,
                    params: props.params,
                    query: props.query,
                    text: props.text
                  }, null, 8, ["icon", "to", "params", "query", "text"]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ], 512))
    : _createCommentVNode("", true)
}
}

})