import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fixed inset-0 overflow-hidden" }
const _hoisted_2 = { class: "absolute inset-0 overflow-hidden" }
const _hoisted_3 = { class: "pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10" }
const _hoisted_4 = { class: "absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4" }
const _hoisted_5 = { class: "flex h-full flex-col overflow-y-scroll bg-white shadow-xl" }
const _hoisted_6 = { class: "relative flex-1" }

import {
  Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';



export default /*@__PURE__*/_defineComponent({
  __name: 'RightSideDrawer',
  props: {
  show: { type: Boolean, required: true }, // Controls the visibility of the drawer
  bgColor: { type: String, default: 'bg-teal-100' }, // Background color for header
  onClose: { type: Function, default: null }, // Custom close method
},
  emits: ['update:show'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

// Handle close logic
const handleClose = () => {
  if (props.onClose) {
    props.onClose(); // Call custom close function
  } else {
    emit('update:show', false); // Default close action
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TransitionRoot), {
    as: "template",
    show: props.show
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Dialog), {
        class: "relative z-60",
        onClose: handleClose
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(TransitionChild), {
            as: "template",
            enter: "ease-in-out duration-500",
            "enter-from": "opacity-0",
            "enter-to": "opacity-100",
            leave: "ease-in-out duration-500",
            "leave-from": "opacity-100",
            "leave-to": "opacity-0"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "fixed inset-0 bg-gray-500/75 transition-opacity" }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(TransitionChild), {
                  as: "template",
                  enter: "transform transition ease-in-out duration-500 sm:duration-700",
                  "enter-from": "translate-x-full",
                  "enter-to": "translate-x-0",
                  leave: "transform transition ease-in-out duration-500 sm:duration-700",
                  "leave-from": "translate-x-0",
                  "leave-to": "translate-x-full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(DialogPanel), { class: "pointer-events-auto relative w-screen max-w-md" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(TransitionChild), {
                          as: "template",
                          enter: "ease-in-out duration-500",
                          "enter-from": "opacity-0",
                          "enter-to": "opacity-100",
                          leave: "ease-in-out duration-500",
                          "leave-from": "opacity-100",
                          "leave-to": "opacity-0"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_4, [
                              _createElementVNode("button", {
                                type: "button",
                                class: "relative rounded-md text-gray-300 hover:text-white focus:ring-2 focus:ring-white focus:outline-hidden",
                                onClick: handleClose
                              }, [
                                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "absolute -inset-2.5" }, null, -1)),
                                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "sr-only" }, "Close panel", -1)),
                                _createVNode(_unref(XMarkIcon), {
                                  class: "size-6",
                                  "aria-hidden": "true"
                                })
                              ])
                            ])
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", {
                            class: _normalizeClass([__props.bgColor])
                          }, [
                            _renderSlot(_ctx.$slots, "header", {}, () => [
                              _createVNode(_unref(DialogTitle), { class: "text-base font-semibold text-gray-900" }, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createTextVNode(" Default Title ")
                                ])),
                                _: 1
                              }),
                              _cache[4] || (_cache[4] = _createElementVNode("p", { class: "mt-1 text-sm text-gray-900" }, " Default Description ", -1))
                            ])
                          ], 2),
                          _createElementVNode("div", _hoisted_6, [
                            _renderSlot(_ctx.$slots, "content", {}, () => [
                              _cache[5] || (_cache[5] = _createTextVNode(" No content provided. "))
                            ])
                          ])
                        ])
                      ]),
                      _: 3
                    })
                  ]),
                  _: 3
                })
              ])
            ])
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["show"]))
}
}

})