import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto min-h-full" }

import {
  ref,
  onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import SimpleHeader from '@/components/tailwind/header/SimpleHeader.vue';
import { useI18n } from 'vue-i18n';
import SimpleFooter from '@/components/tailwind/footer/SimpleFooter.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PluginLivesSharedWithMeLayout',
  setup(__props) {

const router = useRouter();
const { t } = useI18n();

const handleClose = () => {
  router.push({
    name: 'DashboardPage',
  });
};

/*
  Data
*/
const dataLoaded = ref(false);

/*
  Methods
*/
// const deletePlugin = async (plugin: Plugin) => {
//   await pluginsDoDelete(plugin.id)
//     .then(() => {
//       notify({
//         type: 'success',
//         title: 'Sucesso',
//         text: 'Plugin removido com sucesso',
//       });
//       plugins.value = plugins.value.filter((p) => p.id !== plugin.id);
//     }).catch((error) => {
//       console.error('error', error);
//       notify({
//         type: 'error',
//         title: 'Erro',
//         text: 'Não foi possível remover o plugin',
//       });
//     });
// };

/*
  Hooks
*/
onMounted(() => {
  setTimeout(() => {
    dataLoaded.value = true;
  }, 500);
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(SimpleHeader, {
        "on-close": handleClose,
        pages: [
        { name: _unref(t)('sidemenu.navigation.shared-report.title'), href: _unref(router).resolve({ name: 'PluginLivesSharedWithMePage' }).href, current: true },
      ]
      }, null, 8, ["pages"]),
      _createVNode(_component_RouterView)
    ]),
    _createVNode(SimpleFooter, { horizontal: true })
  ], 64))
}
}

})