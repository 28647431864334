<!-- eslint-disable max-len -->
<template>
  <Disclosure
    v-slot="{ open }"
    as="nav"
    class="left-0 w-full z-50 shadow-xs"
  >
    <!-- class="left-0 w-full z-50 fixed bg-gray-50 shadow-xs" -->
    <!-- Free test banner -->
    <Transition
      enter-active-class="transition-opacity duration-500"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="showTopBanner"
        class="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1"
      >
        <div
          class="absolute top-1/2 left-[max(-7rem,calc(50%-52rem))] -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div
            class="aspect-577/310 w-[36.0625rem] bg-linear-to-r from-[#ff80b5] to-teal-500 opacity-30"
            style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"
          />
        </div>
        <div
          class="absolute top-1/2 left-[max(45rem,calc(50%+8rem))] -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div
            class="aspect-577/310 w-[36.0625rem] bg-linear-to-r from-[#ff80b5] to-teal-500 opacity-30"
            style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"
          />
        </div>
        <div class="flex flex-wrap items-center gap-x-4 gap-y-2">
          <p class="text-sm/6 text-gray-900">
            <strong class="font-semibold">
              {{ t('header.banner.free_trial.title') }}
            </strong><svg
              viewBox="0 0 2 2"
              class="mx-2 inline size-0.5 fill-current"
              aria-hidden="true"
            />
            {{ t('header.banner.free_trial.description') }}
          </p>
          <a
            href="#"
            class="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-xs hover:bg-gray-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
            @click.prevent="navigateWhatsapp('Olá, gostaria de testar a plataforma.')"
          >
            {{ t('header.banner.free_trial.cta') }}
            <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
        <div class="flex flex-1 justify-end">
          <button
            type="button"
            class="-m-3 p-3 focus-visible:outline-offset-[-4px]"
            @click.prevent="tempDisableTopBanner"
          >
            <span class="sr-only">Dismiss</span>
            <XMarkIcon
              class="size-5 text-gray-900"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </Transition>

    <!-- Actual header -->
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 justify-between">
        <div class="flex">
          <div class="mr-2 -ml-2 flex items-center md:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500 focus:outline-hidden focus:ring-inset">
              <span class="absolute -inset-0.5" />
              <span class="sr-only">Open main menu</span>
              <Bars3Icon
                v-if="!open"
                class="block size-6"
                aria-hidden="true"
              />
              <XMarkIcon
                v-else
                class="block size-6"
                aria-hidden="true"
              />
            </DisclosureButton>
          </div>
          <div class="flex shrink-0 items-center">
            <img
              class="h-8 w-auto inline"
              src="@/assets/logo2.png"
              alt="Live Link"
            >
          </div>
          <div class="hidden md:ml-6 md:flex md:space-x-8">
            <!-- <a
              href="#"
              class="inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900"
            > -->
            <!-- <a
              href="#"
              class="inline-flex items-center px-1 pt-1 text-sm font-medium "
            >
              <span class="shadow inline-flex items-center rounded-full bg-gray-50 px-4 py-2 text-base font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">
                <SparklesIcon
                  class="size-6 mr-2 fill-teal-500"
                  aria-hidden="true"
                />
                Tenha seu primeiro relatório grátis
              </span>
            </a> -->
            <!-- <a
              href="#"
              class="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >Team</a>
            <a
              href="#"
              class="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >Projects</a>
            <a
              href="#"
              class="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >Calendar</a> -->
          </div>
        </div>
        <div class="flex items-center gap-x-4">
          <!-- Notification Button -->
          <!-- <button
            type="button"
            class="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
          >
            <span class="absolute -inset-1.5" />
            <span class="sr-only">View notifications</span>
            <GlobeAmericasIcon
              class="size-6"
              aria-hidden="true"
            />
          </button> -->

          <!-- Signup Button -->
          <button
            type="button"
            class="relative hidden md:inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold border-2 border-transparent hover:border-teal-500 hover:text-teal-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 transition-all duration-200"
            @click.prevent="navigateSignup"
          >
            <PlusIcon
              class="-ml-0.5 size-5"
              aria-hidden="true"
            />
            {{ t('header.cta.signup') }}
          </button>

          <!-- Login Button -->
          <button
            type="button"
            class="relative inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold border-2 border-transparent hover:border-teal-500 hover:text-teal-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 transition-all duration-200"
            @click.prevent="navigateLogin"
          >
            {{ t('header.cta.login') }}
            <ArrowRightEndOnRectangleIcon
              class="-ml-0.5 size-5"
              aria-hidden="true"
            />
          </button>

          <!-- Profile Dropdown -->
          <Menu
            as="div"
            class="relative hidden"
          >
            <div>
              <MenuButton class="relative flex rounded-full bg-white text-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="absolute -inset-1.5" />
                <span class="sr-only">Open user menu</span>
                <img
                  class="size-8 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                >
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-hidden">
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                  >Your Profile</a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                  >Settings</a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                  >Sign out</a>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <DisclosurePanel class="md:hidden">
      <div class="border-t border-gray-200 pt-4 pb-3">
        <div class="flex items-center px-4 sm:px-6" />
        <div class="mt-3 space-y-1">
          <DisclosureButton
            as="a"
            :href="signupLink"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
          >
            {{ t('home.cta.cta') }}
          </DisclosureButton>

          <div
            class="inset-0 flex items-center"
            aria-hidden="true"
          >
            <div class="w-full border-t border-gray-300" />
          </div>

          <DisclosureButton
            as="a"
            :href="linkTermsAndConditionsPageShoppers"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
          >
            {{ t('footer.links.terms.shoppers.name') }}
          </DisclosureButton>
          <DisclosureButton
            as="a"
            :href="linkTermsAndConditionsPage"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
          >
            {{ t('footer.links.terms.partners.name') }}
          </DisclosureButton>
          <DisclosureButton
            as="a"
            :href="linkPrivacyPage"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
          >
            {{ t('footer.links.terms.privacy.name') }}
          </DisclosureButton>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import {
  Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';
import {
  Bars3Icon, XMarkIcon, ArrowRightEndOnRectangleIcon
  ,
} from '@heroicons/vue/24/outline';
import { PlusIcon } from '@heroicons/vue/20/solid';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const router = useRouter();

/*
  Data
*/
const showTopBanner = ref(true);

/*
  Computed
*/
const linkTermsAndConditionsPageShoppers = computed(() => router.resolve({ name: 'TermsAndConditionsPageShoppers' }).href);
const linkTermsAndConditionsPage = computed(() => router.resolve({ name: 'TermsAndConditionsPage' }).href);
const linkPrivacyPage = computed(() => router.resolve({ name: 'PrivacyPage' }).href);
const signupLink = computed(() => router.resolve({ name: 'SessionSignupPage' }).href);

/*
  Methods
*/
const tempDisableTopBanner = () => {
  showTopBanner.value = false;
};
const navigateWhatsapp = (message: string) => {
  window.open(`https://wa.me/+5511994991786?text=${message}`, '_blank');
};
const navigateSignup = () => {
  router.push({ name: 'SessionSignupPage' });
};
const navigateLogin = () => {
  router.push({ name: 'SessionLoginPage' });
};
</script>
