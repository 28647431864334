// import 'bulma/css/bulma.min.css';
import money from 'v-money3';
import i18n from '@/plugins/i18n';
import * as Sentry from '@sentry/vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import { QuillEditor } from '@vueup/vue-quill';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faEye,
  faFileVideo,
  faExclamationTriangle,
  faEyeSlash,
  faUserSecret,
  faLink,
  faSearchPlus,
  faSearch,
  faPaintBrush,
  faQuestion,
  faQuestionCircle,
  faAlignLeft,
  faExternalLink,
  faToggleOn,
  faToggleOff,
  faExchange,
  faExclamationCircle,
  faFilm,
  faCalendar,
  faChevronDown,
  faCloudDownload,
  faChevronRight,
  faChevronUp,
  faGlobe,
  faClose,
  faPowerOff,
  faCreditCard,
  faHeart,
  faShoppingCart,
  faUser,
  faVideoCamera,
  faVideoSlash,
  faPlus,
  faMinus,
  faArrowLeft,
  faArrowRight,
  faCogs,
  faRefresh,
  faRocket,
  faComments,
  faTags,
  faInfoCircle,
  faLineChart,
  faUpRightFromSquare,
  faHome,
  faBriefcase,
  faPencil,
  faTrash,
  faBuilding,
  faImage,
  faCalendarPlus,
  faShareAlt,
  faShareAltSquare,
  faClock,
  faClipboard,
  faClipboardList,
  faCheckCircle,
  faReply,
  faQuoteLeft,
  faTimesCircle,
  faCheck,
  faVolumeUp,
  faGear,
  faMicrophone,
  faMicrophoneSlash,
  faCircle,
  faShoppingBag,
  faImagePortrait,
  faPaperPlane,
  faChartLine,
  faList,
  faUsers,
  faUserCircle,
  faSignOut,
  faEnvelope,
  faMapMarkerAlt,
  faBook,
  faBell,
  faFileInvoiceDollar,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faWhatsapp,
  faTwitter,
  faInstagram,
  faYoutube,
  faSpotify,
  faLinkedin,
  faTiktok,
  faTelegram,
  faBehance,
  faTwitch,
} from '@fortawesome/free-brands-svg-icons';
import Notifications from '@kyvg/vue3-notification';
import { createPinia } from 'pinia';
import { createApp, watch } from 'vue';
import App from './App.vue';
import router from './router';

library.add(
  faEye,
  faFileVideo,
  faExclamationTriangle,
  faEyeSlash,
  faUserSecret,
  faLink,
  faSearchPlus,
  faSearch,
  faPaintBrush,
  faQuestion,
  faQuestionCircle,
  faAlignLeft,
  faExternalLink,
  faToggleOn,
  faToggleOff,
  faExchange,
  faExclamationCircle,
  faFilm,
  faCalendar,
  faChevronDown,
  faCloudDownload,
  faChevronRight,
  faChevronUp,
  faGlobe,
  faClose,
  faPowerOff,
  faCreditCard,
  faHeart,
  faShoppingCart,
  faUser,
  faVideoCamera,
  faVideoSlash,
  faPlus,
  faMinus,
  faArrowLeft,
  faArrowRight,
  faCogs,
  faRefresh,
  faRocket,
  faComments,
  faTags,
  faInfoCircle,
  faLineChart,
  faUpRightFromSquare,
  faHome,
  faBriefcase,
  faPencil,
  faTrash,
  faBuilding,
  faImage,
  faCalendarPlus,
  faShareAlt,
  faShareAltSquare,
  faClock,
  faClipboard,
  faClipboardList,
  faCheckCircle,
  faReply,
  faQuoteLeft,
  faTimesCircle,
  faCheck,
  faVolumeUp,
  faGear,
  faMicrophone,
  faMicrophoneSlash,
  faCircle,
  faShoppingBag,
  faImagePortrait,
  faPaperPlane,
  faChartLine,
  faList,
  faUsers,
  faUserCircle,
  faSignOut,
  faEnvelope,
  faMapMarkerAlt,
  faBook,
  faBell,
  faFileInvoiceDollar,
  faAngleLeft,
  faAngleRight,

  faFacebook,
  faWhatsapp,
  faTwitter,
  faInstagram,
  faYoutube,
  faSpotify,
  faLinkedin,
  faTiktok,
  faTelegram,
  faBehance,
  faTwitch,
);

const app = createApp(App);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control
      // for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/livelink\.com.br/],
      // tracePropagationTargets: ['localhost', /^https:\/\/livelink\.com.br\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 20% of transactions for production
  // Session Replay
  // This sets the sample rate at 10%. You may want to change
  // it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.05, // Reduce if you're hitting limits
  // If you're not already sampling the entire session,
  // change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 0.25, // Record fewer error sessions
});

app
  .component('QuillEditor', QuillEditor)
  .component('FontAwesomeIcon', FontAwesomeIcon);

const pinia = createPinia(); // Create the Pinia instance
app
  .use(pinia)
  .use(i18n)
  .use(router)
  .use(money)
  .use(Notifications)
  .use(Toast, {
    transition: 'Vue-Toastification__slideBlurred',
    maxToasts: 5,
    newestOnTop: true,
    position: 'top-right', // Default position
    timeout: 5000, // 5 seconds
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    rtl: false,
  })
  .mount('#app');

// Watch for route changes and dynamically load Tailwind
watch(
  () => router.currentRoute.value,
  (to) => {
    if (to.meta.useTailwind) {
      document.getElementById('bulma-styles')?.remove(); // Remove Bulma if exists
      if (!document.getElementById('tailwind-styles')) {
        const link = document.createElement('link');
        link.id = 'tailwind-styles';
        link.rel = 'stylesheet';
        link.href = '/tailwind.css';
        document.head.appendChild(link);
      }
    } else {
      document.getElementById('tailwind-styles')?.remove(); // Remove Tailwind if exists
      if (!document.getElementById('bulma-styles')) {
        const link = document.createElement('link');
        link.id = 'bulma-styles';
        link.rel = 'stylesheet';
        link.href = 'https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css';
        document.head.appendChild(link);
      }
    }
  },
  { immediate: true }, // Run on initial load
);
