import { RouteRecordRaw } from 'vue-router';
import NoHeaderLayout from '@/components/layout/NoHeaderLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/session/login',
    name: 'SessionLoginPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: false,
      useTailwind: true,
    },
    component: () => import(/* webpackChunkName: "session" */ '@/views/tailwind/session/LoginView.vue'),
  },
  {
    path: '/session/logout',
    name: 'SessionLogoutPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: false,
      useTailwind: true,
    },
    component: () => import(/* webpackChunkName: "session" */ '@/views/tailwind/session/LogoutView.vue'),
  },
  {
    path: '/session/refresh',
    name: 'SessionLoginRefreshPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
      useTailwind: true,
    },
    component: () => import(/* webpackChunkName: "session" */ '@/views/tailwind/session/LoginRefreshView.vue'),
  },
  {
    path: '/session/signup',
    name: 'SessionSignupPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: false,
      useTailwind: true,
    },
    component: () => import(/* webpackChunkName: "session" */ '@/views/tailwind/session/SignupView.vue'),
  },
  {
    path: '/session/password/reset',
    name: 'SessionPasswordResetPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
      useTailwind: true,
    },
    component: () => import(/* webpackChunkName: "session" */ '@/views/tailwind/session/PasswordResetView.vue'),
  },
  {
    path: '/session/recover',
    name: 'SessionRecoverPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: false,
      useTailwind: true,
    },
    component: () => import(/* webpackChunkName: "session" */ '@/views/tailwind/session/PasswordRecoverView.vue'),
  },
  {
    path: '/session/temporary/password',
    name: 'SessionTemporaryPasswordPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: false,
      useTailwind: true,
    },
    component: () => import(/* webpackChunkName: "session" */ '@/views/tailwind/session/TempPasswordSent.vue'),
  },
];

export default routes;
