import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import useAxios from '@/libs/axios';
import Plugin from '@/models/plugin/plugin';
import Pagination from '@/models/pagination';
import eventBus from '@/eventBus/eventBus';
import InstagramLiveMetadata from '@/models/plugin/instagramLiveMetadata';
import InstagramLiveMetadataMetrics from '@/models/plugin/instagramLiveMetadataMetrics';
import InstagramLiveMessage from '@/models/plugin/instagramLiveMessage';
import InstagramLiveUser from '@/models/plugin/instagramLiveUser';
import InstagramLiveStatus from '@/models/plugin/instagramLiveStatus';
import InstagramPluginModerator from '@/models/plugin/instagramPluginModerator';

interface InstagramLiveMetadataResponse {
  data: InstagramLiveMetadata[];
  pagination: Pagination;
}

interface InstagramLiveMetadataMetricsResponse {
  data: InstagramLiveMetadataMetrics[];
}

interface InstagramLiveMessageResponse {
  data: InstagramLiveMessage[];
  pagination: Pagination;
  missingPayment: boolean;
}

interface InstagramLiveUserResponse {
  data: InstagramLiveUser[];
  pagination: Pagination;
}

interface InstagramLiveStatusResponse {
  data: InstagramLiveStatus[];
  pagination: Pagination;
}

export default defineStore('pluginStore', () => {
  const axios = useAxios();

  /*
    State
  */
  const pluginStore = ref({
    csrf: '',
    plugins: {
      data: [] as Plugin[],
      pagination: {} as Pagination,
    },
  });

  /*
    Actions
  */
  // Reset the whole store and remove it from localStorage
  // after user logout
  const resetStore = () => {
    localStorage.removeItem('pluginStore');
  };

  const pluginLiveMessageReply = async (params: {
    pluginId: string,
    pluginType: string,
    commentId: string,
    commentAnswer: string,
  }): Promise<boolean> => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      plugin_id: params.pluginId,
      plugin_type: params.pluginType,
      comment_id: params.commentId,
      comment_answer: params.commentAnswer,
    };

    try {
      const response = await axios.post('/plugins/live/messages/reply', payload, config);

      if (response.status !== 200) { // No content
        throw new Error('Erro ao responder mensagem. Recarregue a página e tente novamente.');
      }
      return true;
    } catch (error: any) {
      if (error.response && error.response.status !== 401) {
        throw error;
      } else if (error.message) {
        throw new Error(`Erro na solicitação: ${error.message}`);
      } else {
        throw new Error('Erro desconhecido ao atualizar live metadata.');
      }
    }
  };

  const pluginAccountLiveStatus = async (params: {
    pluginId: string,
    pluginType: string,
  }): Promise<InstagramLiveStatusResponse> => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      plugin_id: params.pluginId,
      plugin_type: params.pluginType,
    };

    try {
      const response = await axios.post('/plugins/live/status', payload, config);

      if (response.status === 204) { // No content
        return {
          data: [] as InstagramLiveStatus[],
          pagination: {} as Pagination,
        };
      }

      const { columns, rows, pagination } = response.data;

      if (!columns || !rows) {
        return {
          data: [] as InstagramLiveStatus[],
          pagination: {} as Pagination,
        };
      }

      return {
        data: InstagramLiveStatus.fromApiResponse(columns, rows),
        pagination,
      };
    } catch (error: any) {
      console.error('Error from API call:', error);
      if (error.response && error.response.status !== 401) {
        throw error;
      } else if (error.message) {
        throw new Error(`Erro na solicitação: ${error.message}`);
      } else {
        throw new Error('Erro desconhecido ao atualizar live metadata.');
      }
    }
  };

  const pluginRefreshLiveMetadata = async (params: {
    liveUserId: string,
    liveId: string,
    type: string,
  }): Promise<boolean> => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      live_user_id: params.liveUserId,
      live_id: params.liveId,
      type: params.type,
    };

    try {
      const response = await axios.put('/plugins/live-metadata', payload, config);
      return response.status === 200;
    } catch (error: any) {
      if (error.response && error.response.status !== 401) {
        throw error;
      } else if (error.message) {
        throw new Error(`Erro na solicitação: ${error.message}`);
      } else {
        throw new Error('Erro desconhecido ao atualizar live metadata.');
      }
    }
  };

  const pluginGetLiveUsers = async (params: {
    pluginId: string,
    pluginType: string,
    mediaId: string,
    page: number,
    limit: number,
    orderBy: string,
    order: number,
    searchParam: string,
  }): Promise<InstagramLiveUserResponse> => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      plugin_id: params.pluginId,
      plugin_type: params.pluginType,
      media_id: params.mediaId,
      page: params.page,
      limit: params.limit,
      order_by: params.orderBy,
      order: params.order,
      search_param: params.searchParam,
    };

    try {
      const response = await axios.post('/plugins/live/users', payload, config);
      // console.debug('response', response);
      if (response.status === 204) { // No content
        return {
          data: [] as InstagramLiveUser[],
          pagination: {} as Pagination,
        };
      }

      if (response.status !== 200) {
        throw new Error(`Erro ao buscar usuários: ${response.statusText}`);
      }

      const {
        columns, rows, pagination: pageObj,
      } = response.data;

      const pagination = new Pagination({
        total: pageObj.total,
        perPage: pageObj.per_page,
        currentPage: pageObj.current_page,
        lastPage: pageObj.last_page,
      });

      return {
        data: InstagramLiveUser.fromApiResponse(columns, rows),
        pagination,
      };
    } catch (error: any) {
      if (error.response && error.response.status !== 401) {
        throw error;
      } else if (error.message) {
        throw new Error(`Erro na solicitação: ${error.message}`);
      } else {
        throw new Error('Erro desconhecido ao buscar metadados.');
      }
    }
  };

  const pluginGetLiveMessages = async (params: {
    pluginId: string,
    pluginType: string,
    mediaId: string,
    page: number,
    limit: number,
    orderBy: string,
    order: number,
    searchParam: string,
  }): Promise<InstagramLiveMessageResponse> => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      plugin_id: params.pluginId,
      plugin_type: params.pluginType,
      media_id: params.mediaId,
      page: params.page,
      limit: params.limit,
      order_by: params.orderBy,
      order: params.order,
      search_param: params.searchParam,
    };

    try {
      const response = await axios.post('/plugins/live/messages', payload, config);
      // console.debug('response', response);
      if (response.status === 204) { // No content
        return {
          data: [] as InstagramLiveMessage[],
          pagination: {} as Pagination,
          missingPayment: true,
        };
      }

      if (response.status !== 200) {
        throw new Error(`Erro ao buscar mensages: ${response.statusText}`);
      }

      const {
        columns, rows, pagination: pageObj, missingPayment,
      } = response.data;

      const pagination = new Pagination({
        total: pageObj.total,
        perPage: pageObj.per_page,
        currentPage: pageObj.current_page,
        lastPage: pageObj.last_page,
      });

      return {
        data: InstagramLiveMessage.fromApiResponse(columns, rows),
        pagination,
        missingPayment,
      };
    } catch (error: any) {
      if (error.response && error.response.status !== 401) {
        throw error;
      } else if (error.message) {
        throw new Error(`Erro na solicitação: ${error.message}`);
      } else {
        throw new Error('Erro desconhecido ao buscar metadados.');
      }
    }
  };

  const pluginGetSharedWithMeLiveMetadatas = async (params: {
    page: number,
    limit: number,
    orderBy: string,
    order: number,
  }): Promise<InstagramLiveMetadataResponse> => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      page: params.page,
      limit: params.limit,
      order_by: params.orderBy,
      order: params.order,
    };

    try {
      const response = await axios.post('/plugins/live/shared/with-me', payload, config);
      // console.debug('response', response);
      if (response.status === 204) { // No content
        return {
          data: [] as InstagramLiveMetadata[],
          pagination: {} as Pagination,
        };
      }

      if (response.status !== 200) {
        throw new Error(`Erro ao buscar metadados: ${response.statusText}`);
      }

      const { columns, rows, pagination } = response.data;

      return {
        data: InstagramLiveMetadata.fromApiResponse(columns, rows),
        pagination,
      };
    } catch (error: any) {
      if (error.response && error.response.status !== 401) {
        throw error;
      } else if (error.message) {
        throw new Error(`Erro na solicitação: ${error.message}`);
      } else {
        throw new Error('Erro desconhecido ao buscar metadados.');
      }
    }
  };

  const pluginGetLiveMetadatasMetrics = async (params: {
    pluginId?: string,
    pluginType?: string,
  }): Promise<InstagramLiveMetadataMetricsResponse> => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    let payload = {};

    if (params.pluginId && params.pluginType) {
      payload = {
        plugin_id: params.pluginId,
        plugin_type: params.pluginType,
      };
    }

    try {
      const response = await axios.post('/plugins/live/metadata/metrics', payload, config);
      // console.debug('response', response);
      if (response.status === 204) { // No content
        return {
          data: InstagramLiveMetadataMetrics.fromApiResponse([], []),
        };
      }

      if (response.status !== 200) {
        throw new Error(`Erro ao buscar metadados: ${response.statusText}`);
      }

      const { columns, row } = response.data;

      return { data: InstagramLiveMetadataMetrics.fromApiResponse(columns, [row]) };
    } catch (error: any) {
      if (error.response && error.response.status !== 401) {
        throw error;
      } else if (error.message) {
        throw new Error(`Erro na solicitação: ${error.message}`);
      } else {
        throw new Error('Erro desconhecido ao buscar metadados.');
      }
    }
  };

  const pluginGetLiveMetadatas = async (params: {
    pluginId: string,
    pluginType: string,
    page: number,
    limit: number,
    orderBy: string,
    order: number,
    mediaId?: string,
    moderator?: boolean,
  }): Promise<InstagramLiveMetadataResponse> => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const data = {
      plugin_id: params.pluginId,
      plugin_type: params.pluginType,
      page: params.page,
      limit: params.limit,
      order_by: params.orderBy,
      order: params.order,
    };

    let payload = {};

    if (params.moderator) {
      payload = {
        ...data,
        moderator: params.moderator,
      };
    }

    if (params.mediaId) {
      payload = {
        ...data,
        media_id: params.mediaId,
      };
    } else {
      payload = data;
    }

    try {
      const response = await axios.post('/plugins/live/metadata', payload, config);
      // console.debug('response', response);
      if (response.status === 204) { // No content
        return {
          data: [] as InstagramLiveMetadata[],
          pagination: {} as Pagination,
        };
      }

      if (response.status !== 200) {
        throw new Error(`Erro ao buscar metadados: ${response.statusText}`);
      }

      const { columns, rows, pagination } = response.data;

      return {
        data: InstagramLiveMetadata.fromApiResponse(columns, rows),
        pagination: new Pagination({
          total: pagination.total,
          perPage: pagination.per_page,
          currentPage: pagination.current_page,
          lastPage: pagination.last_page,
        }),
      };
    } catch (error: any) {
      if (error.response && error.response.status !== 401) {
        throw error;
      } else if (error.message) {
        throw new Error(`Erro na solicitação: ${error.message}`);
      } else {
        throw new Error('Erro desconhecido ao buscar metadados.');
      }
    }
  };

  const pluginsDoUpdateModerators = async (params: {
    mediaId: string,
    moderatorsToAdd: InstagramPluginModerator[] | null,
    emailsToRemove: string[] | null,
  }) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    if (!params.moderatorsToAdd && !params.emailsToRemove) {
      throw new Error('Nenhum moderador para adicionar ou remover.');
    }

    const payload = {
      media_id: params.mediaId,
      add_moderators: params.moderatorsToAdd,
      remove_moderators_emails: params.emailsToRemove,
    };

    return axios.post('/plugins/live/moderators/update', payload, config)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Erro ao atualizar moderadores: ${response.statusText}`);
        }
        return true;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const pluginsDoGetModerators = async (pluginId: string, mediaId: string) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const queryParams = `?plugin_id=${pluginId}&media_id=${mediaId}`;

    return axios.get(`/plugins/my${queryParams}`, config)
      .then((response) => {
        if (response.status === 204) { // No content
          pluginStore.value.plugins.data = [] as Plugin[];
          pluginStore.value.plugins.pagination = {} as Pagination;
          return pluginStore.value.plugins;
        }

        if (response.status !== 200) {
          throw new Error(`Erro ao buscar plugins: ${response.statusText}`);
        }

        const { columns, rows, pagination } = response.data;
        const plugins = Plugin.fromApiResponse(columns, rows);

        if (plugins.length === 0) {
          return pluginStore.value.plugins;
        }

        pluginStore.value.plugins.data = plugins;
        pluginStore.value.plugins.pagination = pagination;
        return pluginStore.value.plugins;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const pluginsDoGetMy = async (pluginId?: string, mediaId?: string) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const queryParams = [];
    queryParams.push(pluginId ? `plugin_id=${pluginId}` : '');
    queryParams.push(mediaId ? `media_id=${mediaId}` : '');

    const queryParamsString = queryParams.filter((param) => param.length > 0).join('&');

    return axios.get(`/plugins/my?${queryParamsString}`, config)
      .then((response) => {
        if (response.status === 204) { // No content
          pluginStore.value.plugins.data = [] as Plugin[];
          pluginStore.value.plugins.pagination = {} as Pagination;
          return pluginStore.value.plugins;
        }

        if (response.status !== 200) {
          throw new Error(`Erro ao buscar plugins: ${response.statusText}`);
        }

        const { columns, rows, pagination } = response.data;
        const plugins = Plugin.fromApiResponse(columns, rows);

        if (plugins.length === 0) {
          return pluginStore.value.plugins;
        }

        pluginStore.value.plugins.data = plugins;
        pluginStore.value.plugins.pagination = pagination;
        return pluginStore.value.plugins;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const pluginsDoDelete = async (id: string) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };
    const payload = {
      id,
    };

    return axios.post('/plugins/delete', payload, config)
      .then((response) => {
        if (response.status !== 200) {
          return undefined;
        }
        return true;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const pluginsDoCreate = async (plugin: Plugin) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };
    const payload = {
      name: plugin.name,
      type: plugin.type,
      redirect_uri: plugin.redirectURI,
      version: plugin.version,
      token: plugin.token,
      owner_uuid: plugin.ownerUuid,
      admins_uuid: plugin.adminsUuid,
      shopify_oauth: plugin.shopifyOauth ? {
        code: plugin.shopifyOauth.code,
        hmac: plugin.shopifyOauth.hmac,
        host: plugin.shopifyOauth.host,
        shop: plugin.shopifyOauth.shop,
        state: plugin.shopifyOauth.state,
        timestamp: plugin.shopifyOauth.timestamp,
      } : null,
    };

    return axios.post('/plugins/add', payload, config)
      .then((response) => {
        if (response.status !== 200) {
          return undefined;
        }
        return true;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const pluginsProcessInstagramToken = async (
    provider: string,
    code: string,
    scope: string,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      provider,
      code,
      scope,
    };

    return axios.post(
      '/plugins/oauth/exchange',
      payload,
      config,
    ).then(
      (response) => response,
    ).catch((error) => {
      console.error('error', error);
      throw error;
    });
  };

  const pluginsProcessManualInstagramToken = async (
    provider: string,
    code: string,
    scope: string,
    account: string,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      provider,
      code,
      scope,
      account,
    };

    return axios.post(
      '/plugins/oauth/exchange',
      payload,
      config,
    ).then(
      (response) => response,
    ).catch((error) => {
      console.error('error', error);
      throw error;
    });
  };

  /*
    Hooks
  */
  // Listen to logout event
  // This event is used to clear the store
  eventBus.on('event:logout', resetStore);

  // Load store from localStorage
  // This is necessary to keep the store
  // when user refresh the page
  if (localStorage.getItem('pluginStore')) {
    pluginStore.value = JSON.parse(localStorage.getItem('pluginStore') || '{}');
  }

  watch(pluginStore, (pluginsVal) => {
    localStorage.setItem('pluginStore', JSON.stringify(pluginsVal));
  }, {
    deep: true,
  });

  return {
    pluginStore,
    resetStore,
    pluginsDoGetMy,
    pluginsDoGetModerators,
    pluginsDoUpdateModerators,
    pluginsDoCreate,
    pluginsDoDelete,
    pluginsProcessInstagramToken,
    pluginsProcessManualInstagramToken,
    pluginGetLiveMetadatas,
    pluginGetLiveMetadatasMetrics,
    pluginGetSharedWithMeLiveMetadatas,
    pluginGetLiveMessages,
    pluginGetLiveUsers,
    pluginRefreshLiveMetadata,
    pluginAccountLiveStatus,
    pluginLiveMessageReply,
  };
});
