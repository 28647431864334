import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, isRef as _isRef, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  for: "locale",
  class: "block text-sm/6 text-gray-600",
  style: {"padding":"0"}
}
const _hoisted_2 = {
  key: 0,
  class: "hidden sm:inline mr-4"
}

import {
  ref,
  watchEffect,
} from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChooseLanguage',
  props: {
  hideLabel: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const { t, locale } = useI18n();

/*
  Props
*/
const props = __props;

/*
  Data
*/
const selectedLanguage = ref(locale.value);

/*
  Methods
*/
const changeLanguage = ($language: string) => {
  locale.value = $language;
  localStorage.setItem('user-locale', $language); // Save user preference
};

/*
  Hooks
*/
watchEffect(() => {
  const savedLocale = localStorage.getItem('user-locale') || 'pt-BR';
  locale.value = savedLocale;
  selectedLanguage.value = savedLocale;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    (!props.hideLabel)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(t)('home.hero.language')), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(locale) ? (locale).value = $event : null)),
      onChange: _cache[1] || (_cache[1] = ($event: any) => (changeLanguage(_unref(locale))))
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("option", {
        value: "en-US",
        class: "text-lg"
      }, " 🇺🇸 EN ", -1),
      _createElementVNode("option", {
        value: "pt-BR",
        class: "text-lg"
      }, " 🇧🇷 PT-BR ", -1)
    ]), 544), [
      [_vModelSelect, _unref(locale)]
    ])
  ]))
}
}

})