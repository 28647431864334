import Image from '@/models/image';

class User {
  uuid: string;

  username: string;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;

  status: string;

  language: string;

  timezone: string;

  name: string;

  birthdate: Date;

  document: string;

  documentType: string;

  email: string;

  emailConfirmedAt: Date;

  mobile: string;

  mobileConfirmedAt: Date;

  facebook: string;

  linkedin: string;

  instagram: string;

  tiktok: string;

  youtube: string;

  profileImage: Image;

  password: string;

  tempPassword: string;

  stripeCustomerId: string;

  constructor({
    uuid,
    username,
    createdAt,
    updatedAt,
    deletedAt,
    status,
    language,
    timezone,
    name,
    birthdate,
    document,
    documentType,
    email,
    emailConfirmedAt,
    mobile,
    mobileConfirmedAt,
    facebook,
    linkedin,
    instagram,
    tiktok,
    youtube,
    password,
    profileImage,
    tempPassword,
    stripeCustomerId,
  }: {
    uuid: string;
    username: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    status: string;
    language: string;
    timezone: string;
    name: string;
    birthdate: Date;
    document: string;
    documentType: string;
    email: string;
    emailConfirmedAt: Date;
    mobile: string;
    mobileConfirmedAt: Date;
    facebook: string;
    linkedin: string;
    instagram: string;
    tiktok: string;
    youtube: string;
    password: string;
    profileImage: Image;
    tempPassword: string;
    stripeCustomerId: string;
  }) {
    this.uuid = uuid;
    this.username = username;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.deletedAt = deletedAt;
    this.status = status;
    this.language = language;
    this.timezone = timezone;
    this.name = name;
    this.birthdate = birthdate;
    this.document = document;
    this.documentType = documentType;
    this.email = email;
    this.emailConfirmedAt = emailConfirmedAt;
    this.mobile = mobile;
    this.mobileConfirmedAt = mobileConfirmedAt;
    this.facebook = facebook;
    this.linkedin = linkedin;
    this.instagram = instagram;
    this.tiktok = tiktok;
    this.tiktok = tiktok;
    this.youtube = youtube;
    this.profileImage = profileImage;
    this.password = password;
    this.tempPassword = tempPassword;
    this.stripeCustomerId = stripeCustomerId;
  }

  formattedDocument(): string {
    if (!this.document) {
      return '';
    }
    if (this.documentType === 'CPF' && this.document.length === 11) {
      return this.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    if (this.documentType === 'CNPJ' && this.document.length === 11) {
      return this.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
    return this.document;
  }

  formattedEmail(maxLen?: number): string {
    if (!this.email) {
      return '';
    }
    if (maxLen) {
      return this.email?.length > maxLen
        ? `${this.email?.substring(0, maxLen)}...`
        : this.email;
    }
    return this.email;
  }

  formattedBirthdate(): string {
    if (!this.birthdate) {
      return '';
    }
    const options: Intl.DateTimeFormatOptions = {
      // weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return this.birthdate.toLocaleDateString('pt-BR', options);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): User[] {
    if (rows.length === 0) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        if (camelCaseKey === 'birthdate') {
          if (row[index]) {
            v[camelCaseKey] = new Date(row[index]);
          } else {
            v[camelCaseKey] = null;
          }
        } else if (camelCaseKey === 'profileImage') {
          if (row[index] == null || row[index].uuid === '00000000-0000-0000-0000-000000000000') {
            v[camelCaseKey] = undefined;
          } else {
            v[camelCaseKey] = new Image({
              uuid: row[index].uuid,
              cdnUrl: row[index].cdn_url,
              cdnUrlModifiers: row[index].cdn_url_modifiers,
              mimeType: row[index].mime_type,
            });
          }
        } else {
          v[camelCaseKey] = row[index];
        }
      });
      return new User(v);
    });
  }
}

export default User;
