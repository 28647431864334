<!-- eslint-disable max-len -->
<template>
  <!-- Skeleton Loader (Shown When isLoading is TRUE) -->
  <nav
    v-if="isLoading"
    class="fixed top-0 left-0 w-full z-50 shadow-md bg-gray-200 bg-opacity-70 backdrop-blur-md backdrop-opacity-90 animate-pulse"
  >
    <div class="flex items-center h-16 mx-auto max-w-screen-xl justify-between px-4">
      <!-- Breadcrumb Skeleton -->
      <div
        v-if="props.pages"
        class="h-6 w-1/3 bg-gray-300 rounded"
      />

      <!-- Close Button Skeleton -->
      <div class="h-6 w-6 bg-gray-300 rounded-full" />
    </div>
  </nav>

  <!-- Actual Header (Shown When isLoading is FALSE) -->
  <nav
    v-else
    :class="['fixed top-0 left-0 w-full z-50 shadow-md', bgColor, 'bg-opacity-70 backdrop-blur-md backdrop-opacity-90']"
  >
    <div :class="['flex items-center h-16 mx-auto max-w-screen-xl px-4', justifyClass]">
      <BreadCrumb
        v-if="props.pages"
        :pages="props.pages"
      />
      <button
        class="text-gray-900 hover:text-gray-700 p-2 rounded-md focus:ring-2 focus:ring-teal-600 focus:ring-offset-2 focus:ring-offset-teal-600 cursor-pointer"
        @click="handleClose"
      >
        <span class="sr-only">Close</span>
        <XMarkIcon
          class="size-6"
          aria-hidden="true"
        />
      </button>
    </div>
  </nav>

  <!-- Spacer to avoid content jumping when header loads -->
  <div class="h-18" />
</template>

<script setup lang="ts">
import {
  ref, onMounted, computed, defineProps, defineEmits,
} from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import BreadCrumb from '@/components/tailwind/header/BreadCrumb.vue';
import Page from '@/types/page';

const emit = defineEmits(['close']);

/*
  Props
*/
const props = defineProps({
  bgColor: { type: String, default: '' }, // Allows custom background color
  onClose: { type: Function, default: null }, // Optional custom close method
  pages: { type: Array as () => Page[], default: null }, // Array of pages for the breadcrumb
});

/*
  Computed
*/
const justifyClass = computed(() => (props.pages.length > 0 ? 'justify-between' : 'justify-end'));

/*
  Data
*/
const isLoading = ref(true);

/*
  Methods
*/
const handleClose = () => {
  if (props.onClose) {
    props.onClose(); // Call custom method
  } else {
    emit('close'); // Default behavior
  }
};

/*
  Hooks
*/
onMounted(() => {
  setTimeout(() => {
    isLoading.value = false;
  }, 100);
});
</script>
