import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "flex border-gray-700",
  "aria-label": "Breadcrumb"
}
const _hoisted_3 = { class: "mx-auto flex w-full max-w-screen-xl space-x-4 px-4" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = { class: "sr-only" }
const _hoisted_7 = { class: "flex items-center" }
const _hoisted_8 = {
  key: 1,
  class: "flex ml-4 text-sm font-medium text-gray-900",
  "aria-current": "page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.$props.pages.length > 0)
          ? (_openBlock(), _createElementBlock("nav", _hoisted_2, [
              _createElementVNode("ol", _hoisted_3, [
                _createElementVNode("li", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_router_link, {
                      to: { name: 'DashboardPage' },
                      class: "text-gray-700 hover:text-gray-900 cursor-pointer"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_HomeIcon, {
                          class: "size-5 shrink-0",
                          "aria-hidden": "true"
                        }),
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('sidemenu.navigation.dashboard.title')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$props.pages, (page, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: page.name,
                    class: _normalizeClass(['flex', {
              'hidden sm:flex': (index !== _ctx.$props.pages.length - 1) }])
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _cache[0] || (_cache[0] = _createElementVNode("svg", {
                        class: "h-full w-6 shrink-0 text-gray-200",
                        viewBox: "0 0 24 44",
                        preserveAspectRatio: "none",
                        fill: "currentColor",
                        "aria-hidden": "true"
                      }, [
                        _createElementVNode("path", { d: "M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" })
                      ], -1)),
                      (!page.current)
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            to: page.href,
                            class: _normalizeClass(["flex ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer", { 'font-bold': page.current }]),
                            "aria-current": page.current ? 'page' : undefined
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(page.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["to", "class", "aria-current"]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(page.name), 1))
                    ])
                  ], 2))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}