<!-- eslint-disable max-len -->
<template>
  <HomeHeader />

  <main
    v-if="dataLoaded"
    class="isolate relative"
  >
    <!-- Hero section -->
    <div class="relative isolate -z-10">
      <svg
        class="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
            width="200"
            height="200"
            x="50%"
            y="-1"
            patternUnits="userSpaceOnUse"
          >
            <path
              d="M.5 200V.5H200"
              fill="none"
            />
          </pattern>
        </defs>
        <svg
          x="50%"
          y="-1"
          class="overflow-visible fill-gray-50"
        >
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400
              600h201v201h-201Z M200 800h201v201h-201Z"
            stroke-width="0"
          />
        </svg>
        <rect
          width="100%"
          height="100%"
          stroke-width="0"
          fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
        />
      </svg>
      <div
        class="absolute top-0 right-0 left-1/2 -z-10 -ml-24 transform-gpu
          overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
        aria-hidden="true"
      >
        <div
          class="aspect-801/1036 w-[50.0625rem] bg-linear-to-tr from-[#ff80b5] to-[#00bba7] opacity-30"
          style="clip-path: polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)"
        />
      </div>
      <div class="overflow-hidden">
        <div class="mx-auto max-w-7xl px-6 pt-24 lg:px-8 lg:pt-16">
          <div class="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
            <div class="relative w-full lg:max-w-xl lg:shrink-0 xl:max-w-2xl">
              <span class="shadow inline-flex items-center rounded-full bg-white px-4 py-2 mb-8 text-base font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">
                <SparklesIcon
                  class="size-6 mr-2 fill-teal-500"
                  aria-hidden="true"
                />
                {{ t('home.hero.tag') }}
              </span>
              <h1 class="text-5xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-7xl">
                {{ t('home.hero.title') }}
              </h1>
              <p class="mt-8 text-lg font-medium text-pretty text-gray-500 sm:max-w-md sm:text-xl/8 lg:max-w-none">
                {{ t('home.hero.description') }}
              </p>
              <a
                class="inline-block rounded-md border border-transparent bg-teal-500 px-8 py-3 mt-8 shadow-xl text-center font-medium text-white hover:bg-teal-600 focus-visible:outline-teal-600"
                aria-label="navbar main logo"
                :href="signupLink()"
              >
                {{ t('home.hero.cta') }}
              </a>
            </div>
            <div class="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
              <div class="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                <div class="relative shadow-xl">
                  <img
                    src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                    alt=""
                    class="aspect-2/3 w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  >
                  <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-gray-900/10 ring-inset" />
                </div>
              </div>
              <div class="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                <div class="relative shadow-xl">
                  <img
                    src="https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                    alt=""
                    class="aspect-2/3 w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  >
                  <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-gray-900/10 ring-inset" />
                </div>
                <div class="relative shadow-xl">
                  <img
                    src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=396&h=528&q=80"
                    alt=""
                    class="aspect-2/3 w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  >
                  <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-gray-900/10 ring-inset" />
                </div>
              </div>
              <div class="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                <div class="relative shadow-xl">
                  <img
                    src="https://images.unsplash.com/photo-1670272504528-790c24957dda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=left&w=400&h=528&q=80"
                    alt=""
                    class="aspect-2/3 w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  >
                  <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-gray-900/10 ring-inset" />
                </div>
                <div class="relative shadow-xl">
                  <img
                    src="https://images.unsplash.com/photo-1670272505284-8faba1c31f7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                    alt=""
                    class="aspect-2/3 w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  >
                  <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-gray-900/10 ring-inset" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Stats section -->
    <div class="mx-auto mt-32 max-w-7xl px-6 lg:px-8">
      <h2 class="text-center text-lg/8 font-semibold">
        {{ t('home.stats.title') }}
      </h2>
      <dl class="mx-auto grid max-w-2xl grid-cols-2 gap-x-8 gap-y-10 mt-16 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
        <div
          v-for="stat in stats"
          :key="stat.id"
          class="flex flex-col gap-y-3 border-l border-white/10"
        >
          <dt class="text-center text-sm/8">
            {{ t(stat.name) }}
          </dt>
          <dd class="text-center order-first text-3xl font-semibold tracking-tight">
            {{ t(stat.value) }}
          </dd>
        </div>
      </dl>
    </div>

    <!-- Featured section -->
    <div class="mx-auto mt-32 max-w-7xl sm:px-6 lg:px-8">
      <div class="relative isolate overflow-hidden bg-teal-600 px-6 py-20 sm:rounded-3xl sm:px-10 sm:py-24 lg:py-24 xl:px-24 shadow-2xl">
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0">
          <div class="lg:row-start-2 lg:max-w-md">
            <h2 class="text-3xl font-semibold tracking-tight text-balance text-white sm:text-4xl">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="t('home.featured.title')" />
            </h2>
            <p class="mt-6 text-lg font-medium text-white">
              {{ t('home.featured.description') }}
            </p>
          </div>
          <img
            src="https://ucarecdn.com/02e855a1-9eee-4115-9419-bc577ad0ab60/reportex1.png"
            alt="Product screenshot"
            class="relative -z-20 max-w-xl min-w-full rounded-xl ring-1 shadow-xl ring-white/10 lg:row-span-4 lg:w-[44rem] lg:max-w-none"
            width="2432"
            height="1442"
          >
          <div class="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-10">
            <dl class="max-w-xl space-y-8 lg:max-w-none">
              <div
                v-for="feature in primaryFeatures"
                :key="feature.name"
                class="relative"
              >
                <dt class="ml-9 inline-block font-semibold text-white">
                  <component
                    :is="feature.icon"
                    class="absolute top-1 left-1 size-5"
                    aria-hidden="true"
                  />
                  {{ t(feature.name) }}
                </dt>
                {{ ' ' }}
                <dd class="inline text-white italic">
                  {{ t(feature.description) }}
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div
          class="pointer-events-none absolute top-1/2 left-12 -z-10 -translate-y-1/2 transform-gpu blur-3xl lg:top-auto lg:bottom-[-12rem] lg:translate-y-0 lg:transform-gpu"
          aria-hidden="true"
        >
          <div
            class="aspect-1155/678 w-[72.1875rem] bg-linear-to-tr from-[#ff80b5] to-[#00bba7] opacity-25"
            style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
          />
        </div>
      </div>
    </div>

    <!-- Pricing section -->
    <div
      id="pricing"
      class="mx-auto pt-32 max-w-7xl px-6 lg:px-8"
    >
      <div class="mx-auto max-w-2xl lg:mx-0">
        <span class="shadow inline-flex items-center rounded-full bg-white px-4 py-2 mb-8 text-base font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">
          <BanknotesIcon
            class="size-6 mr-2 fill-teal-500"
            aria-hidden="true"
          />
          {{ t('home.pricing.tag') }}
        </span>
        <h2 class="text-4xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-5xl">
          {{ t('home.pricing.title') }}
        </h2>
        <p class="mt-6 text-lg/8 text-gray-600">
          {{ t('home.pricing.description') }}
        </p>
      </div>

      <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <div
          v-for="tier in tiers"
          :key="tier.id"
          :class="[tier.mostPopular ? 'ring-2 ring-teal-600' : 'ring-1 ring-gray-200', 'rounded-3xl p-8 xl:p-10 bg-white shadow-xl']"
        >
          <div class="flex items-center justify-between gap-x-4">
            <h3
              :id="tier.id"
              :class="[tier.mostPopular ? 'text-teal-600' : 'text-gray-900', 'text-lg/8 font-semibold']"
            >
              {{ tier.name }}
            </h3>
            <p
              v-if="tier.mostPopular"
              class="rounded-full bg-teal-600/10 px-2.5 py-1 text-xs/5 font-semibold text-teal-600"
            >
              {{ t('home.pricing.mostPopular') }}
            </p>
          </div>
          <p class="mt-4 text-sm/8 text-gray-600">
            {{ tier.description }}
          </p>
          <p class="mt-6 flex items-baseline gap-x-1">
            <span class="text-4xl font-semibold tracking-tight text-gray-900">{{ tier.price }}</span>
            <span class="text-sm/8 font-semibold text-gray-600">{{ tier.frequency }}</span>
          </p>
          <a
            :href="tier.href"
            :aria-describedby="tier.id"
            :class="[tier.mostPopular ? 'bg-teal-600 text-white shadow-xs hover:bg-teal-500' : 'text-teal-600 ring-1 ring-teal-500 ring-inset hover:ring-teal-600', 'mt-6 block rounded-md px-3 py-2 text-center text-sm/8 font-semibold focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600']"
          >
            {{ tier.button }}
          </a>
          <ul
            class="mt-8 space-y-3 text-sm/8 text-gray-600 xl:mt-10"
          >
            <li
              v-for="feature in tier.features.split(';')"
              :key="feature"
              class="flex gap-x-3"
            >
              <CheckIcon
                class="h-6 w-5 flex-none text-teal-500"
                aria-hidden="true"
              />
              {{ feature }}
            </li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col mt-8 items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
        <div class="lg:min-w-0 lg:flex-1">
          <h3 class="text-base/7 font-semibold">
            {{ t('home.pricing.custom.title') }}
          </h3>
          <p class="mt-1 text-base/7 text-gray-600">
            {{ t('home.pricing.custom.description') }}
          </p>
        </div>
        <a
          href="#"
          class="rounded-md px-3.5 py-2 text-sm/8 font-semibold text-teal-600 ring-1 ring-teal-600 ring-inset hover:ring-teal-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          @click="navigateWhatsapp(t('home.pricing.custom.message'))"
        >
          {{ t('home.pricing.custom.cta') }} <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
    </div>

    <!-- Trusted by section -->
    <div
      id="made-for-you"
      class="mx-auto pt-32 max-w-7xl px-6 lg:px-8"
    >
      <div class="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
        <div class="mx-auto w-full max-w-xl lg:mx-0">
          <span class="shadow inline-flex items-center rounded-full bg-white px-4 py-2 mb-8 text-base font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">
            <CheckBadgeIcon
              class="size-6 mr-2 fill-teal-500"
              aria-hidden="true"
            />
            {{ t('home.trusted.tag') }}
          </span>
          <h2 class="text-4xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-5xl">
            {{ t('home.trusted.title') }}
          </h2>
          <p class="mt-6 text-lg/8 text-gray-600">
            {{ t('home.trusted.description') }}
          </p>
          <div class="mt-8 flex items-center gap-x-6">
            <a
              :href="signupLink()"
              class="rounded-md bg-teal-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-xl hover:bg-teal-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              {{ t('home.trusted.cta') }}
            </a>
            <a
              href="#"
              class="text-sm font-semibold text-gray-900"
              @click="navigateWhatsapp(t('home.reviews.message'))"
            >
              {{ t('home.trusted.contact') }} <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
        <ul
          class="mx-auto grid w-full max-w-xl grid-cols-5 items-center gap-y-4 gap-x-4 sm:gap-y-8 lg:mx-0 lg:max-w-none lg:pl-8"
        >
          <li
            v-for="customer in customers"
            :key="customer.name"
          >
            <a
              :href="customer.href"
              target="_blank"
            >
              <span class="sr-only">{{ customer.name }}</span>
              <img
                class="grayscale hover:grayscale-0 aspect-14/13 w-full rounded-2xl object-cover max-w-none shadow-xl"
                :src="customer.image"
                :alt="customer.name"
              >
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Reviews section -->
    <div class="relative mx-auto -z-10 mt-32 max-w-7xl px-6 lg:px-8">
      <div
        class="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-[120px] sm:top-auto sm:right-[calc(50%-6rem)] sm:bottom-0 sm:translate-y-0 sm:justify-end"
        aria-hidden="true"
      >
        <div
          class="aspect-[1108/632] w-[72rem] flex-none bg-gradient-to-br from-[#ff7eb3] via-teal-400 to-[#ff7eb3] opacity-40"
          style="clip-path: polygon(75% 50%, 90% 90%, 100% 55%, 95% 15%, 85% 12%, 70% 35%, 50% 50%, 45% 48%, 40% 30%, 48% 10%, 22% 40%, 2% 5%, 8% 50%, 22% 38%, 60% 100%, 75% 50%)"
        />
      </div>
      <div class="mx-auto max-w-2xl text-center">
        <span class="shadow inline-flex items-center rounded-full bg-white px-4 py-2 mb-8 text-base font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">
          <HeartIcon
            class="size-6 mr-2 fill-teal-500"
            aria-hidden="true"
          />
          {{ t('home.reviews.tag') }}
        </span>
        <h2 class="text-4xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-5xl">
          {{ t('home.reviews.title') }}
        </h2>
        <p class="mt-6 text-lg/8 text-gray-600">
          {{ t('home.reviews.description') }}
        </p>
      </div>
      <div class="isolate mx-auto mt-10 grid grid-cols-1 gap-8 max-w-2xl">
        <ul class="mx-auto grid w-full max-w-xl grid-cols-2 sm:grid-cols-2 md:grid-cols-3 items-center gap-y-4 gap-x-4 sm:gap-y-8">
          <li
            v-for="testimonial in testimonials"
            :key="testimonial.name"
          >
            <a
              href="#"
              @click="openTestimonialVideoModal(testimonial.videoUrl)"
            >
              <span class="sr-only">{{ testimonial.name }}</span>
              <img
                class="grayscale hover:grayscale-0 aspect-14/13 w-full rounded-2xl object-cover max-w-none"
                :src="testimonial.imageUrl"
                :alt="testimonial.name"
              >
            </a>
            <button
              type="button"
              class="mt-4 mx-auto flex items-center gap-x-1.5 rounded-md border-2 border-transparent px-3 py-2 text-sm font-semibold hover:text-teal-600 shadow-xs hover:border-teal-600 focus:border-teal-600 focus:outline-none"
              @click="openTestimonialVideoModal(testimonial.videoUrl)"
            >
              {{ t('home.reviews.watch') }}
              <PlayCircleIcon
                class="-mr-0.5 size-5"
                aria-hidden="true"
              />
            </button>
          </li>
        </ul>
      </div>
      <div class="mt-8 flex">
        <p class="mx-auto relative rounded-md text-center px-4 py-1.5 text-sm/8 text-gray-600 ring-1 ring-gray-900/10 ring-inset hover:ring-gray-900/20">
          <span class="sm:inline">
            {{ t('home.reviews.cta') }}
          </span>
          <a
            href="#"
            @click="navigateWhatsapp(t('home.reviews.message'))"
          >
            <span class="ml-2 font-semibold text-teal-500">
              {{ t('home.reviews.contact') }}  <span aria-hidden="true">&rarr;</span></span>
          </a>
        </p>
      </div>
    </div>
    <TransitionRoot
      as="template"
      :show="showTestimonialModal"
    >
      <Dialog
        class="relative z-10"
        @close="closeModal"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 flex flex-col">
                <!-- YouTube Video -->
                <div class="relative w-full aspect-video">
                  <video
                    class="w-full max-w-md sm:max-w-lg aspect-[9/16]"
                    controls
                    autoplay
                    muted
                    playsinline
                  >
                    <source
                      :src="activeTestimonialVideoURL"
                      type="video/mp4"
                    >
                  </video>
                </div>

                <!-- <div class="mt-4 text-center">
                  <h3 class="mt-6 text-lg/8 font-semibold tracking-tight">
                    {{ testimonial.name }}
                  </h3>
                  <p class="text-sm/8 text-gray-900">
                    {{ testimonial.location }}
                  </p>
                </div> -->
                <!-- Close Button -->
                <div class="mt-4 text-center">
                  <button
                    type="button"
                    class="rounded-full bg-gray-600 p-1.5 text-white shadow-xs hover:bg-gray-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    @click="closeModal"
                  >
                    <XMarkIcon
                      class="size-6 text-gray-200"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- FAQ section -->
    <div
      id="faq"
      class="relative mx-auto pt-32 max-w-7xl px-6 lg:px-8"
    >
      <div class="mx-auto max-w-2xl lg:mx-0">
        <span class="shadow inline-flex items-center rounded-full bg-white px-4 py-2 mb-8 text-base font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">
          <ChatBubbleLeftEllipsisIcon
            class="size-6 mr-2 fill-teal-500"
            aria-hidden="true"
          />
          {{ t('home.faq.tag') }}
        </span>
        <h2 class="text-4xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-5xl">
          {{ t('home.faq.title') }}
        </h2>
        <p class="mt-6 text-lg/8 text-gray-600">
          {{ t('home.faq.description') }}
        </p>
      </div>

      <div class="isolate mx-auto max-w-7xl grid grid-cols-1 gap-8 lg:mx-0 lg:max-w-none mt-8">
        <dl class="divide-y divide-gray-900/10">
          <Disclosure
            v-for="faq in faqs"
            :key="faq.question"
            v-slot="{ open }"
            as="div"
            class="py-6 first:pt-0 last:pb-0"
          >
            <dt>
              <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900">
                <span class="text-base/7 font-semibold">{{ faq.question }}</span>
                <span class="ml-6 flex h-7 items-center">
                  <PlusSmallIcon
                    v-if="!open"
                    class="size-6"
                    aria-hidden="true"
                  />
                  <MinusSmallIcon
                    v-else
                    class="size-6"
                    aria-hidden="true"
                  />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel
              as="dd"
              class="mt-2 pr-12"
            >
              <p
                v-for="answer in faq.answers"
                :key="answer"
                class="text-base/7 text-gray-600"
                v-html="answer"
              />
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>

    <!-- Image section -->
    <div class="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
      <img
        src="https://images.pexels.com/photos/6964860/pexels-photo-6964860.jpeg?auto=compress&cs=tinysrgb&w=1200"
        alt=""
        class="aspect-5/2 w-full object-cover xl:rounded-3xl shadow-2xl"
      >
    </div>

    <!-- Content section -->
    <!-- <div class="mx-auto max-w-7xl px-6 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
      <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
        <h2 class="text-4xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-5xl">
          Our mission
        </h2>
        <div class="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
          <div class="lg:w-full lg:max-w-2xl lg:flex-auto">
            <p class="text-xl/8 text-gray-600">
              Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit
              dui mi, nibh dui, diam eget aliquam. Quisque id at vitae feugiat egestas ac. Diam
              nulla orci at in viverra scelerisque eget. Eleifend egestas fringilla sapien.
            </p>
            <p class="mt-10 max-w-xl text-base/7 text-gray-700">
              Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis
              mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio
              penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa
              rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet
              vitae sed turpis id.
            </p>
          </div>
          <div class="lg:flex lg:flex-auto lg:justify-center">
            <dl class="w-64 space-y-8 xl:w-80">
              <div
                v-for="stat in stats"
                :key="stat.label"
                class="flex flex-col-reverse gap-y-4"
              >
                <dt class="text-base/7 text-gray-600">
                  {{ stat.label }}
                </dt>
                <dd class="text-5xl font-semibold tracking-tight text-gray-900">
                  {{ stat.value }}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div> -->

    <!-- CTA section -->
    <div class="relative mx-auto pt-32 max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-3xl">
        <h2
          class="text-4xl font-semibold tracking-tight text-balance text-gray-900 sm:text-5xl text-center"
        >
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="t('home.cta.title')" />
        </h2>
        <p class="mx-auto mt-6 text-lg/8 text-pretty text-gray-600 text-center">
          {{ t('home.cta.description') }}
        </p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <a
            :href="signupLink()"
            class="rounded-md bg-teal-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-xs hover:bg-teal-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-500"
          >
            {{ t('home.cta.cta') }}
          </a>
          <a
            href="#"
            class="text-sm font-semibold text-gray-900"
            @click="navigateWhatsapp(t('home.reviews.message'))"
          >
            {{ t('home.cta.contact') }} <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </div>
  </main>

  <!-- Footer -->
  <footer>
    <div class="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
      <nav
        class="-mb-6 flex flex-wrap justify-center gap-x-12 gap-y-3 text-sm/8"
        aria-label="Footer"
      >
        <a
          v-for="item in footerNavigation.main"
          :key="item.name"
          :href="item.href"
          class="text-gray-600 hover:text-gray-900"
        >{{ item.name }}</a>
      </nav>
      <div class="mt-16 flex justify-center gap-x-10">
        <a
          v-for="item in footerNavigation.social"
          :key="item.name"
          :href="item.href"
          class="text-gray-600 hover:text-gray-800"
        >
          <span class="sr-only">{{ item.name }}</span>
          <component
            :is="item.icon"
            class="size-6"
            aria-hidden="true"
          />
        </a>
      </div>
      <div class="flex justify-center">
        <ChooseLanguage />
      </div>
      <p class="mt-8 text-sm/8 text-gray-600 text-center">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="t('home.footer.love')" />
      </p>
    </div>
  </footer>
</template>

<script setup lang="ts">
import {
  defineComponent, h, ref, onBeforeMount, computed, onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import useAuthStore from '@/store/auth';
import HomeHeader from '@/components/tailwind/header/HomeHeader.vue';
import ChooseLanguage from '@/components/tailwind/shared/ChooseLanguage.vue';
import {
  SparklesIcon,
  BanknotesIcon,
  HeartIcon,
  PlayCircleIcon,
  XMarkIcon,
  ChatBubbleLeftEllipsisIcon,
  MinusSmallIcon,
  PlusSmallIcon,
  CheckBadgeIcon,
  RocketLaunchIcon,
  LightBulbIcon,
  UsersIcon,
} from '@heroicons/vue/24/outline';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue';

import BrechoEraMeuImage from '@/assets/images/customers/brechoerameu.jpg';
import JazminMais from '@/assets/images/customers/jazminmais.jpeg';
import FeiraDoLar from '@/assets/images/customers/feiradolar.jpeg';
import ElaineBrechoeOutlet from '@/assets/images/customers/elainebrechoeoutlet.jpg';

import ThomyTalks from '@/assets/images/customers/thomytalks.jpg';
import LuzdaLuaJoacaba from '@/assets/images/customers/luzdaluajoacaba.jpg';
import TaueTauBrecho from '@/assets/images/customers/tau.e.tau.brecho.jpg';
import ElvisCalcados from '@/assets/images/customers/elviscalcados.jpg';
import Lucimarmodascx from '@/assets/images/customers/lucimarmodascx.jpg';
import Elahmodafeminina from '@/assets/images/customers/elahmodafeminina.jpg';
import NovadonaMogi from '@/assets/images/customers/novadona_mogi.jpg';
import Pequenoluxo from '@/assets/images/customers/pequenoluxo.jpg';
import LojatransitoOficial from '@/assets/images/customers/lojatransito.oficial.png';

import { useI18n } from 'vue-i18n';
import BingaPresentes from '@/assets/images/customers/bingapresentes.jpg';
import Portobelobrechooutlet from '@/assets/images/customers/portobelobrechooutlet.jpg';

const { t } = useI18n();
const { isAuthenticated } = useAuthStore();
const router = useRouter();

/*
  Data
*/
const dataLoaded = ref(false);
const customers = [
  {
    image: BingaPresentes,
    name: 'bingapresentes',
    href: 'https://instagram.com/bingapresentes',
  },
  {
    image: BrechoEraMeuImage,
    name: 'brechoerameuicara',
    href: 'https://instagram.com/brechoerameuicara',
  },
  {
    image: ElaineBrechoeOutlet,
    name: 'elainebrechoeoutlet',
    href: 'https://instagram.com/elainebrechoeoutlet',
  },
  {
    image: Elahmodafeminina,
    name: 'elahmodafeminina',
    href: 'https://instagram.com/elahmodafeminina',
  },
  {
    image: ElvisCalcados,
    name: 'elviscalcados',
    href: 'https://instagram.com/elviscalcados',
  },
  {
    image: FeiraDoLar,
    name: 'feiradolar',
    href: 'https://instagram.com/feiradolar',
  },
  {
    image: JazminMais,
    name: 'jazminmais',
    href: 'https://instagram.com/jazminmais',
  },
  {
    image: LuzdaLuaJoacaba,
    name: 'luzdaluajoaçaba',
    href: 'https://instagram.com/luzdaluajoacaba',
  },
  {
    image: LojatransitoOficial,
    name: 'lojatransito.oficial',
    href: 'https://instagram.com/lojatransito.oficial',
  },
  {
    image: Lucimarmodascx,
    name: 'lucimarmodascx',
    href: 'https://instagram.com/lucimarmodascx',
  },
  {
    image: NovadonaMogi,
    name: 'novadona_mogi',
    href: 'https://instagram.com/novadona_mogi',
  },
  {
    image: Pequenoluxo,
    name: 'pequenoluxo',
    href: 'https://instagram.com/pequenoluxo',
  },
  {
    image: Portobelobrechooutlet,
    name: 'portobelobrechooutlet',
    href: 'https://instagram.com/portobelobrechooutlet',
  },
  {
    image: TaueTauBrecho,
    name: 'tau.e.tau.brechó',
    href: 'https://instagram.com/tau.e.tau.brecho',
  },
  {
    image: ThomyTalks,
    name: 'thomytalks',
    href: 'https://instagram.com/thomytalks',
  },
];
const showTestimonialModal = ref(false);
const activeTestimonialVideoURL = ref('');
const testimonials = [
  {
    id: 1,
    name: '@brechoerameuicara',
    href: 'https://instagram.com/brechoerameuicara',
    videoUrl: 'https://ucarecdn.com/7828318d-7073-4374-9679-d4d35dc4a38b/brechoerameuicara.mp4',
    imageUrl: BrechoEraMeuImage,
    location: 'Içara - SC',
  },
  {
    id: 2,
    name: '@feiradolar',
    href: 'https://instagram.com/feiradolar',
    videoUrl: 'https://ucarecdn.com/ef164475-214e-44e0-baf2-951907cbe807/feiradolar.mp4',
    imageUrl: FeiraDoLar,
    location: 'São José do Rio Pardo - SP',
  },
  {
    id: 3,
    name: '@jazminmais',
    href: 'https://instagram.com/jazminmais',
    videoUrl: 'https://ucarecdn.com/e291fa61-3d8e-44bf-ad1d-e6b9837aa6d8/jazminmais.mp4',
    imageUrl: JazminMais,
    location: 'Pinheiros - SP',
  },
];

/*
  Computed
*/
const faqs = computed(() => [
  {
    question: t('home.faq.questions.0.question'),
    answers: t('home.faq.questions.0.answers').split('\n'),
  },
  {
    question: t('home.faq.questions.1.question'),
    answers: t('home.faq.questions.1.answers').split('\n'),
  },
  {
    question: t('home.faq.questions.2.question'),
    answers: t('home.faq.questions.2.answers').split('\n'),
  },
  {
    question: t('home.faq.questions.3.question'),
    answers: t('home.faq.questions.3.answers').split('\n'),
  },
  {
    question: t('home.faq.questions.4.question'),
    answers: t('home.faq.questions.4.answers').split('\n'),
  },
  {
    question: t('home.faq.questions.5.question'),
    answers: t('home.faq.questions.5.answers').split('\n'),
  },
  {
    question: t('home.faq.questions.6.question'),
    answers: t('home.faq.questions.6.answers').split('\n'),
  },
  {
    question: t('home.faq.questions.7.question'),
    answers: t('home.faq.questions.7.answers').split('\n'),
  },
  {
    question: t('home.faq.questions.8.question'),
    answers: t('home.faq.questions.8.answers').split('\n'),
  },
  {
    question: t('home.faq.questions.9.question'),
    answers: t('home.faq.questions.9.answers').split('\n'),
  },
]);
const tiers = computed(() => [
  {
    name: t('home.pricing.tiers.basic.title'),
    button: t('home.pricing.tiers.basic.button'),
    id: 'tier-freelancer',
    href: router.resolve({ name: 'SessionSignupPage' }).href,
    price: t('home.pricing.tiers.basic.price'),
    frequency: t('home.pricing.tiers.basic.frequency'),
    description: t('home.pricing.tiers.basic.description'),
    features: t('home.pricing.tiers.basic.features'),
    mostPopular: false,
  },
  {
    name: t('home.pricing.tiers.intermediate.title'),
    button: t('home.pricing.tiers.intermediate.button'),
    id: 'tier-freelancer',
    href: router.resolve({ name: 'SessionSignupPage' }).href,
    price: t('home.pricing.tiers.intermediate.price'),
    frequency: t('home.pricing.tiers.intermediate.frequency'),
    description: t('home.pricing.tiers.intermediate.description'),
    features: t('home.pricing.tiers.intermediate.features'),
    mostPopular: false,
  },
  {
    name: t('home.pricing.tiers.subscription.title'),
    button: t('home.pricing.tiers.subscription.button'),
    id: 'tier-freelancer',
    href: router.resolve({ name: 'SessionSignupPage' }).href,
    price: t('home.pricing.tiers.subscription.price'),
    frequency: t('home.pricing.tiers.subscription.frequency'),
    description: t('home.pricing.tiers.subscription.description'),
    features: t('home.pricing.tiers.subscription.features'),
    mostPopular: true,
  },
]);
const footerNavigation = computed(() => ({
  main: [
    { name: t('footer.links.terms.shoppers.name'), href: router.resolve({ name: 'TermsAndConditionsPageShoppers' }).href },
    { name: t('footer.links.terms.partners.name'), href: router.resolve({ name: 'TermsAndConditionsPage' }).href },
    { name: t('footer.links.terms.privacy.name'), href: router.resolve({ name: 'PrivacyPage' }).href },
  ],
  social: [
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/livelinkoficial',
      icon: defineComponent({
        render: () => h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
          h('path', {
            'fill-rule': 'evenodd',
            d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
            'clip-rule': 'evenodd',
          }),
        ]),
      }),
    },
    // {
    //   name: 'Facebook',
    //   href: '#',
    //   icon: defineComponent({
    //     render: () => h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
    //       h('path', {
    //         'fill-rule': 'evenodd',
    // eslint-disable-next-line max-len
    //         d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
    //         'clip-rule': 'evenodd',
    //       }),
    //     ]),
    //   }),
    // },
    // {
    //   name: 'X',
    //   href: '#',
    //   icon: defineComponent({
    //     render: () => h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
    //       h('path', {
    // eslint-disable-next-line max-len
    //         d: 'M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z',
    //       }),
    //     ]),
    //   }),
    // },
    // {
    //   name: 'GitHub',
    //   href: '#',
    //   icon: defineComponent({
    //     render: () => h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
    //       h('path', {
    //         'fill-rule': 'evenodd',
    // eslint-disable-next-line max-len
    //         d: 'M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z',
    //         'clip-rule': 'evenodd',
    //       }),
    //     ]),
    //   }),
    // },
    // {
    //   name: 'YouTube',
    //   href: '#',
    //   icon: defineComponent({
    //     render: () => h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
    //       h('path', {
    //         'fill-rule': 'evenodd',
    // eslint-disable-next-line max-len
    //         d: 'M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z',
    //         'clip-rule': 'evenodd',
    //       }),
    //     ]),
    //   }),
    // },
  ],
}));

/*
  Methods
*/
const signupLink = () => router.resolve({ name: 'SessionSignupPage' }).href;
const navigateWhatsapp = (message: string) => {
  window.open(`https://wa.me/+5511994991786?text=${message}`, '_blank');
};
const closeModal = () => {
  showTestimonialModal.value = false;
};
const openTestimonialVideoModal = (videoUrl: string) => {
  activeTestimonialVideoURL.value = videoUrl;
  showTestimonialModal.value = true;
};
const stats = [
  { id: 1, name: 'home.stats.reports.title', value: 'home.stats.reports.value' },
  { id: 2, name: 'home.stats.messages.title', value: 'home.stats.messages.value' },
  { id: 3, name: 'home.stats.hours.title', value: 'home.stats.hours.value' },
  { id: 4, name: 'home.stats.accounts.title', value: 'home.stats.accounts.value' },
];
const primaryFeatures = [
  {
    name: 'home.featured.features.live.title',
    description: 'home.featured.features.live.description',
    icon: RocketLaunchIcon,
  },
  {
    name: 'home.featured.features.insights.title',
    description: 'home.featured.features.insights.description',
    icon: LightBulbIcon,
  },
  {
    name: 'home.featured.features.shareable.title',
    description: 'home.featured.features.shareable.description',
    icon: UsersIcon,
  },
  {
    name: 'home.featured.features.partners.title',
    description: 'home.featured.features.partners.description',
    icon: CheckBadgeIcon,
  },
];

/*
  Hooks
*/
onMounted(() => {
  setTimeout(() => {
    dataLoaded.value = true;
  }, 100);
});
onBeforeMount(() => {
  if (isAuthenticated()) {
    router.push({
      name: 'DashboardPage',
    });
  }
});
</script>
