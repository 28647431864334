import axios, { AxiosInstance } from 'axios';
import { useCookies } from 'vue3-cookies';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default (): AxiosInstance => {
  const router = useRouter();
  const { cookies } = useCookies();

  const instance = axios.create({
    baseURL: 'https://app.livelink.com.br', // Set the base URL for backend API
    // baseURL: 'https://le.sa.ngrok.io/api', // Set the base URL for backend API
    // baseURL: 'http://localhost:80', // Set the base URL for backend API
    timeout: 1000 * 60, // Wait for 1 minute
    headers: { // Set default headers for all requests
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'Accept-Language': localStorage.getItem('user-locale') || navigator.language,
    },
  });

  // Add an interceptor to request
  instance.interceptors.request.use(
    (request) => {
      const config = request;
      config.headers.Authorization = `Bearer ${cookies.get('access_token')}`;
      return config;
    },
    (error) => error,
  );

  // Add an response interceptor to handle errors
  // if calling method catch router.push to error page
  // is not specified, this route interceptor will be used
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error('axios.ts', router.currentRoute.value, error);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      }

      // Especifico da YAMPI
      if (
        error.response.data
        && error.response.data.errors) {
        if (error.response.data.errors.name) {
          throw new Error(`Nome: ${error.response.data.errors.name[0]}`);
        }
        if (error.response.data.errors.name) {
          throw new Error(`Nome: ${error.response.data.errors.name[0]}`);
        }
        if (error.response.data.errors['skus.0.images.0.url']) {
          throw new Error(`Imagem: ${error.response.data.errors['skus.0.images.0.url'][0]}`);
        }
        throw new Error(error.response.data.errors);
      }

      if (error.code === 'ERR_BAD_REQUEST' && error.response.status !== 401) { // Check if error was due to bad request
        if (error.response.data && error.response.data.error?.length > 0) {
          if (error.response.data.error === 'invalid_authorization_header') {
            alert('invalid_authorization_header');
            setTimeout(() => {
              router.push({
                name: 'SessionLoginRefreshPage',
                query: { redirect: router.currentRoute.value.fullPath },
                params: { message: 'Faça login para continuar.' },
              });
            }, 100);
          }
          throw error;
        }
        throw new Error(`
        Solicitação inválida.
        Não foi possível processar sua solicitação. Recarregue a página e tente novamente em instantes.
        ${error.code}`);
      } else if (error.code === 'ERR_BAD_RESPONSE') { // Check if error was due to bad response
        throw new Error(`Tempo de conexão excedido.
        Nossos servidores estão indisponíveis no momento. Tente novamente em instantes.
        ${error.code}`);
      } else if (error.code === 'ECONNABORTED') { // Check if error was due to timeout
        throw new Error(`Tempo de conexão excedido.
        Verifique sua conexão com a internet e tente novamente.
        ${error.code}`);
      } else if (error.code === 'ERR_NETWORK') { // Check if error was due to network connection
        setTimeout(() => {
          router.push({
            name: 'SessionLoginRefreshPage',
            query: { redirect: router.currentRoute.value.fullPath },
            params: { message: 'Você não tem acesso à essa página.' },
          });
        }, 100);

        // throw new Error(`${error.code}
        // <br>
        // <br>
        // Não foi possível estabelecer conexão com o Servidor.
        // <br>
        // Verifique sua conexão com a internet e tente novamente em instantes.`);
      // } else if (error.response.status === 404) { // Check if error was due to not found page
      //   throw new Error('PageNotFound');
      // } else if (error.response.status === 500) {
      //    Check if error was due to internal server error
      //   throw new Error('InternalServerError');
      } else if (
        error.response
        && error.response.status === 401
        && router.currentRoute.value.name !== 'SessionLoginRefreshPage'
      ) { // Check if error was due to unauthorized access
        setTimeout(() => {
          router.push({
            name: 'SessionLoginRefreshPage',
            query: { redirect: router.currentRoute.value.fullPath },
            params: { message: 'Você não tem acesso à essa página.' },
          });
        }, 100);
      }
      return Promise.reject(error);
    },
  );
  return instance;
};
